import React from "react"
import WithSider from "../../layouts/WithSider"
import AboutSidebar from "../../components/AboutSidebar/AboutSidebar"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import { Row, Col } from "antd"
import WpPage from "../WpPage/WpPage"
import { useTranslation } from 'react-i18next'
import MetaDecorator from "../../metaDecorator"


const AboutUsWp = props => {

  const { t } = useTranslation("translation")
  return (
    <WithSider
      siderTitle={ t('translation:aboutUsWp:o-nama') }
      sider={ <AboutSidebar uri={ props }/> }
      content={
        <Row>
          <MetaDecorator 
            title = { t(`translation:aboutUsWp:${props.id}`) }
            url = {window.location.href}
          />
          <Col>
            <WpPage wrapperClass={ props.id } id={ props.id }/>
          </Col>
        </Row>
      }
      moreContent={ <PopularActivities mobile={ props.mobile }/> }
    />
  )
}

export default AboutUsWp
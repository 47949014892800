import React from 'react'
import useFetch from "../../hooks/useFetch"
import { Parser } from "html-to-react"


function Survey() {
  const htmlToReact = new Parser()
  
  const { response: contact, isLoading: contactLoading } = useFetch(
    "/wp-json/wp/v2/settings?slug=podesavanja"
  )

  return (<>
    {contact[0]?.acf?.powrio_form_embed && htmlToReact.parse(contact[0].acf.powrio_form_embed)}
  </>
  )
}

export default Survey;
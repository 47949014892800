import React, { useState, useEffect, useCallback } from "react"
import { List, Row, Col, Input, Icon, Skeleton } from "antd"
import WithSider from "../../layouts/WithSider"
import GalleryCard from "../../components/GalleryCard/GalleryCard"
import GallerySearch from "../../components/GallerySearch/GallerySearch"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import useFetch from "../../hooks/useFetch"
import CustomButton from "../../components/CustomButton/CustomButton"
import useWindowSize from "../../hooks/useWindowSize"
import { useTranslation } from "react-i18next"
import convert from 'latin-to-serbian-cyrillic'

import styles from "./Gallery.module.css"
import "./Gallery.css"
import MetaDecorator from "../../metaDecorator"
import textTranslation from "../../textTranslation"

function Gallery( props ) {
  const { t } = useTranslation("translation")

  const { Search } = Input

  const fetchUrl = `/wp-json/gbns/v2/galleries/filtered?per_page=9&page=1&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`

  const { response, isLoading } = useFetch(fetchUrl)

  const [initLoading, setInitLoading] = useState( true )
  const [filterActive, setFilterActive] = useState( false )
  const [galleriesFilterResults, setGalleriesFilterResults] = useState([])
  const [currentPage, setCurrentPage] = useState( 1 )
  const [disabled, setDisabled] = useState( false )
  const [wpTotalItems, setWpTotalItems] = useState( null )
  const [wpTotalPages, setWpTotalPages] = useState( null )
  const [galleriesList, setGalleriesList] = useState([])
  const [fetchMore, setFetchMore] = useState( false )
  const [resultsLoading, setResultsLoading] = useState( false )
  const [loadMoreClicked, setLoadMoreClicked] = useState( false )
  const [mobileFiltersEnabled, setMobileFiltersEnabled] = useState( false )

  const [searchTerm, setSearchTerm] = useState( '' )
  const [term, setTerm] = useState( '' )
  const [filteredData, setFilteredData] = useState( [] )

  const apiUrl = process.env.REACT_APP_API_URL

  const size = useWindowSize()

  function handleLoadMore() {
    if (currentPage < wpTotalPages) {
      setCurrentPage( currentPage + 1 )
      setResultsLoading( true )
      setFetchMore( true )
      setLoadMoreClicked( true )
    }
  }

  useEffect( () => {
    if (typeof wpTotalPages === "number") {
      if (currentPage >= wpTotalPages || isNaN( wpTotalPages )) {
        setDisabled( true )
      } else {
        if ( disabled ) {
          setDisabled( false )
        }
      }
    }
  }, [disabled, currentPage, wpTotalItems, wpTotalPages])

  const getTotals = useCallback(() => {
    return fetch(
      `${apiUrl}//wp-json/gbns/v2/galleries/filtered?per_page=9&page=1&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`
    ).then(res => {
      setWpTotalItems( parseInt(res.headers.get("X-WP-Total")))
      setWpTotalPages( parseInt(res.headers.get("X-WP-TotalPages")))
      return res.json()
    })
  }, [apiUrl])

  useEffect(() => {
    getTotals()
  }, [getTotals])

  useEffect(() => {
    setLoadMoreClicked( false )
  }, [galleriesFilterResults])

  useEffect(() => {
    setCurrentPage( 1 )
    if ( term !== '' ) {
      fetch(`${apiUrl}/wp-json/gbns/v2/galleries/filtered?per_page=9&page=1&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}&search_term=${term}`)
      .then( response => {
        setWpTotalItems( parseInt(response.headers.get("X-WP-Total")))
        setWpTotalPages( parseInt(response.headers.get("X-WP-TotalPages")))
        return response.json()
      })
      .then( resp => {
        if ( resp.length > 0 ) setFilteredData( resp )
        else setFilteredData( [] )
      })
      .catch( err => console.error( err ))
    } else {
      setFilteredData( [] )
    }
  }, [apiUrl, term])

  useEffect(() => {
    if ( filteredData.length !== 0 && term.length !== 0 ) {
      setInitLoading( false )
      setGalleriesList( filteredData )
    } else if ( filteredData.length === 0 && term.length !== 0 ) {
      setInitLoading( false )
      setGalleriesList( [] )
    } 
    else if ( filteredData.length === 0 ) {
      if ( response && response.length ) {
        setInitLoading( false )
        setGalleriesList( response )
      }
    }
  }, [response, filteredData, term])

  useEffect(() => {
    if ( filterActive ) {
      if ( loadMoreClicked ) {
        if ( galleriesFilterResults.length ) {
          setGalleriesList( prevState => {
            return [...prevState, ...galleriesFilterResults]
          })
        } else {
          setGalleriesList( [] )
        }
      } else {
        setWpTotalItems( wpTotalItems ) 
        setWpTotalPages( wpTotalPages )
        setGalleriesList( () => {
          return [...galleriesFilterResults]
        })
      }
      setFilterActive( false )
    } else {
      setGalleriesList( prevState => {
        return [...prevState, ...galleriesFilterResults]
      })
    }
  }, [galleriesFilterResults, wpTotalPages, wpTotalItems ])

  const loadMore = !isLoading ? (
    <CustomButton
      click={ handleLoadMore }
      isLoading={ isLoading } 
      disabled={ disabled }
      className={ styles.customButton }>
      {t('translation:userGuide:prikazite-jos')}
    </CustomButton>
  ) : null

  function handleClick(gallery) {
    props.navigate(`${gallery.id}`)
    window.scrollTo(0,0)
  }

  const enableMobileFilters = () => {
    setMobileFiltersEnabled( !mobileFiltersEnabled )
  }

  
  return size.width > 991 ? (
    <WithSider
      siderTitle={t('translation:gallery:galerija')}
      sider={
        <GallerySearch
          toggleFilter={ setFilterActive }
          resultsOfFilter={ setGalleriesFilterResults }
          currentPage={ currentPage }
          setCurrentPage={ setCurrentPage }
          setTotalItems={ setWpTotalItems }
          setTotalPages={ setWpTotalPages }
          loadMoreStatus={ fetchMore }
          toggleLoadMore={ setFetchMore }
          setResultsLoading={ setResultsLoading }
          searchTerm={ term }
        />
      }

      content={
       
        <Row style={{ marginBottom: 40 }}>
           <MetaDecorator 
            title = { textTranslation('Галерија') }
           />
          {initLoading ? (
            <Skeleton
              active={ true }
              loading={ true }
              paragraph={{ rows: 10 }}
            ></Skeleton>
          
          ) : ( 
          
          <List
            grid={{
              gutter: 24,
              xs: 1,
              lg: 3
            }}
            loading={ isLoading }
            dataSource={ galleriesList }
            loadMore={ loadMore }
            renderItem={ gallery => {
              const galleryImage = gallery.acf.gallery[0].url
              return (
                <List.Item>
                  <GalleryCard
                    onClick={() => handleClick( gallery )}
                    imageSrc={ galleryImage }
                    title={ gallery.title.rendered }
                    desc={ gallery.excerpt.rendered }
                  />
                </List.Item>
              )
            }}></List>  )} 
        </Row>        
      }
      moreContent={ <PopularActivities mobile={ props.mobile }/>}
    />
  
  ) : (

    <div style={{ padding: "20px" }}>
      <Row style={{ marginBottom: "15px" }}>
        <h1 className = "gallery-title">
          {t('translation:gallery:galerija')}
        </h1>
      </Row>

      <Row
        style={{
          marginBottom: "30px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Col span={8}>
          <div
            className={styles.filtersBtnWrapper}
            onClick={enableMobileFilters}
          >
            <Icon
              type="filter"
              style={{
                fontSize: "22px",
                color: "#FF595E",
                verticalAlign: "text-bottom"
              }}
            />
            <p className={ styles.filtersTitle }>{t('translation:gallery:filteri')}</p>
          </div>
        </Col>

        <Col span={16}>
          <form onSubmit={ event => {
            event.preventDefault()
            setTerm( searchTerm )
          }}
        >
            <Search
              onChange={ event => {
                // localStorage.getItem("lang").length === 0 ? setSearchTerm (convert(event.target.value)) : setSearchTerm( event.target.value )
                localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr' ? setSearchTerm(convert(event.target.value)) : setSearchTerm( event.target.value )              
              }}
              placeholder={t('translation:gallery:pretrazite-galeriju')}
              className={ styles.searchTop }
            />
          </form>
        </Col>
      </Row>

      <Row>
        {isLoading ? (
          <Skeleton
            active={ true }
            loading={ true }
            paragraph={{ rows: 10 }}
          ></Skeleton>
        ) : (

          <List
            loading={ isLoading }
            itemLayout="horizontal"
            dataSource={ galleriesList }
            loadMore={ loadMore }
            renderItem={ gallery => {
              const galleryImage = gallery.acf.gallery[0].url
              return (
                <List.Item style={{display: 'block'}}>
                  <GalleryCard
                    onClick={() => handleClick( gallery )}
                    imageSrc={ galleryImage }
                    title={ gallery.title.rendered }
                    desc={ gallery.excerpt.rendered }
                  />
                </List.Item>
              )
            }}></List>
        )}
      </Row>

      <Row>
        <MetaDecorator 
            title = { textTranslation('Галерија') }
        />
        <PopularActivities mobile={ true }/>
      </Row>
      
      <div
        className={styles.activitiesFiltersWrapper}
        style={{ display: mobileFiltersEnabled ? "block" : "none"}}
      >
        <GallerySearch
          mobile={ true }
          closeFilters={ enableMobileFilters }
          toggleFilter={ setFilterActive }
          resultsOfFilter={ setGalleriesFilterResults }
          currentPage={ currentPage }
          setCurrentPage={ setCurrentPage }
          setTotalItems={ setWpTotalItems }
          setTotalPages={ setWpTotalPages }
          isInitLoading={ initLoading }
          loadMoreStatus={ fetchMore }
          toggleLoadMore={ setFetchMore }
          resultsLoading={ resultsLoading }
          setResultsLoading={ setResultsLoading }
          searchTerm={ term }
        />
      </div>

    </div>
  )
}

export default Gallery

import React, { useState, useEffect, useCallback } from "react"
import styles from "./Activities.module.css"
import mobileStyles from "./MobileActivities.module.css"
import { Row, Skeleton, List, Empty, Col, Icon, Input } from "antd"
import { Parser } from "html-to-react"
import convert from 'latin-to-serbian-cyrillic'

import WithSider from "../../layouts/WithSider"
import ActivityItem from "../../components/ActivityItem/ActivityItem"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import ActivitySearch from "../../components/ActivitiesSearch/ActivitySearch"
import useFetch from "../../hooks/useFetch"
import CustomButton from "../../components/CustomButton/CustomButton"

import useWindowSize from "../../hooks/useWindowSize"
import { useTranslation } from 'react-i18next';
import MetaDecorator from "../../metaDecorator"
import textTranslation from "../../textTranslation"



function Activities( props ) {
  const { t } = useTranslation("translation")
  const htmlToReact = new Parser()

  const { Search } = Input

  const fetchUrl = `/wp-json/gbns/v2/activities/filtered?per_page=10&page=1&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`

  const { response, isLoading } = useFetch( fetchUrl )

  const [initLoading, setInitLoading] = useState( true )
  const [filterActive, setFilterActive] = useState( false )
  const [activitiesFilterResults, setActivitiesFilterResults] = useState( [] )
  const [currentPage, setCurrentPage] = useState( 1 )
  const [disabled, setDisabled] = useState( false )
  const [wpTotalItems, setWpTotalItems] = useState( null )
  const [wpTotalPages, setWpTotalPages] = useState( null )
  const [activitiesList, setActivitiesList] = useState( [] )
  const [fetchMore, setFetchMore] = useState( false )
  const [resultsLoading, setResultsLoading] = useState( false )
  const [loadMoreClicked, setLoadMoreClicked] = useState( false )
  const [mobileFiltersEnabled, setMobileFiltersEnabled] = useState( false )

  const [searchTerm, setSearchTerm] = useState( '' )
  const [term, setTerm] = useState( '' )
  const [filteredData, setFilteredData] = useState( [] )

  const apiUrl = process.env.REACT_APP_API_URL

  const size = useWindowSize()

  function handleLoadMore() {
    if ( currentPage < wpTotalPages ) {
      setCurrentPage( currentPage + 1 )
      setResultsLoading( true )
      setFetchMore( true )
      setLoadMoreClicked( true )
    }
  }

  useEffect(() => {
    if ( typeof wpTotalPages === "number" ) {
      if ( currentPage >= wpTotalPages || isNaN( wpTotalPages )) {
        setDisabled( true )
      } else {
        if ( disabled ) {
          setDisabled(false)
        }
      }
    }
  }, [currentPage, wpTotalItems, wpTotalPages, disabled])


  const getTotals = useCallback(() => {
    return fetch(
      `${apiUrl}/wp-json/gbns/v2/activities/filtered?per_page=10&page=1&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`
    ).then( res => {
      setWpTotalItems( parseInt(res.headers.get("X-WP-Total")) )
      setWpTotalPages( parseInt(res.headers.get("X-WP-TotalPages")) )
      return res.json()
    })
  }, [apiUrl])

  useEffect(() => {
    getTotals()
  }, [getTotals])

  useEffect(() => {
    setLoadMoreClicked( false )
  }, [activitiesFilterResults])

  useEffect(() => {
    setCurrentPage( 1 )
    if ( term !== '' ) {
      fetch(`${apiUrl}/wp-json/gbns/v2/activities/filtered?per_page=10&page=1&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}&search_term=${term}`)
      .then( response => {
        setWpTotalItems( parseInt(response.headers.get("X-WP-Total")) )
        setWpTotalPages( parseInt(response.headers.get("X-WP-TotalPages")) )
        return response.json()
      })
      .then( resp => {
        if ( resp.length > 0 ) setFilteredData( resp )
        else setFilteredData( [] )
      })
      .catch( err => console.error( err ) )
    } 
    else {
      setFilteredData( [] )
    }
  }, [term, apiUrl])

  useEffect(() => {
    if ( filteredData.length !== 0 && term.length !== 0 ) {
      setInitLoading( false )
      setActivitiesList( filteredData )
    } else if ( filteredData.length === 0 && term.length !== 0 ) {
      setInitLoading( false )
      setActivitiesList( [] )
    } 
    else if ( filteredData.length === 0 ) {
      if ( response && response.length ) {
        setInitLoading( false )
        setActivitiesList( response )
      }
    } 
  }, [response, filteredData, term])

  useEffect(() => {
    if ( filterActive ) {
      if ( loadMoreClicked ) {
        if ( activitiesFilterResults.length ) {
          setActivitiesList( prevState => {
            return [...prevState, ...activitiesFilterResults]
          })
        } else {
          setActivitiesList( [] )
        }
      } else {
        setWpTotalItems( wpTotalItems )
        setWpTotalPages( wpTotalPages )
        setActivitiesList(() => {
          return [...activitiesFilterResults]
        })
      }
      setFilterActive( false )
    } else {
      setActivitiesList( prevState => {
        return [...prevState, ...activitiesFilterResults]
      })
    }
  }, [activitiesFilterResults, wpTotalItems, wpTotalPages])

  const loadMore = !isLoading ? (
    <CustomButton
      click={ handleLoadMore }
      isLoading={ isLoading }
      disabled={ disabled }
      className={ mobileStyles.customButton }>
      {t('translation:activities:prikazite-jos')}
    </CustomButton>
  ) : null

  const locale = {
    emptyText: (
      <Empty
        image={ Empty.PRESENTED_IMAGE_SIMPLE }
        className={ styles.noResults }
        imageStyle={{ height: 60 }}
        description={ <span>{t('translation:activities:nema-rezultata')}</span> }
      ></Empty>
    )
  }

  const enableMobileFilters = () => {
    setMobileFiltersEnabled( !mobileFiltersEnabled )
  }

  return size.width > 991 ? (
    <WithSider
      siderTitle={t('translation:activities:aktivnosti')}
      sider={
        <ActivitySearch
          toggleFilter={ setFilterActive }
          resultsOfFilter={ setActivitiesFilterResults }
          currentActivePage={ currentPage }
          setCurrentPage={ setCurrentPage }
          setTotalItems={ setWpTotalItems }
          setTotalPages={ setWpTotalPages }
          loadMoreStatus={ fetchMore }
          toggleLoadMore={ setFetchMore }
          setResultsLoading={ setResultsLoading }
        />
      }

      content={
        <Row style={{ marginBottom: 40 }}>
          <MetaDecorator 
            title = {textTranslation("Активности")}
          />
          {initLoading ? (
            <Skeleton
              active={ true }
              loading={ true }
              paragraph={{ rows: 10 }}
            ></Skeleton>

          ) : (

            <List
              loading={ isLoading }
              itemLayout="horizontal"
              dataSource={ activitiesList }
              loadMore={ loadMore }
              locale={ locale }
              renderItem={ activity => (
                <ActivityItem
                  key={ activity.id }
                  id={ activity.id }
                  activityStatus={ activity.acf.status }
                  date={ activity.acf.date }
                  location={ activity.acf.place }
                  category={ activity.acf.type }
                  title={ htmlToReact.parse(activity.title.rendered) }
                  description={ activity.acf.about }
                />
              )}
            ></List>
          )
          }
        </Row>
      }
      moreContent={<PopularActivities mobile={props.mobile} />}
    />

  ) : (
    <div style={{ padding: "20px" }}>

      <Row style={{ marginBottom: "15px" }}>
        <h1
          style={{
            fontFamily: '"Tinos", serif',
            color: "#292929",
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "40px"
          }}
        >
          {t('translation:activities:aktivnosti')}
        </h1>
      </Row>

      <Row
        style={{
          paddingBottom: "16px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Col span={ 8 }>
          <div
            className={ mobileStyles.filtersBtnWrapper }
            onClick={ enableMobileFilters }
          >
            <Icon
              type="filter"
              style={{
                fontSize: "22px",
                color: "#FF595E",
                verticalAlign: "text-bottom"
              }}
            />
            <p className={ mobileStyles.filtersTitle }>{t('translation:activities:filteri')}</p>
          </div>
        </Col>

        <Col span={16}>
          <form onSubmit={event => {
            event.preventDefault()
            setTerm( searchTerm )
          }}
        >
            <Search
              onChange={ event => {
                localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr' ? setSearchTerm (convert(event.target.value)) : setSearchTerm( event.target.value )
              }}
              placeholder={t('translation:activities:pretrazite-aktivnosti')}
              className={ mobileStyles.searchTop }
            />
          </form>
        </Col>

      </Row>


      <Row>
        {isLoading ? (
          <Skeleton
            active={ true }
            loading={ true }
            paragraph={{ rows: 10 }}
          ></Skeleton>

        ) : (

          <List
            loading={ isLoading }
            itemLayout="horizontal"
            dataSource={ activitiesList }
            loadMore={ loadMore }
            locale={ locale }
            renderItem={ activity => (
              <ActivityItem
                key={ activity.id }
                id={ activity.id }
                activityStatus={ activity.acf.status }
                date={ activity.acf.date }
                location={ activity.acf.place }
                category={ activity.acf.type }
                title={ htmlToReact.parse( activity.title.rendered ) }
                description={ activity.acf.about }
              />
            )}
          ></List>
        )}
      </Row>

      <Row>
        <PopularActivities mobile={ true }/>
      </Row>

      <div
        className={ mobileStyles.activitiesFiltersWrapper }
        style={{ display: mobileFiltersEnabled ? "block" : "none" }}
      >
        <ActivitySearch
          mobile={ true }
          closeFilters={ enableMobileFilters }
          toggleFilter={ setFilterActive }
          resultsOfFilter={ setActivitiesFilterResults }
          currentActivePage={ currentPage }
          setCurrentPage={ setCurrentPage }
          setTotalItems={ setWpTotalItems }
          setTotalPages={ setWpTotalPages }
          loadMoreStatus={ fetchMore }
          toggleLoadMore={ setFetchMore }
          isInitLoading={ initLoading }
          resultsLoading={ resultsLoading }
          setResultsLoading={ setResultsLoading }
          searchTerm={ term }
        />
      </div>

    </div>
  )
}

export default Activities
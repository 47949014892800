import React from "react";
import { Link } from "@reach/router";
import style from "./SingleLink.module.css";

function SingleLink({
	url = null,
	openInNewTab = false,
	title,
	to = undefined,
	className = "",
	style: customStyle
}) {
	return (
		<div
			style={customStyle}
			className={
				className !== "" ? `${className} ${style.wrapper}` : style.wrapper
			}>
			{to && <Link className={style.link} to={to} >{title}</Link> }
			{url && <a className={style.link} target={openInNewTab ? "_blank" : null} href={url}>{title}</a> }
		</div>
	);
}

export default SingleLink;

import React, { useState, useEffect, useCallback } from "react"
import styles from "./WpPage.module.css"
import { Typography, Skeleton } from "antd"
import { Parser } from "html-to-react"
import textTranslation from "../../textTranslation"

const { Text } = Typography

const WpPage = props => {
  const apiUrl = process.env.REACT_APP_API_URL

  const [aboutData, setAboutData] = useState( null )
  const [isLoading, setIsLoading] = useState( false )

  
  const fetchPage = useCallback(() => {
    setIsLoading( true )
    const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
    ? `${apiUrl}/wp-json/wp/v2/pages?slug=${props.id}&lang=` 
    : `${apiUrl}/wp-json/wp/v2/pages?slug=${props.id}&lang=${localStorage.getItem('lang')}`
    fetch(createUrl())
      .then( response => response.json() )
      .then( data => {
        setAboutData( data )
        setIsLoading( false )
        const numberOfPhotos = document.getElementById("numberOfPhotos")
        if( numberOfPhotos ) {
          numberOfPhotos.innerHTML = document.querySelectorAll(".wp-block-gallery img").length
        }
      })
  }, [props.id, apiUrl])

  useEffect(() => {
    fetchPage()
  }, [fetchPage])

  useEffect(() => {
    if (aboutData !== null) {
      setTimeout(() => {
        Array.from(document.getElementsByTagName('figcaption')).forEach(e => {
          e.addEventListener('click', event => {
            event.preventDefault()
            let downloadUrl = e.previousElementSibling.src
            window.open(`${downloadUrl}`, "_blank")
          })
        })
      }, 0)
    }
  }, [aboutData])

  const htmlParser = new Parser()

  let aboutContent

  if (aboutData && aboutData.length) {
    aboutContent = (
      <Skeleton avatar title={ true } loading={ isLoading } paragraph={{ rows: 8 }} active>
        <div className={ styles.aboutDescWrapper }>
          <Text className="about-description">
            { htmlParser.parse( textTranslation(aboutData[0].content.rendered) ) }
          </Text>
        </div>
      </Skeleton>
    )
  }

  return <div className={ props.wrapperClass }>{ aboutContent }</div>
}

export default WpPage

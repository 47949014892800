import React, { useState, useEffect } from "react"
import { Skeleton, List } from "antd"
import CustomButton from "../CustomButton/CustomButton"
import DigitizedMagazineModal from "../DigitizedMagazineModal/DigitizedMagazineModal"
import OurMagazineCard from "../OurMagazineCard/OurMagazineCard"
import { useTranslation } from 'react-i18next'
import "./OurMagazinesList.module.css"

const OurMagazinesList = props => {
  const { t } = useTranslation("translation")
  const [currentPage, setCurrentPage] = useState(1)
  const [initLoading, setInitLoading] = useState(true)
  const [magazineData, setMagazineData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [responseHeaders, setResponseHeaders] = useState(null)

  const PER_PAGE = 8
  const apiUrl = process.env.REACT_APP_API_URL
  
  const cyrParams = function() {
    if(props.category === "mozaik") {
      return "Мозаик" 
    } 

    if(props.category === "informativni-bilten") {
      return "Инфромативни билтен" 
    } 

    if(props.category === "bibliotecki-putokaz-za-decu") {
      return "Библиотечки путоказ за децу" 
    } 
  }

  useEffect(() => {
    setInitLoading(false)
    setCurrentPage(1)
    setMagazineData([])

    const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
    ? `${apiUrl}/wp-json/gbns/v2/our_magazines?per_page=${PER_PAGE}&page=${currentPage}&category=${cyrParams()}&lang=` 
    : `${apiUrl}/wp-json/gbns/v2/our_magazines?per_page=${PER_PAGE}&page=${currentPage}&category=${cyrParams()}&lang=${localStorage.getItem('lang')}`

    fetch(createUrl())
      .then(response => {
        setResponseHeaders({
          totalItems:response.headers.get("X-WP-Total"), 
          totalPages:response.headers.get("X-WP-TotalPages")
        })
        
        return response.json()
      })
      .then(data => {
        if(data.code !== "error") {
          setMagazineData(data)
        }
        setIsLoading(false)
      })
  }, [props.category])

  useEffect(() => {
    const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
    ? `${apiUrl}/wp-json/gbns/v2/our_magazines?per_page=${PER_PAGE}&page=${currentPage}&category=${cyrParams()}&lang=` 
    : `${apiUrl}/wp-json/gbns/v2/our_magazines?per_page=${PER_PAGE}&page=${currentPage}&category=${cyrParams()}&lang=${localStorage.getItem('lang')}`

    fetch(createUrl())
      .then(response => {
        setResponseHeaders({
          totalItems:response.headers.get("X-WP-Total"), 
          totalPages:response.headers.get("X-WP-TotalPages")
        })
        
        return response.json()
      })
      .then(data => {
        if(data.code !== "error") {
          setMagazineData(data)
          setMagazineData(magazineData.concat(data))
        }
        setIsLoading(false)
      })
  }, [currentPage])

  useEffect(() => {
    if (
      responseHeaders &&
      responseHeaders.totalPages &&
      currentPage < responseHeaders.totalPages
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [responseHeaders, currentPage])

  function handleLoadMore() {
    if (currentPage < responseHeaders.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  function handleOpenModal(magazine) {
    setModalData(magazine)
    setIsModalOpen(true)
  }
  function handleCloseModal() {
    setIsModalOpen(false)
  }

  return (
    <>
      {
        <List
          className="ourMagazineList"
          // grid={{ gutter: 50, xs: 2, sm: 3, md: 4 }}
          grid={{ gutter: window.screen.width <= 576 ? 20: 50, xs: 2, sm: 3, md: 4 }}
          loading={initLoading}
          loadMore={
            !isLoading && (
              <CustomButton
                click={handleLoadMore}
                isLoading={isLoading}
                disabled={disabled}>
                {t('translation:userGuide:prikazite-jos')}
              </CustomButton>
            )
          }
          dataSource={magazineData}
          renderItem={item => (
            <Skeleton avatar title={false} loading={initLoading} active>
              <List.Item >
                <OurMagazineCard
                  year={item && item.acf.year}
                  number={item.acf && item.acf.number}
                  month={item.acf && item.acf.month}
                  imgSrc={item.acf.pages[0] && item.acf.pages[0].sizes.medium}
                  click={() => handleOpenModal(item)}
                />
              </List.Item>
            </Skeleton>
          )}
        />
      }

      {modalData && (
        <DigitizedMagazineModal
          isVisible={isModalOpen}
          closeModal={handleCloseModal}
          data={modalData}
        />
      )}
    </>
  )
}

export default OurMagazinesList
import React from "react"
import styles from "./Indexpage.module.css"
import { Tabs, Row, Typography } from "antd"
import { useTranslation } from 'react-i18next'
import SingleLink from "../SingleLink/SingleLink"
import MobileProcuredBooks from "./ProcuredBooks/MobileProcuredBooks"
import MobileRecommendedBooks from "./RecommendedBooks/MobileRecommendedBooks"
import MobileBooksOfMonth from "./BooksOfMonth/MobileBooksOfMonth"
import MobileNewBooks from "./NewBooks/MobileNewBooks"
import MobileMostRedBooks from "./MostRedBooks/MobileMostRedBooks"

const { TabPane } = Tabs
const { Title } = Typography

export default function BookLovers() {
	const { t } = useTranslation("translation")

	return (
		<section style={{ padding: "20px" }}>
			<Row type="flex" justify={ window.screen.width <= 576 ? "center" : "start" } align={ "middle" } style={{marginBottom: "32px", marginTop: "4px"}}>
				<Title level={ window.screen.width <= 576 ? 4 : 3 } style={{fontWeight: 600, fontSize: "24px", fontFamily: "Tinos"}}>
					{t('translation:allTitlesSidebar:za-ljubitelje-knjige')}
				</Title>
			</Row>

			<Tabs defaultActiveKey="1" className={ styles.tabpanel }>
			
				<TabPane tab={t('translation:allTitlesSidebar:najcitaniji-naslovi')} key="najcitaniji_naslovi">
					<MobileMostRedBooks url={"/wp-json/wp/v2/most_read_books"} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:nove-knjige')} key="nove_knjige">
					<MobileNewBooks url={"/wp-json/wp/v2/new_books"} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:knjiga-meseca')} key="3">
					<MobileBooksOfMonth url={"/wp-json/wp/v2/books_of_month"} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:prinovljeni-naslovi')} key="4">
					<MobileProcuredBooks url={"/wp-json/wp/v2/procured_books"} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:preporuka-bibliotekara')} key="5">
					<MobileRecommendedBooks url={"/wp-json/wp/v2/recommended_books"} />
				</TabPane>
			
			</Tabs>
			
			<Row className={ styles.allTitles } type="flex" justify="center" align="middle" style={{marginBottom: "100px", marginTop: "40px"}}>
				<SingleLink to="/naslovi/najcitaniji-naslovi" title="svi naslovi" />
			</Row>
		</section>
	)
}

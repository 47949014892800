import React, { useEffect, useState } from "react"
import WithSider from "../../layouts/WithSider"
import AboutSidebar from "../../components/AboutSidebar/AboutSidebar"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import { Row, Col, Typography, Collapse } from "antd"
import { ExpandIcon } from "../CustomIcon/CustomIcon"
import { useTranslation } from 'react-i18next'
import styles from "./Friends.module.css"
import textTranslation from "./../../textTranslation"
import MetaDecorator from "../../metaDecorator"

const { Text, Title } = Typography
const { Panel } = Collapse

const Friends = (props) => {
	const { t } = useTranslation("translation")
  const [data, setData] = useState([])
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    // fetch(`${apiUrl}/wp-json/wp/v2/friends?lang=`)
      const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
      ? `${apiUrl}/wp-json/wp/v2/friends?lang=` 
      : `${apiUrl}/wp-json/wp/v2/friends?lang=${localStorage.getItem('lang')}`
      fetch(createUrl())
      .then(response => response.json())
      .then(data => {
        setData(data)
      })
  },[])

  function compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
  
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  function titleRender(el, index) {
    return <div key={ index }>
      { (el.capitalLetter) && <Title className={ styles.bigLetter } >{ textTranslation(el.capitalLetter) }</Title>}
      <div style={{margin:"5px"}} >
        {!!el.link 
          ? 
            <a href={ el.link.url } target="_blank" rel="noopener noreferrer">
              <Text 
                className={ styles.friendsName } 
                style={ {color: "#FF595E", textDecoration: "underline" } }
              >
                { textTranslation(el.name) }, 
                { textTranslation(el.location) }
              </Text>
            </a>
          :
            <Text className={ styles.friendsName }>
              { textTranslation(el.name) }, { textTranslation(el.location) }
            </Text>
          }
        </div> <br/>
    </div>
  }

  function sortData(el){
    let listOfFriends = el.acf.list_of_friends.sort(compare);
    const dividerLength = Math.round(listOfFriends.length/3);
    listOfFriends.forEach((element, index) => {
      if(!index){
        listOfFriends[index].capitalLetter = element.name[0].toUpperCase();
      }else if(element.name[0].toUpperCase() !== listOfFriends[index-1].name[0].toUpperCase()){
        listOfFriends[index].capitalLetter = element.name[0].toUpperCase();
      }
      
    });

    return{
       listOfFriends,
       first : listOfFriends.slice(0, dividerLength),
       second : listOfFriends.slice(dividerLength, dividerLength * 2),
       third : listOfFriends.slice(dividerLength * 2, listOfFriends.length)
    }
  
  }

  return(
    <WithSider
    siderTitle={t('translation:locationGroupList:o-nama')}
    sider={ <AboutSidebar uri={ props }/> }
    content={
      <Row>
        <MetaDecorator 
          title = {textTranslation("Пријатељи библиотеке")}
        />
        <Col >
          <h1>{ t('translation:Friends:prijatelji-bibiloteke') }</h1>
          <div className="accordionWrapper">
              <Collapse
                expandIconPosition="right"
                bordered={ false }
                defaultActiveKey={ ["0"] }
                style={ { backgroundColor:'white' } }
                expandIcon={ ( { isActive }) => (
                  <div className={ styles.iconWrapper } style={ { fontSize:'30px' } } >
                    <ExpandIcon isActive={ isActive }  />
                  </div>
                )}>
                  { data.length && data.map((el, index) => {

                    const { listOfFriends, first, second, third } = sortData(el);

                    return (
                      <Panel
                        key={ index }
                        header={
                          <Text className={ styles.header } strong>
                            { textTranslation(el.title.rendered) }
                          </Text>
                        }
                        className={ `${ styles.accordionItem }` }>
                        
                          <div className={ styles.friend }>
                          {
                          listOfFriends.length > 5 ?
                            <div style={ { display: 'flex' } }>
                              <div style={ { flex: 1, padding: "10px" } }>
                                  { first.map(titleRender) }
                              </div>
                              <div style={ { flex: 1, padding:"10px" } }>
                                  { second.map(titleRender) }
                              </div>
                              <div style={ { flex: 1, padding:"10px" } }>
                                  { third.map(titleRender) }
                              </div>
                            </div> : <div>{ listOfFriends.map(titleRender) }</div>
                          }
                          </div> 
                      </Panel>
                    )
                  })}
              </Collapse>
            </div>
        </Col>
      </Row>
    }
    moreContent={ <PopularActivities mobile={ props.mobile } />}
  />
  )
}

export default Friends

import React from "react"
import FooterRubricsContent from "./FooterRubricsContent"
import FooterRubricsSider from "./FooterRubricsSider"
import WithSider from "../../layouts/WithSider"
import { useTranslation } from 'react-i18next'

function IzdavackaDelatnost({ pod_rubrika, titleTransKey }) {
  const { t } = useTranslation("translation")

  return (
    <WithSider
      siderTitle={t(`translation:allTitles:${titleTransKey}`)}
      sider={<FooterRubricsSider uri={pod_rubrika} />}
      ignoreBottom={["contact"]}
      content={<FooterRubricsContent uri={pod_rubrika} />}
    />
  )
}

export default IzdavackaDelatnost

import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Popover, Icon } from 'antd'
import { Parser } from 'html-to-react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import style from './SingleBook.module.css'
import MetaDecorator from '../../metaDecorator'

const SingleBook = ({ id, ofMonth = false }) => {
  const { t } = useTranslation("translation")
  const apiUrl = process.env.REACT_APP_API_URL
  const [bookData, setBookData] = useState()

  useEffect( () => {
    fetch(`${apiUrl}/wp-json/wp/v2/books/${id}`)
    .then( response => response.json() )
    .then( resp => {
      setBookData( resp )
    })
  }, [apiUrl, id])

  const shareContent = (
    <div className={ style.socialMedia }>
      <LinkedinShareButton url={ window.location.href }>
        <Icon style={{ color: "#FF595E", margin: "4px" }} type="linkedin" />
      </LinkedinShareButton>
      <TwitterShareButton url={ window.location.href }>
        <Icon style={{ color: "#FF595E", margin: "4px" }} type="twitter" />
      </TwitterShareButton>
      <FacebookShareButton url={ window.location.href }>
        <Icon style={{ color: "#FF595E", margin: "4px" }} type="facebook" />
      </FacebookShareButton>
    </div>
  )
  
  const { Title, Paragraph } = Typography
  const htmlToReact = new Parser()
  
  return (
      <div 
        className={ style.singleBook__wrapper } 
        style={{ marginTop: "20px" }}
      >
        { bookData && !ofMonth && <div onClick={ () => window.history.back() }
          className={ style.singleBook__linkback }
          style={{ 
            marginBottom: "48px", 
            display: "inline-block", 
            width: "fit-content", 
            cursor: "pointer" 
          }}
        >
          &lt; { t('translation:singleBook:nazad') }
          </div>
        }
        { bookData && <div 
          style={{
            width: window.screen.width <= 990 ? "90vw" : "", 
            margin: "0 auto"
          }}>
        <Row 
          gutter={ 16 } 
          style={{ width: window.screen.width <= 990 ? "90vw" : "903px" }} 
          className={style.singleBook__row}
        >
          <Col 
            span={ 6 } 
            className={ style.singleBook }
          >
            <MetaDecorator 
              title = { textTranslation(bookData.title.rendered) }
              description = { textTranslation(bookData.acf.author) }
              image = {bookData.acf.image.url}
            />
            <div className={ style.singleBook__image } style={{ backgroundImage: `url(${bookData.acf.image.url})` }}></div>
            <div className={ style.singleBook__image__info }>
              <div className={ style.singleBook__image__line }>
                <p>{ t('translation:singleBook:format') } </p> 
                <span>{ bookData.acf.format }</span>
              </div>
              <div className={ style.singleBook__image__line }>
                <p>{ t('translation:singleBook:broj-stranica') } </p>
                <span>{ bookData.acf.number_of_pages }</span>
              </div>
              <div className={ style.singleBook__image__line }>
                <p>{ t('translation:singleBook:pismo') } </p> 
                <span>{ localStorage.getItem("lang") === '' ? bookData.acf.alphabet : textTranslation(bookData.acf.alphabet) }</span>
              </div>
              <div className={ style.singleBook__image__line }>
                <p>{ t('translation:singleBook:povez') } </p> 
                <span>{ localStorage.getItem("lang") === '' ? bookData.acf.binding : textTranslation(bookData.acf.binding) }</span>
              </div>
            </div>
          </Col>
          <Col 
            span={ 18 } 
            className={ style.singleBook }
          >
            <Paragraph 
              className={ style.singleBook__info__genre } 
              style={{ marginBottom: "0" }}
            >
              { localStorage.getItem("lang") === '' ? bookData.acf.genre.post_title : textTranslation(bookData.acf.genre.post_title) }
            </Paragraph>
            <Title 
              className={ style.singleBook__info__title } 
              style={{ marginTop: "0" }}
            >
              { localStorage.getItem("lang") === '' ? htmlToReact.parse(bookData.title.rendered) : htmlToReact.parse(textTranslation(bookData.title.rendered)) }
            </Title>
            <Paragraph className={ style.singleBook__info__author }>
              { textTranslation(bookData.acf.author) }
            </Paragraph>
            { bookData.recommended && <Paragraph className={ style.singleBook__info__recommended } style={{ marginBottom: "30px" }}>
              <span>{ t('translation:singleBook:preporuka') } </span> 
              { localStorage.getItem("lang") === '' ? bookData.acf.recommended_by : textTranslation(bookData.acf.recommended_by) }
            </Paragraph>}
            <Paragraph 
              className={ style.singleBook__info__description }
            >
              { localStorage.getItem("lang") === '' ? htmlToReact.parse(bookData.acf.about) : htmlToReact.parse(textTranslation(bookData.acf.about)) }
            </Paragraph>
          </Col>         
      </Row>

      { !ofMonth && <div className={ style.share }>
              <p>{ t('translation:singleBook:podelite-knjigu') }</p>
              <Popover placement="left" content={ shareContent } trigger="click">
                <Icon className={ style.shareIcon } type="share-alt" />
              </Popover>
            </div> 
        }
      </div>
      }
    </div>
  )
}

export default SingleBook
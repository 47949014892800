import React, { useState, useEffect, useCallback } from "react"
import styles from "./MobileMainNav.module.css"
import { Link } from "@reach/router"
import { Menu, Icon } from "antd"
import { useTranslation } from 'react-i18next'

import { mainNavData } from './mainNavData'
import textTranslation from "../../textTranslation"


const MainNav = props => {
	const { t } = useTranslation("translation")
  const { location, handleMenuClick } = props

  const [aboutUsData, setAboutUsData] = useState( false )
  const [faceOfNsData, setFaceOfNsData] = useState( null )

  const { SubMenu } = Menu
  const apiUrl = process.env.REACT_APP_API_URL
  const splitPath = location.pathname.split("/")

  const fetchAboutUsData = useCallback(() => {
    const createUrl = () => (
      localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
      ? `${apiUrl}/wp-json/wp-api-menus/v2/menus/12?lang=` 
      : `${apiUrl}/wp-json/wp-api-menus/v2/menus/12?lang=${localStorage.getItem('lang')}`

    return fetch(createUrl())
      .then( response => response.json() )
      .then( data => setAboutUsData( data ))
  }, [apiUrl])

  const fetchFaceOfNsData = useCallback(() => {
    const createUrl = () => (
      localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
      ? `${apiUrl}/wp-json/wp-api-menus/v2/menus/25?0?lang=` 
      : `${apiUrl}/wp-json/wp-api-menus/v2/menus/25?0?lang=${localStorage.getItem('lang')}`
    
    return fetch(createUrl())
    .then( response => response.json() )
    .then( data => setFaceOfNsData( data ))
  }, [apiUrl])

  useEffect(() => {
    fetchAboutUsData()
    fetchFaceOfNsData()
  }, [fetchFaceOfNsData, fetchAboutUsData])

  function contactItemHandler( item ) { 
    props.handleContactClick( item.key )
  }

  return (
    <Menu className={ styles.menu } selectedKeys={ [splitPath[splitPath.length - 1]] } inlineIndent={ 0 } mode="inline" >
      
      <Menu.Item key="katalog" className={`${styles.menuItem} ${styles.menuItemLinkWrapper}`}>
        <a href="https://opac.bisis.rs/lib/gbns" target="_blank" rel="noopener noreferrer" className={ styles.menuItemTitle } onClick={ handleMenuClick }>
          {t('translation:mainNav:katalog')}
        </a>
      </Menu.Item>

        <SubMenu key="subDigitalizacija" title={t('translation:mainNav:digitalizacija')} className={`${styles.menuItemTitle} ${styles.menuItem}`}>
          <SubMenu
            key="subDigMaterijal"
            title={t('translation:mainNav:digitalni-materijal')}
            inlineIndent={ 12 }
            style={{ paddingLeft: 20 }}
            className={ styles.subMenuGroupTitle }
          >
            <SubMenu key="zavicajnaZbirka" title={t('translation:mainNav:zavicajna-zbirka')} >      
              {/* {mainNavData.ZAVICAJNAZBIRKA.map(e => (
                <Menu.Item key={e.key} className={`${styles.subMenuItem} ${styles.menuItemLinkWrapper}`}>
                  <Link to={e.link} className={ styles.menuItemLink } style={{ paddingLeft: 30 }} onClick={ handleMenuClick }>
                    {t(e.trans)}
                  </Link>
                </Menu.Item>
              ))} */}
            </SubMenu>

            <SubMenu key="nasiCasopisi" title={t('translation:mainNav:nasi-casopisi')} >
              {mainNavData.NASICASOPISI.map(e => (
                <Menu.Item key={e.key} className={`${styles.subMenuItem} ${styles.menuItemLinkWrapper}`}>
                  <Link to={e.link} className={ styles.menuItemLink } style={{ paddingLeft: 30 }} onClick={ handleMenuClick }>
                    {t(e.trans)}
                  </Link>
                </Menu.Item>  
              ))}
            </SubMenu>
          </SubMenu>


        <Menu.Item key="digitalniOmladinskiCentar" className={`${styles.subMenuGroupTitle} ${styles.menuItemLinkWrapper}`} style={{ paddingLeft: 20 }}>
          <Link to="/digitalizacija/digitalni-omladinski-centar" className={ styles.menuItemLink } onClick={ handleMenuClick } style={{ paddingLeft: 20 }}>
            {t('translation:mainNav:digitalni-omladinski-centar')}
          </Link>
        </Menu.Item>

        <Menu.Item key="korisniLinkovi" style={{ paddingLeft: 20 }} className={`${styles.subMenuGroupTitle} ${styles.menuItemLinkWrapper}`}>
          <Link to="/digitalizacija/korisni-linkovi/" className={ styles.menuItemLink } style={{ paddingLeft: 20 }} onClick={ handleMenuClick } >
            {t('translation:mainNav:korisni-linkovi')}
          </Link>
        </Menu.Item>

      </SubMenu>

      
      <Menu.Item key="aktivnosti" className={`${styles.menuItemTitle} ${styles.menuItem} ${styles.menuItemLinkWrapper}`}>
        <Link to="aktivnosti" className={ styles.menuItemLink } onClick={ handleMenuClick } >
          {t('translation:mainNav:aktivnosti')}
        </Link>
      </Menu.Item>

      
      <SubMenu key="vodicZaKorisnike" title={t('translation:mainNav:vodic-za-korisnike')} className={`${styles.menuItemTitle} ${styles.menuItem}`}>
        {mainNavData.VODICZAKORISNIKE.map(e => (
          <Menu.Item key={e.key} className={`${styles.subMenuGroupTitle} ${styles.menuItemLinkWrapper} ${styles.singleItem}`} onClick={ contactItemHandler }>
            <Link to={e.link} className={ styles.menuItemLink } onClick={ handleMenuClick }>
              {t(e.trans)}
            </Link>
          </Menu.Item>
        ))}
      </SubMenu>

      <Menu.Item key="galerija" className={`${styles.menuItemTitle} ${styles.menuItem} ${styles.menuItemLinkWrapper}`}>
        <Link to="galerija" className={ styles.menuItemLink } onClick={ handleMenuClick }>
          {t('translation:mainNav:galerija')}
        </Link>
      </Menu.Item>

      <Menu.Item key="lokacije" className={`${styles.menuItemTitle} ${styles.menuItem} ${styles.menuItemLinkWrapper}`}>
        <Link to="lokacije" className={ styles.menuItemLink } onClick={ handleMenuClick }>
          {t('translation:mainNav:lokacije-i-radno-vreme')}
        </Link>
      </Menu.Item>

      <SubMenu key="kontakt" title={t('translation:mainNav:kontakt')} className={`${styles.menuItemTitle} ${styles.menuItem}`}>
        {mainNavData.CONTACT.map(e => (
          <Menu.Item key={e.key} className={`${styles.subMenuGroupTitle} ${styles.menuItemLinkWrapper} ${styles.singleItem}`} onClick={ contactItemHandler }>
            <Link to={e.link} className={ styles.menuItemLink } onClick={ handleMenuClick }>
              {t(e.trans)}
            </Link>
          </Menu.Item>
        ))}
      </SubMenu>


      <SubMenu key="subLiceNs" title={t('translation:mainNav:lice-novog-sada')} className={`${styles.menuItemTitle} ${styles.menuItem}`}>
        {faceOfNsData && faceOfNsData.items.map(el => {
            return (
              <SubMenu className={styles.subMenuGroupTitle} key={el.id} title={textTranslation(el.title)} inlineIndent={12} style={{ paddingLeft: 20 }}>
                {el.children && el.children.map(subMenuItem => {
                    return (
                      <Menu.Item key={ subMenuItem.id } className={`${styles.subMenuItem} ${styles.menuItemLinkWrapper}`}>
                        <Link 
                          to={`/lice-novog-sada${el.url}${subMenuItem.url}`}
                          className={ styles.menuItemLink }
                          style={{ paddingLeft: 30 }}
                          onClick={ handleMenuClick }
                        >
                          { textTranslation(subMenuItem.title) }
                        </Link>
                      </Menu.Item>
                    )
                  })}
              </SubMenu>
            )
          })}
      </SubMenu>
      <SubMenu
        key="o-nama"
        title={t('translation:mainNav:o-nama')} 
        className={`${styles.menuItemTitle} ${styles.menuItem} ${styles.menuItemAboutUs} ${styles.menuItemLinkWrapper}`}
      >
        {aboutUsData && aboutUsData.items.map( item => {
            return item.children ? (
              <SubMenu key={ item.id } title={ item.title } inlineIndent={ 12 } style={{ paddingLeft: 20 }} className={ styles.subMenuGroupTitle }>
                {item.children.map( childItem => {
                  return (
                    <Menu.Item key={ childItem.id } className={`${styles.subMenuItem} ${styles.menuItemLinkWrapper}`}>
                      <Link 
                        to={`/o-nama${item.url}${childItem.url}`}
                        className={ styles.menuItemLink }
                        style={{ paddingLeft: 30 }}
                        onClick={ handleMenuClick }
                      >
                        { textTranslation(childItem.title) }
                      </Link>
                    </Menu.Item>
                  )
                })}
              </SubMenu>

            ) : (

              <Menu.Item className={`${styles.subMenuGroupTitle} ${styles.menuItemLinkWrapper}`} key={ item.id } style={{ paddingLeft: 20 }}>
                <Link
                  to={`/o-nama${item.url}`}
                  className={ styles.menuItemLink }
                  style={{ paddingLeft: 20 }}
                  onClick={ handleMenuClick }
                >
                  { textTranslation(item.title) }
                </Link>
              </Menu.Item>

            )
          })}

      </SubMenu>

        {mainNavData.FOOTER.map(e => (
          <Menu.Item className={`${styles.mobileMenuFooterItem} ${styles.mobileMenuAdditionalItem}`} key={e.key}>
            <Link to={e.link} onClick={ handleMenuClick }>
              <Icon width="16px" type={e.type} />
              {t(e.trans)}
            </Link>
          </Menu.Item>
        ))}
        
    </Menu>
  )
}

export default MainNav

import React from "react"
import { Typography } from "antd"
import { useTranslation } from 'react-i18next'

import styles from "./Postcard.module.css"

const { Text, Title } = Typography

function Postcard({ year, month, imgSrc, click }) {
	const { t } = useTranslation("translation")

  return (
    <div className={ styles.card }>
    <div
      onClick={ click }
      className={ styles.coverImg }
      style={{ backgroundImage: `url(${imgSrc})` }}></div>
    <Title className={ styles.title } level={ 4 } onClick={ click }>
      { year }
    </Title>
    <Text className={ styles.secondary }>
      <span className={ styles.secondaryLabel }> { t('translation:postCard:mesec') }</span> 
      { month }
    </Text>
  </div>
  )
}

export default Postcard
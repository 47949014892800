const singleActivity = {
  title: "Promocija romana Zorice Tijanic",
  date: "Utorak, 17. septembar 2019.",
  time: "19.00",
  location: "Novi Sad (Dunavska 1)",
  coverPhoto: "https://via.placeholder.com/500x500",
  bookTitle: "Do svidanja, Avgustina",
  author: "Zorica Tijanic",
  others: "Viktor Radun, Milena Letic i Zorica Tijanic",
  music: "Mirko Kluz, gitara",
  description:
    "Novi roman knjizevnice i novinarke Zorice Tijanic, esejistkinje, putopisca, knjizevne kriticarke, kolumnistkinje i urednice knjiga poezije.",
  about:
    "Sunce je previsoko. Noc je. Gusta tmina se izliva iz sebe same i siri ses organizmom poput bolesti, kojim tece crna krv i uliva se u melanholicne tisine Azovskog mora, gde se kao utvara davno zaboravljenog secanja, njise, na hladnim vetrovima, jedna mala upaljena sveca ciji se plamen zove - Ljubav;",
  about2:
    "Sunce je safa preblizu, topi nas, i mi nestajemo da bi postali, tamo negde daleko, gde su prazna rec i laz nemoguci, gde se pije samo nektat i istine: nestajemo i postajemo, citajuci ga, u romani Zorice Tijanic, Avgustina.",
  writtenBy: "Boris Mandic"
}

export default singleActivity

import React, { useState } from "react"
import { Row, Menu, Skeleton, Divider, Checkbox } from "antd"
import WithSider from "../../layouts/WithSider"
import useFetch from "../../hooks/useFetch"
import LocationContainer from "./LocationContainer"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import style from "./Location.module.css"
import MetaDecorator from "../../metaDecorator"

const Index = () => {
  const { t } = useTranslation("translation")
  const [id, setId] = useState("Градски")
  const [checkedLanguages, setCheckedLanguages] = useState([])
  const { response, isLoading } = useFetch(
    "/wp-json/wp/v2/location_groups"
  )

  const langOfBooks = [
    'мађарски',
    'словачки',
    'румунски',
    'енглески',
    'грчки',
    'шпански',
    'француски',
    'италијански',
    'холандски',
    'португалски',
    'руски',
    'русински',
    'шведски',
    'турски',
    'украјински',
    'кинески',
    'арапски',
    'чешки',
    'фински',
    'хебрејски',
    'македонски',
    'норвешки',
    'пољски',
    'бугарски',
    'немачки'
]

  const setIdOnClick = param => {
    setId(param)
  }

  function onChangeCheckBox(checkedValues) {
    setCheckedLanguages([...checkedValues])
  }

  const filterByLocation = () => {
    if (!isLoading) {
      return response.filter(item => id === item.acf.name)
    }
  }

  const filterByLang = () => {
    let arr = checkedLanguages.map(lang => {
      let tempLoc = filterByLocation()
      
      return tempLoc[0].acf.locations.filter(item => {
        if(item.acf.language.includes(lang)) {
          return item
        }
        return null
      })
    })
    return [...new Set(arr.flat())]
  }

  const singleLang = langOfBooks.map( lang => 
    <Row key={ lang }>
      <Checkbox className={ style.checkbox } value={ lang }>
        { textTranslation(lang) }
      </Checkbox>
    </Row>
  )

  return (
    <WithSider
      siderTitle={t('translation:index.lokacije')}
      sider={
        <Menu
          className="locationMenu"
          style={{ width: 256, color: "#888A8C" }}
          defaultSelectedKeys={ ["1"] }
          defaultOpenKeys={ ["sub1"] }
          mode="inline"
        >
          <MetaDecorator
            url = {window.location.href}
            title = {textTranslation("Локације")}
          />
          <Menu.Item key="1" onClick={ () => setIdOnClick("Градски") }>
          { t('translation:index.gradski-ogranci') }
          </Menu.Item>
          <Menu.Item key="2" onClick={ () => setIdOnClick("Приградски огранци") }>
          { t('translation:index.prigradski-ogranci') }
          </Menu.Item>
          <Menu.Item key="3" onClick={ () => setIdOnClick("За децу") }>
          { t('translation:index.za-decu') }
          </Menu.Item>
          <Menu.Item key="4" onClick={ () => setIdOnClick("За студенте") }>
          { t('translation:index.za-studente') }
          </Menu.Item>
          <Menu.Item key="5" onClick={ () => setIdOnClick("За одрасле") }>
          { t('translation:index.za-odrasle') }
          </Menu.Item>
          <Menu.Item
            key="6"
            onClick={ () => setIdOnClick("За лица са специјалним потребама") }>
            { t('translation:index.za-lica-sa-specijalnim-potrebama') }
          </Menu.Item>
          <div>
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={ onChangeCheckBox } 
              >
              <Row>
                <p style={{ fontSize: "13px", fontFamily: "'Roboto', serif" }}>
                  <span style={{ fontSize: "10px" }}>
                    <strong>{ t('translation:index.jezik') }</strong>
                  </span>
                  <br></br>
                  ( {t('translation:index.ogranci-u-kojima-ima-knjiga-na-stranim-jezicima-i-jezicima-nacionalnih-manjina') })
                </p>
              </Row>
              <Divider style={{ margin: "0 0 15px" }} />
              <div className={ style.language }>
              { singleLang }
              </div>
            </Checkbox.Group>
          </div>
        </Menu>
      }
      content={
        isLoading ? (
          <Skeleton
            active={ true }
            loading={ true }
            paragraph={{ rows: 16}}
          ></Skeleton>
        ) : (
          <LocationContainer
          className='locationContainer'
          data={
            !isLoading
              ? checkedLanguages.length
                ? filterByLang()
                : filterByLocation()
              : null
          }
          checkedLanguages={ checkedLanguages }
          />
        )
      }
    />
  )
}

export default Index
import React, { useState } from "react"
import { List, Typography, notification } from "antd"
import axios from "axios"
import ContactModal from "../ContactModal/ContactModal"
import apiUrl from "../../apiUrl"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { Link } from "@reach/router"

import style from "./ContactItem.module.css"

function ContactItem({ data }) {
	const { t } = useTranslation("translation")

  const [modalVisible, setModalVisible] = useState( false )
  const [confirmLoading, setConfirmLoading] = useState( false )
  const closeModal = () => setModalVisible( false )
  const { id } = data

  const contactData = ( sender_email, sender_full_name, sender_subject, sender_message, g_recaptcha_response, receiver_id ) => {
    return {
      sender_email,
      sender_full_name,
      sender_subject,
      sender_message,
      g_recaptcha_response,
      receiver_id
    }
  }

  const postContactForm = data => {
    setConfirmLoading( true )
    axios.post(`${apiUrl}/wp-json/gbns/v2/contact_form`, JSON.stringify(data)).then(e => {
        setConfirmLoading( false )
        notification.success({ message: t('translation:contactItem:poruka-je-uspešno-poslata') })
        setModalVisible( false )
      }).catch( e => {
        setConfirmLoading( false )
        if (e.response && e.response.data && e.response.data.message) {
          e.response.data.message.forEach(( message ) => {
            notification.warning({ message: message })
          })
        } else {
          notification.warning({ message: t('translation:surveyForm:doslo-je-do-greske') })
        }
      })
  }

  const contactModalProp = ({ sender_email, sender_full_name, sender_subject, sender_message }, recaptchaKeyResponse) => {
    postContactForm(contactData(sender_email, sender_full_name, sender_subject, sender_message, recaptchaKeyResponse, id))
  }

  return (
    <>
      <List.Item className={ style.wrapper } onClick={ () => setModalVisible( true ) }>
        <div>
          { data.acf.title && (
            <span className={ style.title }>{ textTranslation(data.acf.title) }</span>
          )}
          <Typography.Title className={ style.name } level={ 4 }>
            { textTranslation(data.acf.name) }
          </Typography.Title>
          <p className={ style.title } style={{fontSize: "1rem"}}>
            { textTranslation(data.acf.telephone) }
          </p>
        </div>
        <div>
          <div className={ style.contact }> { t('translation:contact:kontakt') }</div>
        </div>
      </List.Item>
      
      <ContactModal
        title={ textTranslation(data.acf.name) }
        address={ textTranslation(data.acf.address) }
        titula={ textTranslation(data.acf.title) }
        visible={ modalVisible }
        onCancel={ closeModal }
        contactModalProp={ contactModalProp }
        confirmLoading={ confirmLoading }
      />
    </>
  )
}

export default ContactItem
import React from "react"
import { Icon } from "antd"
import style from "./CustomIcon.module.css"

const ExpandSvg = isActive => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="minus"
    className={style.expandIconSvg}
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512">
    <path
      className={`${style.expandIcon}`}
      fill="currentColor"
      d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
    <path
      className={`${style.expandIcon} ${isActive ? style.open : style.closed}`}
      fill="currentColor"
      d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
  </svg>
)

const ExpandIcon = props => {
  const { isActive } = props
  return <Icon component={() => ExpandSvg(isActive)} />
}

export { ExpandIcon }

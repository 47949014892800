import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import './ContactModal.css';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';

const { TextArea } = Input

function ModalSubscribe( props ) {

  const { t } = useTranslation("translation")
  const { form } = props
  const { getFieldDecorator, validateFields } = form
  const [recaptchaKeyResponse, setrecaptchaKeyResponse] = useState( null )

  const onChange = ( responseKey)  => {
    setrecaptchaKeyResponse( responseKey )
  }

  const handleOk = () => {
    validateFields(( err, values ) => {
      if (!err) {
        props.handleClick( values, recaptchaKeyResponse )
      }
    })
  }

  return (
    <Modal
      { ...props }
      className="contact-modal"
      okText={ t('translation:modalSubscribe:posaljite') }
      onOk={ handleOk }
      okButtonProps={{ disabled: recaptchaKeyResponse === null }}
      cancelText={ t('translation:modalSubscribe:nazad') }
    >

      { props.address && <span className="contact-modal-adresa">{ props.address }</span> }
      <Form layout="vertical">

        <Form.Item label={ t('translation:modalSubscribe:ime-i-prezime') }>
          {getFieldDecorator("full_name", {
            rules: [{ required: true, message: t('translation:modalSubscribe:unesite-vase-ime') }]
          })(
            <Input size="large" placeholder= { t('translation:modalSubscribe:unesite-vase-ime') }/>
          )}
        </Form.Item>

        <Form.Item label={ t('translation:modalSubscribe:email') }>
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: t('translation:modalSubscribe:unesite-vas-email') },
              { type: "email", message: t('translation:modalSubscribe:unesite-vas-email')}
            ]
          })(
            <Input size="large" placeholder={ t('translation:modalSubscribe:unesite-vas-email') }/>
          )}
        </Form.Item>

        <Form.Item label={ t('translation:modalSubscribe:naslov-poruke') }>
          {getFieldDecorator("subject", {})(
            <Input size="large" placeholder={ t('translation:modalSubscribe:unesite-naslov-vase-poruke') }/>
          )}
        </Form.Item>

        <Form.Item label={ t('translation:modalSubscribe:poruka') }>
          {getFieldDecorator("message", {})(
            <TextArea size="large" rows="6" placeholder={ t('translation:modalSubscribe:unesite-vasu-poruku') }/>
          )}
        </Form.Item>

          <span className="ant-form-item-required">{ t('translation:modalSubscribe:obavezna-polja') }</span>

        <Form.Item>
          <ReCAPTCHA sitekey='6LeyAqAaAAAAAEmIaowm5Zb7Ydk2h0lQvRBuvezY' onChange={ onChange } />
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default Form.create()(ModalSubscribe);

import React from 'react'
import { Layout } from 'antd';

import styles from './Layout.module.css'

const { Content } = Layout;

export default props => {
  return (
      <Layout className={ styles.layout } style={{ overflowX: "hidden", overflowY: "auto" }}>
          <Content>
              { props.children }
          </Content>
      </Layout>
  )
}

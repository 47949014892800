import React, { useState, useEffect, useCallback } from "react"
import { Row, Col, Typography, Skeleton } from "antd"
import { Parser } from "html-to-react"
import { useTranslation } from 'react-i18next'
import WithSider from "../../layouts/WithSider"
import PopularActivities from "../PopularActivities/PopularActivities"
import DigitizationSidebar from "../DigitizadionSidebar/DigitizationSidebar"
import SingleLink from "../SingleLink/SingleLink"
import textTranslation from "../../textTranslation"

import styles from "./DigitizedMagazine.module.css"
import Meta from "antd/lib/card/Meta"
import MetaDecorator from "../../metaDecorator"

const { Text } = Typography

const DigitizedMagazine = props => {
  const { t } = useTranslation("translation")
  const [magazineData, setMagazineData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const apiUrl = process.env.REACT_APP_API_URL

  const fetchMagazine = useCallback(() => {
    setIsLoading(true)
    const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr')
    ? `${apiUrl}/wp-json/wp/v2/pages?slug=${props.id}&_embed&lang=` 
    : `${apiUrl}/wp-json/wp/v2/pages?slug=${props.id}&_embed&lang=${localStorage.getItem('lang')}`
    return fetch(createUrl())
      .then(response => response.json())
      .then(data => {
        setMagazineData(data)
        setIsLoading(false)
      })
  }, [props.id, apiUrl])

  useEffect(() => {
    fetchMagazine()
  }, [fetchMagazine])

  const htmlToReact = new Parser()
  let magazineContent

  if (magazineData && magazineData.length) {
    magazineContent = (
      <>
      <MetaDecorator />
        <div className={ styles.magazineTitle }>
          { textTranslation(magazineData[0].title.rendered) }
        </div>
        <div
          className={ styles.headerImg }
          style={{backgroundImage: `url(${magazineData[0]._embedded["wp:featuredmedia"]["0"].source_url})`}}>
        </div>
        <div className={ styles.magazineDescWrapper }>
          <Text className="magazine-description">
            { htmlToReact.parse(textTranslation(magazineData[0].content.rendered)) }
          </Text>
          <SingleLink to="prikaz-svih-brojeva" title={t('translation:digitizedMagazine:pogledajte-sve-brojeve-biltena')} />
        </div>
      </>
    )
  }

  return (
    <WithSider
      siderTitle={ t('translation:digitizedMagazine:digitalizacija') }
      sider={ <DigitizationSidebar id={ props.id }/> }
      content={
        <Row>
          <Col>
            <Skeleton avatar title={ false } loading={ isLoading } paragraph={{ rows: 4 }} active>
              { magazineContent }
            </Skeleton>
          </Col>
        </Row>
      }
      moreContent={ <PopularActivities mobile={ props.mobile }/> }
    />
  )
}
export default DigitizedMagazine
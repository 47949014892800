import React from 'react'
import { Typography, List, Skeleton } from 'antd'
import { Link } from '@reach/router'
import SingleLink from '../SingleLink/SingleLink'
import useFetch from '../../hooks/useFetch'
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import style from './SideFaq.module.css'


function SideFaq() {
	const { response, isLoading } = useFetch(`/wp-json/wp/v2/faqs?lang=${localStorage.getItem("lang")}`);
	const { Title, Paragraph } = Typography;
	const { t } = useTranslation("translation")

	return (
		<>
			<List
				header={
					<Title className={ style.title } level={ 2 }>
						{ t('translation:sideFaq:kako-da') }
					</Title>
				}
				footer={
					<>
						<p>{ t('translation:sideFaq:ne-mozes-da-nades-to-sto-trazis') }</p>
						<SingleLink to='/kontakt' title={ t('translation:sideFaq:pitaj-bibotekara') } />
					</>
				}
				loading={ isLoading }
				dataSource={ response || [] }
				renderItem={ item => (
					<Link to='/vodic-za-korisnike/cesto-postavljana-pitanja'>
						<Paragraph className={ style.point }>{ textTranslation(item.title.rendered) }</Paragraph>
					</Link>
				)}>
				<Skeleton active={ true } loading={ isLoading } paragraph={{ rows: 10 }} />
			</List>
		</>
	)
}

export default SideFaq
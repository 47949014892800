import React from "react"
import styles from "./BookOfMonth.module.css"
import { Typography } from "antd"
import { useTranslation } from 'react-i18next'
import textTranslation from "./../../../textTranslation"
import { Parser } from 'html-to-react'
import SingleLink from "../../SingleLink/SingleLink"

const { Paragraph, Title } = Typography

export default ({
	id,
	title,
	imgSrc,
	author,
	info,
	bckDrop,
	ganre,
	active,
	i,
	setActive
}) => {
	const { t } = useTranslation("translation")
	const htmlToReact = new Parser()
	function shortDesc() {
		if ( info !== undefined ) {
			return (htmlToReact.parse(textTranslation(info)))
		}
		return 
	}

	return (
		<>
			{ active ? (
				<div className={ styles.bookItemActive }>

					<div>
						<img
							src={ `${imgSrc}` }
							className={ styles.imageContainer }
							style={{ height: "284px", width: "185px" }}
							alt="book-cover"
						/>
					</div>

					<div className={ styles.details }>
					
						<Title level={ 1 } className={ styles.indexActive }>
							{ bckDrop }
						</Title>
					
						<span className={ styles.ganre }>
							{ ganre.post_title ? ganre.post_title.toUpperCase() : "" }
						</span>
					
						<Title 
							level={3}
							className={ styles.title }
							ellipsis={{ rows: 2 }}
							style={{
								wordBreak: "break-word",
								color: "#071013",
								fontFamily: "Tinos",
								height: "80px",
								fontSize: "30px",
								margin: "0",
								width: "70%"
							}}
						>
							{ title }
						</Title>
					
						<div className={ styles.author }>
							{ author }
						</div>
					
						<Paragraph 
							className={ styles.text }
							style={{ opacity: "0.75", width: "60vw"}}
						>
							{ shortDesc(info) }
						</Paragraph>

						<SingleLink to={`/knjiga/${id}`} title={t('translation:featuredActivity:procitaj-vise')}/>
					
					</div>
				
				</div>

			) : (

				<div className={ styles.bookItemInactive } 
					onClick={() => setActive(i)}
					style={{ marginTop: "24px" }}	
				>

					<img
						src={ imgSrc }
						className={ styles.imageContainer }
						style={{ height: "220px", width: "140px" }}
						alt="book-cover"
					/>
					
					<div className={ styles.info }>
						<Title level={1} className={ styles.indexInactive }>
							{ bckDrop }
						</Title>

						<Title 
							level={3}
							className={ styles.title }
							ellipsis={{ rows: 2 }}
							style={{
								wordBreak: "break-word",
								color: "#071013",
								fontFamily: "Tinos",
								height: "60px",
								fontSize: "20px",
								margin: "0",
								width: "70%"
							}}
						>
						{ title }
						</Title>

						<Paragraph 
							className={ styles.author }
							ellipsis={{ rows: 2}}
							style={{ margin: 0 }}
						>
							{ author }
						</Paragraph>
					</div>

				</div>
			)}
		</>
	)
}
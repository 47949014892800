import React from "react";
import WithSider from "../../layouts/WithSider";
import { Row, Skeleton } from "antd";
import { Parser } from "html-to-react";
import useFetch from "../../hooks/useFetch";
import UserGuidenceSidebar from "../../components/UserGuidenceSidebar";
import SingleFAQ from "./SingleFAQ";
import { useTranslation } from 'react-i18next'
import textTranslation from './../../textTranslation'
import MetaDecorator from "../../metaDecorator";

export default function FAQPage({ uri }) {
	const { t } = useTranslation("translation")
	const htmlToReact = new Parser();
	const { response, isLoading } = useFetch("/wp-json/wp/v2/faqs");

	return (
		<WithSider
			siderTitle={t('translation:FAQ:cesto-postavljana-pitanja')}
			sider={<UserGuidenceSidebar uri={uri} />}
			content={
				
				<Row style={{ marginTop: 40 }}>
					<MetaDecorator
						title = {textTranslation("Често постављана питања")}
					/>
					{isLoading ? (
						<Skeleton
							active={true}
							loading={true}
							paragraph={{ rows: 10 }}></Skeleton>
					) : (
						response.map((faq) => (
							<SingleFAQ
								key={faq.id}
								id={faq.id}
								title={htmlToReact.parse(textTranslation(faq.title.rendered))}
								text={htmlToReact.parse(textTranslation(faq.acf.answer))}
							/>
						))
					)}
				</Row>
			}
		/>
	);
}

import React from "react"
import styles from "./OurMagazineCard.module.css"
import { Typography } from "antd"
import { useTranslation } from 'react-i18next'

const { Text } = Typography

function MagazineCard({ year, number, month, imgSrc, click }) {
  const { t } = useTranslation("translation")

  return (
    <div className={ styles.card }>
      <div
        onClick={click}
        className={styles.coverImg}
        style={{ backgroundImage: `url(${imgSrc})` }}
      ></div>
      
      <Text className={ styles.secondary }>
        <span className={ styles.secondaryLabel }>{t('translation:magazineCard:godina')}</span> { year }
      </Text>

      <Text className={ styles.secondary }>
        <span className={ styles.secondaryLabel }>{t('translation:magazineCard:broj')}</span> { number }
      </Text>
      
      <Text className={ styles.secondary }>
        <span className={ styles.secondaryLabel }>{t('translation:magazineCard:mesec')}</span> { month }
      </Text>
    </div>
  )
}

export default MagazineCard

import React from "react"
import WithSider from "../../layouts/WithSider"
import PopularActivities from "../PopularActivities/PopularActivities"
import DigitizationSidebar from "../DigitizadionSidebar/DigitizationSidebar"
import OurMagazinesList from "../OurMagazinesList/OurMagazinesList"
import { useTranslation } from 'react-i18next'

function OurMagazines( props ) {
  const { t } = useTranslation("translation")
  return (
    <WithSider
      siderTitle={ t('translation:mainNav:digitalizacija') }
      sider={<DigitizationSidebar id={ props.uri }/>}
      content={<OurMagazinesList category={ props.id }/>}
      moreContent={<PopularActivities mobile={ props.mobile } />}
    />
  )
}

export default OurMagazines
import React, { useState, useCallback, Fragment } from "react"
import { LocationProvider } from "@reach/router"
import { Carousel, Row, Col, Button } from "antd"
import SingleLink from "../../components/SingleLink/SingleLink"
import styles from "./PopularActivities.module.css"
import ActivityCard from "../Activity/ActivityCard/ActivityCard"
import useFetch from "../../hooks/useFetch"
import { useTranslation } from 'react-i18next'

function PopularActivities(props) {
	const { t } = useTranslation("translation")

  const [ref, setRef] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const { response } = useFetch("/wp-json/wp/v2/activities")

  const carousel = useCallback(ref => {
    setRef(ref)
  }, [])

  const desktopSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  };
  const mobileSettings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "40px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next)
    }
  }

  function next() {
    ref.slick.slickNext()
  }

  function prev() {
    ref.slick.slickPrev()
  }

  function filterPopularActivities(data = []) {
    return data.length ? data.filter(v => v.acf.popular) : data
  }
  return (
    <></>
    // <LocationProvider>
    //   {context => (
    //     <div className={styles.content}>
    //       <div className={styles.container}>
    //         <article className="popularActivities">
    //           <header className={styles.header}>
    //             <h3 className={styles.title}>{t('translation:popularActivities:aktuelno')}</h3>
    //             <SingleLink className={styles.allActivities} to="/aktivnosti" title={t('translation:popularActivities:sve-aktivnosti')} />
    //           </header>
    //           <main>
    //             {response.length > 0 && (
    //               <Carousel ref={carousel} {...(props.mobile ? mobileSettings : desktopSettings)}>
    //                 {filterPopularActivities(response).map(
    //                   (activity, index) => (
    //                     <Fragment key={index}>
    //                       <Row style={{ marginBottom: 40 }} gutter={24} >
    //                       <Col key={activity.id} >
    //                         <ActivityCard
    //                           onClick={(event) => {
    //                             context.navigate(`/aktivnosti/${activity.id}`)
    //                           }}
    //                           title={activity.title.rendered}
    //                           description={activity.acf.description}
    //                           imageSrc={activity.acf.image.url}
    //                           type={activity.acf.type}
    //                           isActive={index === activeSlide}
    //                         />
    //                       </Col>
    //                       </Row>
    //                     </Fragment>
    //                   )
    //                 )}
    //               </Carousel>
    //             )}
    //             {filterPopularActivities(response).length > 4 && (
    //               <div className={styles.actions}>
    //                 <span className={styles.actionItem}>
    //                   <Button onClick={prev} shape="circle" icon="left" />
    //                 </span>
    //                 <span className={styles.actionItem}>
    //                   <Button onClick={next} shape="circle" icon="right" />
    //                 </span>
    //               </div>
    //             )}
    //           </main>
    //         </article>
    //       </div>
    //     </div>
    //   )}
    // </LocationProvider>
  )
}

export default PopularActivities

import React, { useState, useEffect, useRef} from "react"
import { Skeleton, List } from "antd"
import useFetch from "../../hooks/useFetch"
import DigitizedBookModal from "../DigitizedBookModal/DigitizedBookModal"
import CustomButton from "../CustomButton/CustomButton"
import Postcard from "../Postcard/Postcard"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import "./PostcardList.module.css"
import MetaDecorator from "../../metaDecorator"

const PostcardList = props => {
  const { t } = useTranslation("translation")
  const [currentPage, setCurrentPage] = useState(1)
  const [initLoading, setInitLoading] = useState(true)
  const [postcardData, setPostcardData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const childRef = useRef();

  const { response, isLoading, responseHeaders } = useFetch(
    "/wp-json/wp/v2/digitized_postcards",
    {
      per_page: 8,
      page: currentPage
    }
  )

  useEffect( () => {
    setInitLoading( false )
  }, [])

  useEffect( () => {
    setPostcardData( response )
    setPostcardData( postcardData.concat( response ) )
  }, [response])

  useEffect( () => {
    if (
      responseHeaders &&
      responseHeaders.totalPages &&
      currentPage < responseHeaders.totalPages
    ) {
      setDisabled( false )
    } else {
      setDisabled( true )
    }
  }, [responseHeaders, currentPage])

  function handleLoadMore() {
    if ( currentPage < responseHeaders.totalPages ) {
      setCurrentPage( currentPage + 1 )
    }
  }

  function handleOpenModal( postcard ) {
    setModalData( postcard )
    setIsModalOpen( true ) 
   
    
  }
  function handleCloseModal() {
    // childRef.current.showAlert();
    setIsModalOpen( false )
  }

  return (
    <>
      <MetaDecorator 
        title = { textTranslation("Разгледнице")} 
      />
      {
        <List
          className="postcardList"
          grid={{ gutter: window.screen.width <= 576 ? 20: 50, xs: 2, sm: 3, md: 4 }}
          loading={ initLoading }
          loadMore={
            !isLoading && (
              <CustomButton
                click={ handleLoadMore }
                isLoading={ isLoading }
                disabled={ disabled }>
                { t('translation:userGuide:prikazite-jos') }
              </CustomButton>
            )
          }
          dataSource={ postcardData }
          renderItem={ item => (
            <Skeleton avatar title={ false } loading={ initLoading } active>
              <List.Item
                onClick={ () => handleOpenModal( item )}>
                <Postcard
                  year={ item && item.acf.year }
                  month={ item && textTranslation(item.acf.month) }
                  imgSrc={ item.acf.pages && item.acf.pages[0].sizes.medium }
                />
              </List.Item>
            </Skeleton>
          )}
        />
      }
      { modalData && (
        <DigitizedBookModal
          ref={childRef}
          isVisible={ isModalOpen }
          closeModal={ handleCloseModal } 
          data={ modalData }
        />
      )}
    </>
  )
}

export default PostcardList
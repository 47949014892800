import React, { useState, useCallback, useEffect, Fragment } from "react"
import styles from "./BookOfMonth.module.css"
import { Carousel, Row, Col, Button, Skeleton } from "antd"
import chunk from "lodash.chunk"
import textTranslation from "../../../textTranslation"

import useFetch from "../../../hooks/useFetch"
import BookOfMonth from "./BookOfMonth"

import { Parser } from "html-to-react"

const monthsSerbian = [
	"Јануар",
	"Фебруар",
	"Март",
	"Април",
	"Мај",
	"Јун",
	"Јул",
	"Август",
	"Септембар",
	"Октобар",
	"Новембар",
	"Децембар"
]

export default function BooksOfMonth({ url }) {
	const htmlToReact = new Parser()

	const [ref, setRef] = useState( null )
	const [currentSelected, setCurrentSelected] = useState( 1 )
	const [booksOfThemonth, setbooksOfThemonth] = useState( [] )

	const { response, isLoading } = useFetch( url )

	const carousel = useCallback( ref => setRef( ref ), [])
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	function setActive( id ) { setCurrentSelected( id ) }

	function spanRange(id) {
		return currentSelected === id ? 9 : 3
	}

	useEffect(() => {
		const modifiedArray = response.map( e => e.acf )

		modifiedArray.sort(
			(a, b) => (b.year - a.year) || (monthsSerbian.indexOf(b.month) - monthsSerbian.indexOf(a.month))
		   );

		setbooksOfThemonth(modifiedArray)
	}, [response])

	return (
		<>
			<Skeleton active={ true } loading={ isLoading } />
				<Fragment>
					<Carousel ref={ carousel } dots={false} style={{ height: 450 }} >
			
						{booksOfThemonth.map((book, i) => {
							if (book.book !== false) {
								return (
									<Fragment key={i}>
							
										<Row gutter={12} 
										style={{ 
											display: "flex"}}
										>
											<Col span={ 12 }>
												<BookOfMonth
													id={ book.book.ID }
													title={ textTranslation(book.book.post_title) }
													author={ textTranslation(book.book.acf.author) }
													imgSrc={ book.book.acf.image ? book.book.acf.image.sizes.medium : '' }
													info={ textTranslation(book.book.acf.about) }
													bckDrop={ textTranslation(book.month.substring(0, 3)) }
													// setActive={ setActive }
													i={ i }
													active={ true }
													ganre={ textTranslation(book.book.acf.genre.post_title) }
												/>
											</Col>
										</Row>
							
									</Fragment>
								)
							}
						})}

					</Carousel>
				
					<div className={ styles.actions }>
						<span>
							<Button className={ styles.actionItem } onClick={ prev } shape="circle" icon="left" />
						</span>
						<span>
							<Button className={ styles.actionItem } onClick={ next } shape="circle" icon="right" />
						</span>
					</div>
				
				</Fragment>
		</>
	)
}

import React from 'react'
import { Typography } from 'antd'
import { Link } from '@reach/router'
import WithSider from '../../layouts/WithSider'
import AboutSidebar from '../AboutSidebar/AboutSidebar'
import style from "../../pages/LocationAndWorkTime/Location.module.css"
import styles from "./LocationCard.module.css"
import SingleLink from "../SingleLink/SingleLink"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { Parser } from "html-to-react"
import MetaDecorator from "../../metaDecorator"

import "./locationPage.css"

const { Title, Text } = Typography

const LocationPage = props => {
  const { t } = useTranslation("translation")
  const data = props.location.state.props
  const htmlParser = new Parser()
  const workingHours = data.acf.use_text_for_working_hours
  const everyDayHours = [
    data.acf.monday_hours_from && data.acf.monday_hours_until && `${ t('translation:locationPage:ponedeljak') }: ${data.acf.monday_hours_from} - ${data.acf.monday_hours_until}`,
    data.acf.tuesday_hours_from && data.acf.tuesday_hours_until && `${ t('translation:locationPage:utorak') }: ${data.acf.tuesday_hours_from} - ${data.acf.tuesday_hours_until}`,
    data.acf.wednesday_hours_from && data.acf.wednesday_hours_until && `${ t('translation:locationPage:sreda') }: ${data.acf.wednesday_hours_from} - ${data.acf.wednesday_hours_until}`,
    data.acf.thursday_hours_from && data.acf.thursday_hours_until && `${ t('translation:locationPage:cetvrtak') }: ${data.acf.thursday_hours_from} - ${data.acf.thursday_hours_until}`,
    data.acf.friday_hours_from && data.acf.friday_hours_until && `${ t('translation:locationPage:petak') }: ${data.acf.friday_hours_from} - ${data.acf.friday_hours_until}`
  ]

  return (

    <WithSider
      siderTitle={ t('translation:locationPage:o-nama') }
      sider={<AboutSidebar uri={ props }/>}
      content={
        <div >
          < MetaDecorator 
            title = { textTranslation(data.post_title) }
            image = { data.acf.image ? data.acf.image.sizes.medium_large : "" }
          />
          <Link 
            to={`/o-nama/mreza-ogranaka/gradski`} 
            onClick={event => {
              event.preventDefault()
              window.history.go(-1)
            }}
          >
            <span className="material-icons arrow-back">
              arrow_back_ios
            </span> 
            { t('translation:locationPage:nazad-na-ogranke') } </Link>
          <div className={ styles.title }>
            <Title style={{ marginBottom: "2rem" }} level={ 3 }>{ textTranslation(data.post_title) }</Title>
            <SingleLink
              style={{ marginRight: "0" }}
              className={ style.link }
              to='/lokacije'
              title={ t('translation:locationPage:vidi-mapu') }
            />
          </div>
          <div className={ style.panelContainer } style={{ padding: "0", width: "100%",  }}>
            <div className="container-info">
               </div>
            <div className={ styles.image }
                style={{ backgroundImage: `url(${data.acf.image ? data.acf.image.sizes.medium_large : ""})`, height: "30vh" }}>
            </div>
            <div className="location-info-card-container">
              <p className={ `${style.p} location-card-title ` }>
                { t('translation:locationPage:inforamcija') }
              </p>
              <div className={ `${style.p} informations` }>
                <p style={{ display: "flex", flexDirection: "column" }}>
                  <Text type="secondary" className="info-item">
                    { t('translation:singleLocation:adresa') }
                  </Text>
                  <Text strong className={ `${style.accordionRegularText} item-info` }>
                    { textTranslation(data.acf.addreess) }
                  </Text>{ " " } 
                </p>
                <p 
                className={ `${style.p} info-item-container` }>
                  <Text type="secondary" className="info-item">
                    { t('translation:singleLocation:telefon') }
                  </Text>
                  <Text strong className={ `${style.accordionRegularText} item-info` }>
                    { data.acf.phone }
                  </Text>{" "}
                </p>
              <div
                 className={ `${style.workTime} info-item-container` }>
                  <p className={ `${style.p} ${style.workTimeLabel}` }>
                    <Text type="secondary" className= "item-info" style={{ marginRight: "5px", textTransform: "uppercase" }}>
                      { t('translation:singleLocation:radno-vreme') }
                    </Text>
                  </p>
                  <div>
                    <div className={ style.workTimeDay }>
                      {/* <p className={ style.p } style={{ marginRight: "5px", textAlign: "right" }}> */}
                        
                        <Text strong className= "item-info">
                       { workingHours === true 
                        ? htmlParser.parse(textTranslation(data.acf.working_hours_text))
                        : everyDayHours.map((e, i) =>(
                          <Text key={i}>
                          {e} <br/>
                          </Text>  
                        )) } 
                        </Text>
                      {/* </p> */}
                    </div>
                    <div className={ style.workTimeDay }>
                      <p className={ style.p }>
                        <Text strong className= "item-info">
                          {data.acf.saturday_hours_from && data.acf.saturday_hours_until && `${ t('translation:locationPage:subota') }: ${data.acf.saturday_hours_from} - ${data.acf.saturday_hours_until}`}
                        </Text>
                      </p>
                    </div>
                  </div>
                </div> 
            </div>
          </div> 
        </div> 
        {data.acf.description && <div>
          <p 
          style={{ marginTop:"2rem", marginBottom: "0.5rem" }}
          className={ `${style.p} location-card-title ` }>
              { t('translation:locationPage:o-ogranku') }
            </p>
            { htmlParser.parse(textTranslation(data.acf.description)) }
        </div>}
      </div>
    }
  />
  )
}

export default LocationPage
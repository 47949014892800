import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import AppDesktop from "./AppDesktop"
import AppMobile from "./AppMobile"
import useWindowSize from "./hooks/useWindowSize"
import "./hooks/useAxios"
import './i18n'

function App() {
  const size = useWindowSize()
  return size.width > 991 ? <Suspense fallback=""><AppDesktop /></Suspense> : <Suspense fallback=""><AppMobile />
    </Suspense>
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from "react"
import styles from "./BookOfMonth.module.css"
import { Typography } from "antd"
import { navigate } from "@reach/router"
import textTranslation from "./../../../textTranslation"
import { Parser } from 'html-to-react'
const { Paragraph, Title } = Typography

export default function MobileBookOfMonth({
	id,
	title,
	author,
	imgSrc,
	info,
	bckDrop,
	ganre
}) {
	const htmlToReact = new Parser()
	function shortDesc() {
		if ( info !== undefined ) {
			// const description = info.map(( t, i ) => i % 2 === 0 ? t.props.children : "" )
			// return description.join("").substring(0, 200) + "..."
			return (htmlToReact.parse(textTranslation(info)))
		}
		return "./"
	}
	return (
		<div 
			className={ styles.mobileItem }
			style={{ height: document.body.offsetWidth <= 576 ? "560px" : "350px" }}
			onClick={() => navigate(`/knjiga/${id}`)}
			>

			<div>
				<img
					className={ styles.imageContainer }
					src={ imgSrc }
					style={{ height: 270, width: 180 }}
					alt="book-cover"
				/>
			</div>


			<div className={ styles.info }>

				<Title className={ styles.indexMobile } >
					{ bckDrop }
				</Title>

				<p className={ styles.ganre }>
					{ ganre.post_title ? ganre.post_title.toUpperCase() : "./" }
				</p>

				<div>
					<Title level={2} className={ styles.title } >
						{ title }
					</Title>
					<div className={ styles.author }>
						{ author }
					</div>
					<Paragraph className={ styles.text }>
						{ shortDesc( info ) }
					</Paragraph>
				</div>

			</div>

		</div>
	)
}

import React, { useState } from "react";
import { Menu } from "antd";
import useFetch from "../../hooks/useFetch";
import { Link } from "@reach/router";
import "./UserGuideSidebar.module.css"

import textTranslation from '../../textTranslation'

const { ItemGroup, Item } = Menu;

export default function UserGuidenceSidebar(props) {
	const { uri, category, selectedTitleCb } = props;
	const initialCurrent = category !== undefined ? category : uri.split("/")[2];
	const [current, setCurrent] = useState(`/${initialCurrent}`);
	const { response } = useFetch("/wp-json/wp-api-menus/v2/menus/27");

	const setCurrentMenuTitle = (key) => {
		if (!response.items) {
			return;
		}
		const menuItems = [].concat.apply([], response.items.map(i => i.children));
		const currentMenuItem = menuItems.find(item => `/${item.object_slug}` === key);
		if (!currentMenuItem || !selectedTitleCb) {
			return;
		}
		selectedTitleCb(currentMenuItem.title);
	}

	setCurrentMenuTitle(current);

	const handleClick = (e) => {
		props.handleChange && props.handleChange()
		setCurrent(e.key);
		setCurrentMenuTitle(e.key)
	};

	const handleclickOnBisis = () => {
		window.open("https://opac.bisis.rs/lib/gbns", "_blank")
	}
	
	const renderMenu = () => {
		if (response.items && response.items.length) {
			return response.items.map((group) => (
				<ItemGroup key={group.title} title={textTranslation(group.title)}>
					{group.children.map(item => {
						if(item.url !=="/bisis-katalog"){
							return (
								<Item key={`${item.url}`}>
									<Link to={`/${uri.split("/")[1]}${item.url}`}>
										{textTranslation(item.title)}
									</Link>
								</Item>
							)
						} else {
							return (
								<Item key={`${item.url}`}>
									<span onClick={handleclickOnBisis}  style={{ color: "rgba(0, 0, 0, 0.65)" }}>
										{textTranslation(item.title)}
									</span>
								</Item>
								
							)
						}
						})}
				</ItemGroup>
			));
		}
	};

	return (
		<Menu
			style={{ width: 256 }}
			// defaultSelectedKeys={[current]}
			defaultSelectedKeys={[`/${props.uri.split("/")[2]}`]}
			onClick={handleClick}
			loading="true"
			className="userGuide"
			mode="inline">
			{renderMenu()}
		</Menu>
	);
}

import React from "react"
import { Typography } from 'antd'
import WithSider from "../../layouts/WithSider"
import { useTranslation } from 'react-i18next'
import DigitizationSidebar from "../DigitizadionSidebar/DigitizationSidebar"

const { Title } = Typography

function WorkNotFinished() {
    const { t } = useTranslation("translation")

    function renderContent() {
      return( 
        <div style={{ padding: '200px', textAlign: 'center' }}> 
          <div style={{  marginTop: '2rem' }}>
            <Title level={ 3 }>
                { t('translation:workInProgress.radovi-u-toku') }<span role="img" aria-label="worker">👷</span>
            </Title>
          </div>
        </div>
      )
    }
    
    return (
      <WithSider
      siderTitle={t('translation:mainNav:digitalizacija')}
      sider={<DigitizationSidebar />}
      content={renderContent()}
        
        />   
    )
}

export default WorkNotFinished
import React, { useState, useEffect } from "react"
import { Link } from "@reach/router"
import { Menu, Skeleton, Typography } from "antd"

import "../../components/AboutSidebar/AboutSidebar.module.css"

const { Item, ItemGroup } = Menu
const { Paragraph } = Typography

const AboutSidebar = (props) => {
  const [sidebarData, setSidebarData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    setIsLoading(true)
    fetch(`${apiUrl}/wp-json/wp-api-menus/v2/menus/12?lang=${localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr' ? '' : localStorage.getItem("lang")}`)
      .then(response => response.json())
      .then(data => {
        setSidebarData(data)
        setIsLoading(false)
      })
  }, [])


  return (
    <Skeleton title={false} paragraph={{ rows: 15 }} loading={isLoading} active>
      <Menu
        style={{ width: 256 }}
        defaultSelectedKeys={[`${props.uri.uri}`]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        className="digitizationMenu">
        {sidebarData &&
          sidebarData.items.map(el => {
            if (el.children) {
              return (
                <ItemGroup
                  key={el.id}
                  title={el.title}
                  className="itemGroupTitle">
                  {el.children &&
                    el.children.map(child => {
                      return (
                        <Item key={`/o-nama${el.url}${child.url}`} >
                          <Link to={`/o-nama${el.url}${child.url}`} >
                            <Paragraph ellipsis={{ row: 1 }}>
                              {child.title}
                            </Paragraph>
                          </Link>
                        </Item>
                      )
                    })}
                </ItemGroup>
              )
            } else {
              return (
                <Item key={`/o-nama${el.url}`} className="menuItemNotIndented">
                  <Link to={`/o-nama${el.url}`}>
                    <Paragraph ellipsis={{ row: 1 }}
                      style={{color: props.uri.uri && props.uri.uri === `/o-nama${el.url}` ? "rgb(0,0,0)" : "rgba(0,0,0,0.65)"}}  
                    >
                      {el.title}
                    </Paragraph>
                  </Link>
                </Item>
              )
            }
          })}
      </Menu>
    </Skeleton>
  )
}

export default AboutSidebar

import React, { useState, useEffect, useCallback } from "react"
import { Row, Col, Typography, Select, Collapse } from "antd"
import { ExpandIcon } from "../CustomIcon/CustomIcon"
import WithSider from "../../layouts/WithSider"
import AboutSidebar from "../AboutSidebar/AboutSidebar"
import PopularActivities from "../PopularActivities/PopularActivities"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import styles from "./PublicProcurement.module.css"
import MetaDecorator from "../../metaDecorator"

const { Text, Title } = Typography
const { Option } = Select
const { Panel } = Collapse

const PublicProcurement = props => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [years, setYears] = useState([])
  const [data, setData] = useState({})
	const { t } = useTranslation("translation")
  const apiUrl = process.env.REACT_APP_API_URL

  const getProcurements = useCallback(() => {
    const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
    ? `${apiUrl}/wp-json/gbns/v2/all_public_procurements_years?lang=` 
    : `${apiUrl}/wp-json/gbns/v2/all_public_procurements_years?lang=${localStorage.getItem('lang')}`
    return fetch(createUrl())
      .then(response => response.json())
      .then(data => setYears(data))
  }, [apiUrl])

  useEffect(() => {
    getProcurements()
  }, [getProcurements])

  const getFilteredProcurements = useCallback((selectedYear) => {
    const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
    ? `${apiUrl}/wp-json/gbns/v2/all_public_procurements?year=${selectedYear}&lang=` 
    : `${apiUrl}/wp-json/gbns/v2/all_public_procurements?year=${selectedYear}&lang=${localStorage.getItem('lang')}`
    // return fetch(`${apiUrl}/wp-json/gbns/v2/all_public_procurements?year=${selectedYear}&lang=${localStorage.getItem('lang')}`)
    return fetch(createUrl())
    .then(response => response.json())
    .then(resp => {
      setData({ ...data, [ selectedYear ] : resp })
    })
  }, [apiUrl])

  useEffect(() => {
    if (!data[selectedYear]) {
      getFilteredProcurements(selectedYear)
    }
  }, [getFilteredProcurements, selectedYear])

  return (
    <WithSider
      siderTitle={t('translation:locationGroupList:o-nama')}
      sider={ <AboutSidebar uri={ props }/> }
      content={
        <Row>
          <MetaDecorator 
            url = { window.location.href }
            title = { textTranslation("Јавне набавке")}
          />
          <Col>
            <Text className={ styles.text }>
            {t('translation:publicProcurement:spisak-sa-linkovima-ka-tekstu-konkretnog-dokumenta-javne-nabavke')}
            </Text>
            <div className="titleWrapper">
              <Title level={ 3 }>{ t('translation:publicProcurement:javne-nabavke-za-godinu') }</Title>
              <Select
                className="yearSelect"
                defaultValue={ selectedYear }
                onChange={( value, option ) => {
                  setSelectedYear(value)
                }}>
                { years.map( year => (
                  <Option key={ year } value={ year }>
                    { year }
                  </Option>
                )) }
              </Select>
            </div>
            <div className="accordionWrapper">
              <Collapse
                expandIconPosition="right"
                bordered={ false }
                defaultActiveKey={ ["0"] }
                expandIcon={({ isActive }) => (
                  <div className={ styles.iconWrapper }>
                    <ExpandIcon isActive={ isActive }/>
                  </div>
                )}>
                  { data[selectedYear] && Object.entries(data[selectedYear]).map((el, index) => {
                   
                    return (
                      <Panel
                        key={ el[0] }
                        header={
                          <Text className={ styles.header } strong>
                            { el[1].title }
                          </Text>
                        }
                        className={ `${styles.accordionItem} procurementItem` }>
                        <div className={ styles.procurementItemContent }>
                          { el[1].documents && el[1].documents.map(doc => <a key={ doc.document.ID } href={ doc.document.url } target="_blank">{ textTranslation(doc.title) }</a>)}
                        </div>
                      </Panel>
                    )
                  })}
              </Collapse>
            </div>
          </Col>
        </Row>
      }
      moreContent={ <PopularActivities mobile={ props.mobile }/> }
    />
  )
}

export default PublicProcurement

import React from "react"
import { Helmet } from "react-helmet"
import textTranslation from "./textTranslation"
import logo from '../src/assets/images/logo.svg'

const MetaDecorator = ({ description,title, image,url }) => {
    const defaultMetaDescription = textTranslation("Веб презентација Градске библиотеке у Новом Саду. Организација, услуге, контакти, претрага електронског каталога.")
    const defaultMetaImage = logo
    const defaultMetaTitle = textTranslation("Градска библиотека у Новом Саду");
    
    return (
        <Helmet>
            <title>{ title ? `${ defaultMetaTitle } - ${ title }` : defaultMetaTitle }</title>
            <meta name="description" content={ description ? description : defaultMetaDescription } />
            {/* Facebook */}
            <meta property="og:url" content={ url ? url : window.location.href } />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={ title ? `${ defaultMetaTitle } - ${ title }` : defaultMetaTitle } />
            <meta property="og:description" content= { description ? description : defaultMetaDescription } />
            <meta property="og:image" content= { image ? image : defaultMetaImage } />
        </Helmet>
    )
}

export default MetaDecorator
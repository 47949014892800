import React from "react"
import styles from "./NewBooks.module.css"
import { Typography } from "antd"
import { navigate } from "@reach/router"
import textTranslation from "./../../../textTranslation"
import { Parser } from 'html-to-react'

const { Paragraph, Title } = Typography



export default ({
	id,
	title,
	imgSrc,
	author,
	info,
	bckDrop,
	ganre,
	active,
	i,
	setActive
}) => {
	const htmlToReact = new Parser()
	function shortDesc() {
		if (info !== undefined) {
			// const description = info.map(( t, i ) => i % 2 === 0 ? t.props.children : "" )
			// return description.join("").substring(0, 150) + "..."
			return (htmlToReact.parse(textTranslation(info)))
		}
		return "./"
	}

	bckDrop < 10 ? ( bckDrop = `0${bckDrop}` ) : ( bckDrop = `${bckDrop}` )

	return (
		<>
		{active ? (
			<div 
				className={ styles.bookItemActive }
				onClick={ () => navigate(`/knjiga/${id}`) }
			>


				<div>
					<img
						src={`${imgSrc}`}
						className={ styles.imageContainer }
						style={{
							height: "284px",
							width: "185px"
						}}
						alt="book-cover"
					/>
				</div>


				<div className={ styles.details }>
				
					<Title level={1} className={ styles.indexActive }>
						{ bckDrop }
					</Title>
				
					<span className={ styles.ganre }>
						{ ganre.post_title ? textTranslation(ganre.post_title.toUpperCase()) : "./" }
					</span>
				
					<Title level={3} 
						className={ styles.title }
						ellipsis={{ rows: 2 }}
						style={{
							wordBreak: "break-word",
							color: "#071013",
							fontFamily: "Tinos",
							height: "80px",
							fontSize: "30px",
							margin: "0"
						}} 
					>
						{ textTranslation(title) }
					</Title>
				
					<div className={ styles.author }>
						{ textTranslation(author) }
					</div>
				
					<Paragraph 
						className={ styles.text }
						// ellipsis={{ rows: 5 }}
						style={{ opacity: "0.75"}}
					>
						{ shortDesc( info ) }
					</Paragraph>
				
				</div>


			</div>

		) : (
				
			<div 
				className={ styles.bookItemInactive } 
				onClick={ () => setActive(i) }
				style={{ marginTop: "24px" }}	
			>

				<img
					src={ imgSrc }
					className={ styles.imageContainer }
					style={{
						height: "220px",
						width: "140px"
					}}
					alt="book-cover"
				/>

				<div className={ styles.info }>
					<Title 
						level={1} 
						className={ styles.indexInactive }
					>
						{ bckDrop }
					</Title>

					<Title 
						level={3} 
						className={ styles.title }
						ellipsis={{ rows: 2 }}
						style={{
							wordBreak: "break-word",
							color: "#071013",
							fontFamily: "Tinos",
							height: "60px",
							fontSize: "20px",
							margin: "0"
						}} 
					>
						{ textTranslation(title) }
					</Title>

					<Paragraph 
						className={ styles.author }
						ellipsis={{ rows: 2}}	
						style={{ marginTop: 0 }}
					>
						{ textTranslation(author) }
					</Paragraph>
				
				</div>
			
			</div>
		)}
	</>
	)
}

import React, { useRef, useState, useCallback } from "react"
import { Modal, Carousel, Row, Col, Button } from "antd"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { ZoomInOutlined, ZoomOutOutlined, FullscreenOutlined } from '@ant-design/icons'

import styles from "./DigitizedMagazineModal.module.css"
import MetaDecorator from "../../metaDecorator"

function DigitizedMagazineModal({ isVisible, closeModal, data }) {
  const { t } = useTranslation("translation")
  const [currentPage, setCurrentPage] = useState( 0 )
  const [carouselRef, setCarouselRef] = useState( null )
  const carousel = useCallback( ref => { setCarouselRef( ref ) }, [] )
  const previewCarousel = useRef()

  function next() { carouselRef.slick.slickNext() }
  function prev() { carouselRef.slick.slickPrev() }

  const mainCarouselSettings = {
    className: "mainCarousel",
    dots: false,
    arrows: false,
    draggable: false,
    ref: carousel,
    afterChange: current => setCurrentPage( current ),
    asNavFor: previewCarousel.current,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  const previewCarouselSettings = {
    className: "previewCarousel",
    slidesToShow: 3,
    infinite: data.acf.pages.length > 3,
    slidesToScroll: 1,
    dots: false,
    draggable: true,
    centerMode: true,
    centerPadding: "10px",
    ref: previewCarousel,
    focusOnSelect: true,
    arrows: false,
    speed: 500,
    asNavFor: carouselRef
  }

  let ResetZoomButton;
  
  const handleZoomClick = function(){
    if(!ResetZoomButton.click()) {
      setTimeout( () => {
        closeModal()
      },200)
    }
  }

  return (
    <Modal
      className="digitizedModal"
      title={ null }
      visible={ isVisible }
      onCancel={ handleZoomClick }
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={ null }
      bodyStyle={{ padding: 0 }}
      closeIcon={ <div className={ styles.customCloseIcon }></div> }
      width="100%"
      mask={ false }
    >
      <MetaDecorator 
        title = { textTranslation(data.title.rendered) }
        description = {textTranslation(data.acf.description)}
        image = { data.acf.pages[0].url }
      />
      <span className={`${styles.currentPage} ${styles.mobile}`}>
        {`${currentPage + 1}/${data.acf.pages.length}`}
      </span>

      <Row className={ styles.carouselRow }>
        <Col lg={{ span: 14, offset: 2 }}>
          <div className="carousel-wrapper">
            <Carousel { ...mainCarouselSettings }>
              {data.acf.pages.map( page => {
                return (
                  <div className={ `${styles.singlePage}` } key={ page.id }>
                    <TransformWrapper
                      defaultScale={ 1 }
                      defaultPositionX={ 200 }
                      defaultPositionY={ 100 }
                    >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <div className={ styles.tools }>
                          <button className={ styles.zoomBtn } onClick={ zoomIn }><ZoomInOutlined /></button>
                          <button className={ styles.zoomBtn } onClick={ zoomOut }><ZoomOutOutlined /></button>
                          <button className={ styles.zoomBtn } onClick={ resetTransform }><FullscreenOutlined /></button>
                        </div>
                        <button ref={button => ResetZoomButton = button} onClick={ () => { resetTransform() } } style={{display: "none"}}>
                            <i className="material-icons" >
                              close
                            </i>
                          </button>
                        <TransformComponent>
                          <img src={ page.url } alt="test" />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                    </TransformWrapper>
                  </div>
                )
              })}
            </Carousel>
            <Button
              className={ styles.carouselControll }
              onClick={ prev }
              shape="circle"
              icon="left"
            ></Button>
            <Button
              className={ styles.carouselControll }
              onClick={ next }
              shape="circle"
              icon="right"
            ></Button>
          </div>
        </Col>
        <Col lg={{ span: 5, offset: 1 }}>
          <div className={ styles.previewWrapper }>
            <Carousel { ...previewCarouselSettings }>
              {data.acf.pages.map( page => {
                return (
                  <div key={ page.id }>
                    <img src={ page.url } alt="" />
                  </div>
                )
              })}
            </Carousel>
            <span className={styles.currentPage}>
              {`${currentPage + 1}/${data.acf.pages.length}`}
            </span>
          </div>
          <h2 className={ styles.bookTitle }>
            { textTranslation(data.post_title) }
          </h2>
          <div className={ styles.details }>
            <div className={ styles.detailsItem }>
              <p>{t('translation:digitizedMagazineModal:godina')}</p> <span>{ data.acf.year }</span>
            </div>
            <div className={ styles.detailsItem }>
              <p>{t('translation:digitizedMagazineModal:mesec')}</p> <span>{ textTranslation(data.acf.month) }</span>
            </div>
            <div className={ styles.detailsItem }>
              <p>{t('translation:digitizedMagazineModal:broj')}</p> <span>{ data.acf.number }</span>
            </div>
          </div>
          <div className={ styles.description }>
            <p>{ textTranslation(data.acf.description) }</p>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default DigitizedMagazineModal
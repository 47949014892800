import React from "react"
import { Collapse, Typography } from "antd"
import { ExpandIcon } from "../../components/CustomIcon/CustomIcon"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { Parser } from "html-to-react"

import style from "./Location.module.css"

const { Text } = Typography
const { Panel } = Collapse

const SingleLocation = ({ locations }) => {
  const htmlToReact = new Parser()
  const { t } = useTranslation("translation")

  return (
    <Collapse
      expandIconPosition="right"
      bordered={ false }
      defaultActiveKey={ ["0"] }
      expandIcon={({ isActive }) => (
        <div className={ style.iconWrapper }>
          <Text type="secondary" className={ style.iconText }>
            { isActive ? t('translation:singleLocation:manje-detalja') : t('translation:singleLocation:vise-detalja') }
          </Text>
          <ExpandIcon isActive={ isActive } />
        </div>
      )}>
      { locations.length
        ? locations.map((item, index) => (
            <Panel
              header={
                <Text className={ style.header } strong>{ textTranslation(`${index + 1}. ${item.post_title}`)} {!!item.acf.city && <>({ textTranslation(item.acf.city) })</>}</Text>
              }
              className={ `${style.accordionItem} locationItem` }
              key={ index }>
              <div className={ style.panelContainer }>
                <div className={ style.locationInfo }>
                  <p className={ style.p }>
                    <Text type="secondary" style={{ marginRight: "10px" }}>
                    { t('translation:singleLocation:adresa') }
                    </Text>
                    <Text strong className={ style.accordionRegularText }>
                      { textTranslation(item.acf.addreess) }
                    </Text>{ " " }
                  </p>
                  <p className={ style.p }>
                    <Text type="secondary" style={{ marginRight: "10px" }}>
                    { t('translation:singleLocation:telefon') }
                    </Text>
                    <Text strong className={ style.accordionRegularText }>
                      { item.acf.phone }
                    </Text>{ " " }
                  </p>
                </div>
                <div className={ style.workTime }>
                  <p className={ `${style.p} ${style.workTimeLabel}` }>
                    <Text type="secondary" style={{ marginRight: "5px" }}>
                    { t('translation:singleLocation:radno-vreme') }
                    </Text>
                  </p>
                  { item.acf.use_text_for_working_hours ? 
                    <div className={ style.workTimeDay }>
                      { htmlToReact.parse(textTranslation(item.acf.working_hours_text)) }
                    </div> 
                    : 
                    <div style={{ marginLeft: "5px" }}>
                      {item.acf.monday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p }>
                          <Text strong>{ t('translation:singleLocation:ponedeljak') }</Text>
                        </p>
                        <p className={ style.p }>
                          <Text strong>: { `${item.acf.monday_hours_from} - ${item.acf.monday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.tuesday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p }>
                          <Text strong>{ t('translation:singleLocation:utorak') }</Text>
                        </p>
                        <p className={ style.p }>
                          <Text strong>: { `${item.acf.tuesday_hours_from} - ${item.acf.tuesday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.wednesday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p }>
                          <Text strong>{ t('translation:singleLocation:sreda') }</Text>
                        </p>
                        <p className={ style.p }>
                          <Text strong>: { `${item.acf.wednesday_hours_from} - ${item.acf.wednesday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.thursday_hours_from &&<div className={ style.workTimeDay }>
                        <p className={ style.p }>
                          <Text strong>{ t('translation:singleLocation:cetvrtak') }</Text>
                        </p>
                        <p className={ style.p }>
                          <Text strong>: { `${item.acf.thursday_hours_from} - ${item.acf.thursday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.friday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p }>
                          <Text strong>{ t('translation:singleLocation:petak') }</Text>
                        </p>
                        <p className={ style.p }>
                          <Text strong>: { `${item.acf.friday_hours_from} - ${item.acf.friday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.saturday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p }>
                          <Text strong>{ t('translation:locationPage:subota') }</Text>
                        </p>
                        <p className={ style.p }>
                          <Text strong>: { `${item.acf.saturday_hours_from} - ${item.acf.saturday_hours_until}` }</Text>
                        </p>
                      </div>}
                    </div>
                  }
                </div>
              </div>
            </Panel>
          )) : null
          }
    </Collapse>
  )
}

export default SingleLocation
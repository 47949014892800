import convert from "cyrillic-to-latin"

const textTranslation = textForTranslate => {

    let chosenLang = localStorage.getItem('lang')

    if(typeof textForTranslate  !== 'undefined'){
        if( chosenLang !== '' ) {
            const latText = convert(textForTranslate)
            return latText
        }
        else {
            return textForTranslate
        }
    }
}

export default textTranslation
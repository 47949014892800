import React, { useState, useEffect } from "react"
import { Row, Col, Table, Spin, Empty, Modal, Icon, Button } from "antd"
import queryString from "query-string"
import axios from "axios"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { Parser } from 'html-to-react'

import styles from './SearchResults.module.css'
import MetaDecorator from "../../metaDecorator"

const SearchResults = () => {
  const [searchResults, setSearchResults] = useState( [] )
  const [loading, setLoading] = useState( false )
  const [numberOfElements, setNumberOfElements] = useState( null )
	const { t } = useTranslation("translation")
  let chosenDepartment = null
  const searchedTerm = queryString.parse(window.location.search)
  const htmlToReact = new Parser()
 
  if (searchedTerm.departments) {
    chosenDepartment = searchedTerm.departments
    // delete searchedTerm.departments
  }
 

  let searchModel = {}
  let entries = Object.entries(searchedTerm)

  entries.forEach(entry => {
    let objectKey = entry[0]
    let objectValue = entry[1]

    searchModel[objectKey] = objectValue
  })

 

  const searchQuery = JSON.stringify(searchModel)

  const performSearch = (searchModel, pageNumber = 0) => {
    if (chosenDepartment) {
      searchModel.departments = [chosenDepartment]
    }
    
    axios
      .post(
        "https://app.bisis.rs/bisisWS/opac/search",
        {
          searchModel
        },
        {
          headers: {
            "Content-Type": "application/json",
            Library: "gbns"
          },
          params: {
            pageNumber: pageNumber > 0 ? pageNumber - 1 : pageNumber,
            pageSize: 10
          } 
        }
      )
      .then(res => {
        let results = res.data.content
        setSearchResults(results)
        setLoading(false)
        setNumberOfElements(res.data.totalElements)
        return res
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        Modal.error({
          title:
          t('translation:gallerySearch:doslo-je-do-greske-molimo-osvezite-stranicu-i-pokusajte-ponovo'),            
          onOk() {}
        })
      })
  }

  useEffect(() => {
    setLoading(true)
    performSearch(searchModel)
  }, [searchQuery])

  const columns = [
    {
      title: t('translation:searchResults:autor'),
      dataIndex: "autor",
      key: "autor",
      width: "25%",
      render: text => <span>{ text }</span>
    },
    {
      title: t('translation:searchResults:naslov'),
      dataIndex: textTranslation('naslov'),
      key: "naslov",
      width: "25%"
    },
    {
      title: t('translation:searchResults:izdavac'),
      dataIndex: "izdavac",
      key: "izdavac",
      width: "25%"
    },
    {
      title: t('translation:searchResults:godina-izdavanja'),
      dataIndex: "godinaIzdavanja",
      key: "godinaIzdavanja",
      width: "25%"
    }
  ]

  let data = []

  if (searchResults && searchResults.length) {
    searchResults.map( (result, index) => {
      let obj = {
        key: index,
        autor: textTranslation(htmlToReact.parse(result.authors)),
        naslov: textTranslation(result.title),
        slika: result.imageUrl,
        izdavac: textTranslation(result.publisher),
        godinaIzdavanja: result.publishYear,
        mestoIzdavanja: textTranslation(result.publishPlace),
        opis: textTranslation(htmlToReact.parse(result.description)),
        udk: textTranslation(result.udk),
        brojStrana: textTranslation(result.pagesCount),
        dimenzije: result.dimensions,
        napomene: textTranslation(result.notes)
      }
      data.push(obj)
      return data
    })
  }
  const locale = {
    emptyText: (
      <Empty
        image={ Empty.PRESENTED_IMAGE_SIMPLE }
        className={ styles.searchEmpty }
        imageStyle={{
          height: 60
        }}
        description={ <span>{ t('translation:searchResults:nema-rezultata-pretrage') }</span> }></Empty>
    )
  }

  const loadingInProgress = loading ? styles.loadingInProgress : ""

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <span onClick={ event => event.preventDefault() }>
          { t('translation:searchResults:prethodna-strana') }
        </span>
      )
    }
    if (type === "next") {
      return (
        <span onClick={ event => event.preventDefault() }>
          {t('translation:searchResults:sledeca-strana')}
        </span>
      )
    }
    return originalElement
  }

  const onPaginationChange = (page, pageSize) => {
    performSearch(searchModel, page)
  }

  const customExpandedIcon = props => {
    
    if (props.expanded) {
      return (
        <Button
          style={{ color: "black", display: "none" }}
          onClick={ e => {
            props.onExpand(props.record, e)
          }}>
          <Icon type="minus" />
        </Button>
      )
    } else {
      return (
        <Button
          style={{ color: "black", display: "none" }}
          onClick={ e => {
            props.onExpand(props.record, e)
          }}>
          <Icon type="plus" />
        </Button>
      )
    }
  }

  return (
    <div className={ `${styles.container} ${loadingInProgress}` }>
      <MetaDecorator
        title = { textTranslation("Каталог") }
      />
      <a href="https://opac.bisis.rs/search/advanced-search" target="_blank" rel="noopener noreferrer">
        <Button type="primary" size="small">
        { t('translation:searchResults:napredna-pretraga-BISIS') } 
        </Button>
      </a>
      { loading ? (
        <Spin />
      ) : (
        <Table
          columns={ columns }
          dataSource={  data }
          locale={ locale }
          image={ Empty.PRESENTED_IMAGE_SIMPLE }
          className={ styles.resultsTable }
          expandedRowRender={ record => {
            return (
              <div>
                { record.slika && (
                  <Row style={{ padding: "10px" }}>
                    <Col offset={ 6 } style={{ textAlign: "left" }}>
                      <div className={ styles.imageWrapper }>
                        <img
                          src={ record.slika }
                          alt={`${record.naslov} - ${record.autor}`}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {record.naslov && <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:naslov') } </strong>
                  </Col>
                  <Col span={ 18 }>
                    <p>{ record.naslov }</p>
                  </Col>
                </Row>}
                {record.author && <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:autor') }</strong>
                  </Col>
                  <Col span={ 18 }>
                    <p>{ record.autor }</p>
                  </Col>
                </Row>}
                {record.opis && (
                  <Row style={{ padding: "10px" }}>
                    <Col span={ 6 }>
                      <strong>{ t('translation:searchResults:opis') } </strong>
                    </Col>
                    <Col span={ 18 }>
                      <p>{ record.opis }</p>
                    </Col>
                  </Row>
                )}
                {record.godinaIzdavanja && <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:godina-izdavanja') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.godinaIzdavanja }</Col>
                </Row>}
                {record.izdavac && <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:izdavac') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.izdavac }</Col>
                </Row>}
                {record.mestoIzdavanja && <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:mesto-izdavanja') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.mestoIzdavanja }</Col>
                </Row>}
                {record.udk && <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:udk') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.udk }</Col>
                </Row>}
                {record.brojStrana && <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:broj-strana') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.brojStrana }</Col>
                </Row>}
                {record.dimenzije &&<Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:dimenzije') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.dimenzije }</Col>
                </Row>}
                {record.napomene && (
                  <Row style={{ padding: "10px" }}>
                    <Col span={ 6 }>
                      <strong>{ t('translation:searchResults:napomene') }</strong>
                    </Col>
                    <Col span={ 18 }>{ record.napomene }</Col>
                  </Row>
                )}
              </div>
            )
          }}
          expandIcon={ customExpandedIcon }
          expandRowByClick={ true }
          pagination={{
            defaultPageSize: 10,
            total: numberOfElements,
            onChange: onPaginationChange,
            itemRender: itemRender
          }}
        />
      )}
    </div>
  )
}

export default SearchResults
import React from "react"
import { Parser } from "html-to-react"
import { Typography } from "antd"
import textTranslation from '../../../textTranslation'

import styles from "./ActivityCard.module.css"

const { Paragraph } = Typography

function ActivityCard({
  title,
  description,
  imageSrc,
  type,
  onClick,
  isActive
}) {
  const htmlParser = new Parser()

  return (
    <div onClick={onClick} className={`${styles.card} ${isActive ? "active" : "inactive"}`}>

      <div className={styles.image} style={{ backgroundImage: `url(${imageSrc})` }}/>

      <div className={`${styles.text} popularActivityContent`}>
        <span className={styles.category}>{ textTranslation(htmlParser.parse(type)) }</span>

        <Paragraph ellipsis={{ rows: 2 }} className={ styles.title }>
          { textTranslation(htmlParser.parse(title)) }
        </Paragraph>

        <Paragraph ellipsis={{ rows: 2 }} className={ styles.description }>
          { textTranslation(htmlParser.parse(description)) }
        </Paragraph>
      </div>
    </div>
  )
}

export default ActivityCard

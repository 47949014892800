import axios from "axios"

axios.interceptors.request.use(config => {
    config.params = {
        ...config.params, 
        lang: localStorage.getItem("lang")
    }
    return config
})


import React, { useState, Fragment } from "react"
import styles from "./AdditionalNav.module.css"
import { useTranslation } from 'react-i18next'
import { Link } from "@reach/router"
import { Menu, Select, Dropdown } from "antd"

//components
import ContactDropdown from "../ContactDropdown/ContactDropdown"
import AboutDropdown from "../AboutDropdown/AboutDropdown"



const AdditionalNav = props => {
  const { t, i18n } = useTranslation("translation")
  const { location } = props
  const { Option } = Select
  const splitPath = location.pathname.split("/")
  const language = localStorage.getItem("lang")

  const [contactDropdownVisibility, setContactDropdownVisibility] = useState( false )
  const [aboutUsDropdownVisibility, setAboutUsDropdownVisibility] = useState( false )

  const handleContactVisibilityChange = flag => { setContactDropdownVisibility( flag ) }
  const handleAboutUsVisibilityChange = flag => { setAboutUsDropdownVisibility( flag ) }

  const changeLanguage = code => {
    i18n.changeLanguage( code )
    localStorage.setItem( "lang", code )
    window.location.reload()
  }

  return (
    <Fragment>
      <Menu selectedKeys={ splitPath[1] } mode="horizontal" style={{ fontSize: "12px" }} >

        <Menu.Item key="lokacije">
          <Link to="lokacije">{t('translation:additionalNav:lokacije')}</Link>
          <span className={ styles.separator } />
        </Menu.Item>

        <Menu.Item key="kontakt">
          <Dropdown
            overlay={ <ContactDropdown toggleDropdown={ setContactDropdownVisibility } /> }
            overlayStyle={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 15px 5px 2px" }}
            placement="bottomCenter"
            visible={ contactDropdownVisibility }
            onVisibleChange={ handleContactVisibilityChange }
          >
            <Link to="kontakt/bibliotekar">{t('translation:additionalNav:kontakt')}</Link>
          </Dropdown>
          <span className={ styles.separator } />
        </Menu.Item>

        <Menu.Item key="o-nama">
          <Dropdown
            overlay={ <AboutDropdown toggleDropdown={setAboutUsDropdownVisibility} /> }
            overlayStyle={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 15px 5px 2px" }}
            placement="bottomCenter"
            visible={ aboutUsDropdownVisibility }
            onVisibleChange={ handleAboutUsVisibilityChange }
          >
            <Link to={"o-nama"}>{t('translation:additionalNav:O nama')}</Link>
          </Dropdown>
        </Menu.Item>

        <Select defaultValue={language || ""} onChange={ changeLanguage }>
          <Option value="">Ћирилица</Option>
          <Option value="sr">Latinica</Option>
          {/* <Option value="en">EN</Option> */}
        </Select>
      </Menu>
    </Fragment>
  )
}

export default AdditionalNav

import React, { Fragment } from "react"
import styles from "./MobileSingleActivity.module.css"
import { Button, Skeleton } from "antd"
import { Parser } from "html-to-react"
import { useTranslation } from 'react-i18next'

import Layout from "../../layouts/Layout"
import MobileSingleActivityInfo from "../../components/SingleActivityInfo/MobileSingleActivityInfo"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import useFetch from "../../hooks/useFetch"
import singleActivity from "../../data/singleActivity"
import textTranslation from '../../textTranslation'

const SingleActivity = props => {
  const { t } = useTranslation("translation")
  const htmlToReact = new Parser()

  const { response, isLoading } = useFetch(
    `/wp-json/wp/v2/activities/${props.id}`
  )

  const checkActivityStatus = () => {
    if ( response.acf ) {
      if ( response.acf.status === 'U toku' ) {
        let d = new Date()
        const today = {
        day: d.getDate(),
        month: d.getMonth(),
        year: d.getFullYear(),
        hrs: d.getHours(),
        min: d.getMinutes()
        }
        let thisDay = new Date(today.year, today.month, today.day, today.hrs, today.min)

        let x = response.acf.date.split('/')
        let y = response.acf.time.split('/')
        let day = new Date(x[2], parseInt(x[1])-1, x[0], parseInt(y[0]))
        return thisDay > day
      } else if (response.acf.status === 'Otkazano' || response.acf.status === 'Popunjeno') {
        return true
      }
    }
  }

  return (
    <Fragment>

      <Layout>
      
        <header className={ styles.singleActivityHeader }>
          <Button
            onClick={ () => window.history.go(-1) }
            icon="left"
            type="link"
            className={ styles.backBtn }
            style={{
              fontFamily: "'Roboto', sans-serif",
              fontSize: "18px",
              lineHeight: "24px"
            }}>
            {t('translation:singleActivity:nazad-na-pretragu')}
          </Button>
        </header>
      
        <main style={{ padding: "20px" }}>
      
          {isLoading ? (
            <Skeleton
              active={ true }
              loading={ true }
              paragraph={{ rows: 10 }}
            ></Skeleton>
          ) : (
            <MobileSingleActivityInfo
              link={ response._links && response._links.self[0].href }
              title={ htmlToReact.parse( response.title && response.title.rendered )}
              date={ response.acf && response.acf.date }
              time={ response.acf && response.acf.time }
              location={ response.acf && response.acf.place }
              coverPhoto={ response.acf && response.acf.image.url }
              bookTitle={ htmlToReact.parse( response.title && response.title.rendered ) }
              others={ response.acf && response.acf.speakers }
              music={ response.acf && response.acf.music }
              description={ response.acf && response.acf.description }
              about={ htmlToReact.parse(textTranslation(response.acf && response.acf.about)) }
              writtenBy={ singleActivity.writtenBy }
              activityId={ props.id }
              allowParticipants={ response.acf && response.acf.allow_participants }
              stillActive={ checkActivityStatus() }
            />
          )}
      
        </main>
      
      </Layout>
      
      <PopularActivities mobile={ props.mobile } />
    
    </Fragment>
  )
}

export default SingleActivity

import React, { useState, useEffect, useCallback } from "react"
import "./FaceOfNsContent.module.css"
import { Skeleton, Menu } from "antd"
import { Link } from "@reach/router"
import MetaDecorator from "../../metaDecorator"
import textTranslation from "../../textTranslation"

const FaceOfNsSider = ({ uri }) => {
  const { Item, ItemGroup } = Menu
  const [sidebarData, setSidebarData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const apiUrl = process.env.REACT_APP_API_URL

  const fetchFaceOfNS = useCallback( () => {
    setIsLoading(true)
    return fetch(`${apiUrl}/wp-json/wp-api-menus/v2/menus/25?lang=${localStorage.getItem('lang')}`)
    .then(response => response.json())
    .then(data => {
      setSidebarData(data)
      setIsLoading(false)
    })
  }, [apiUrl])
  
  useEffect(() => {
    fetchFaceOfNS()
  }, [fetchFaceOfNS])

  return (
    <Skeleton title={false} loading={isLoading} active>
      <MetaDecorator
        title = { textTranslation("Лице Новога Сада") }
      />
      <Menu
        style={{ width: 256 }}
        defaultSelectedKeys={[`${uri}`]}
        mode="inline"
        className="facensMenu">
        {sidebarData &&
          sidebarData.items.map(el => {
            return (
              <ItemGroup key={el.id}>
                <h4 style={{fontSize: "14px"}}>
                  {el.title}
                </h4>
                {el.children &&
                  el.children.map(subMenuItem => {
                    return (
                      <Item key={`/lice-novog-sada${el.url}${subMenuItem.url}`}>
                        <Link to={`/lice-novog-sada${el.url}${subMenuItem.url}`}>
                          {subMenuItem.title}
                        </Link>
                      </Item>
                    )
                  })}
              </ItemGroup>
            )
          })}
      </Menu>
    </Skeleton>
  )
}

export default FaceOfNsSider

import React from "react"
import styles from "./Indexpage.module.css"
import { Tabs, Row, Typography } from "antd"
import { useTranslation } from 'react-i18next'
import moment from "moment"
import SingleLink from "../SingleLink/SingleLink"
import ProcuredBooksList from "./ProcuredBooks/ProcuredBooksList"
import RecommendedBooks from "./RecommendedBooks/RecommendedBooks"
import BooksOfMonth from "./BooksOfMonth/BooksOfMonth"
import NewBooks from "./NewBooks/NewBooks"
import MostRedBooks from "./MostRedBooks/MostRedBooks"

const { TabPane } = Tabs
const { Title } = Typography
const year = moment().year()

const months = [
	"januar",
	"februar",
	"mart",
	"april",
	"maj",
	"jun",
	"jul",
	"avgust",
	"septembar",
	"oktobar",
	"novembar",
	"decembar"
]

const month = months.find((_, i) => i === moment().month())

export default function BookLovers() {
	const { t } = useTranslation("translation")

	return (
		<section>
			<Row type="flex" justify="space-between" align="middle" style={{marginBottom: "32px", marginTop: "4px"}}>	
				<Title level={2} style={{ fontWeight: 400, fontSize: "32px", fontFamily: "Tinos" }}>
					{t('translation:allTitlesSidebar:za-ljubitelje-knjige')}
				</Title>
				<SingleLink to="/naslovi/najcitaniji-naslovi" title={t('translation:allTitlesSidebar:svi-naslovi')}/>
			</Row>

			<Tabs defaultActiveKey="1" className={ styles.tabpanel }>

				<TabPane tab={t('translation:allTitlesSidebar:najcitaniji-naslovi')} key="najcitaniji_naslovi">
					<MostRedBooks url={`/wp-json/wp/v2/most_read_books`} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:nove-knjige')} key="nove_knjige">
					<NewBooks url={"/wp-json/wp/v2/new_books"} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:knjiga-meseca')} key="3">
					<BooksOfMonth url={"/wp-json/wp/v2/books_of_month"} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:prinovljeni-naslovi')} key="4">
					<ProcuredBooksList url={`/wp-json/wp/v2/procured_books`} />
				</TabPane>
				<TabPane tab={t('translation:allTitlesSidebar:preporuka-bibliotekara')} key="5">
					<RecommendedBooks url={"/wp-json/wp/v2/recommended_books"} />
				</TabPane>
			
			</Tabs>
		</section>
	)
}

import React, { Fragment } from "react"
import { Layout } from "antd"
import SiderTitle from "../components/SiderTitle/SiderTitle"
import SiderBottomLinks from "../components/SiderBottomLinks/SiderBottomLinks"
import style from "./WithSider.module.css"
const { Sider, Content } = Layout

function WithSidebar(props) {
  return (
    <Fragment>
      <Layout className={style.layout}>
        <Sider style={props.siderStyle} width={255} theme="light" className={style.locationSidebar}>
          <SiderTitle title={props.siderTitle} />
          {props.sider}
          <SiderBottomLinks title={props.siderTitle} />
        </Sider>
        <Content className={style.content}>{props.content}</Content>
      </Layout>
      {props.moreContent}
    </Fragment>
  )
}

export default WithSidebar

import React, { Fragment } from "react"
import styles from "./SingleActivity.module.css"
import { Button, Skeleton } from "antd"
import { Parser } from "html-to-react"
import { useTranslation } from 'react-i18next'

import SingleActivityInfo from "../../components/SingleActivityInfo/SingleActivityInfo"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import singleActivity from "../../data/singleActivity"
import Layout from "../../layouts/Layout"
import useFetch from "../../hooks/useFetch"
import textTranslation from "../../textTranslation"
import MetaDecorator from "../../metaDecorator"

const SingleActivity = props => {
  const htmlToReact = new Parser()
  const { t } = useTranslation("translation")

  const { response, isLoading } = useFetch(
    `/wp-json/wp/v2/activities/${props.id}`
  )

  const checkActivityStatus = () => {
    
    if ( response.acf ) {
      if ( response.acf.status === 'U toku' ) {
        let d = new Date()
        const today = {
        day: d.getDate(),
        month: d.getMonth(),
        year: d.getFullYear(),
        hrs: d.getHours(),
        min: d.getMinutes()
        }
        let thisDay = new Date( today.year, today.month, today.day, today.hrs, today.min )

        let x = response.acf.date.split('/')
        let y = response.acf.time.split('/')
        let day = new Date(x[2], parseInt(x[1])-1, x[0], parseInt(y[0]))
        return thisDay > day
      } else if ( response.acf.status === 'Otkazano' || response.acf.status === 'Popunjeno' ) {
        return true
      }
    }
  }

  return (
    <Fragment>
      <Layout>

        <header>
          <Button
            onClick={() => window.history.go(-1)}
            className={styles.backBtn}
            icon="left"
            type="link"
          >
            {t('translation:singleActivity:nazad-na-pretragu')}
          </Button>
        </header>
        
        <main>
          <MetaDecorator 
            description={ response.acf && response.acf.description }
            title = { htmlToReact.parse(response.title && response.title.rendered) }
            image = { response.acf && response.acf.image.url }
            url = { window.location.href }
          />
          {isLoading ? (
            <Skeleton
              active={ true }
              loading={ true }
              paragraph={{ rows: 10 }}
            ></Skeleton>
          ) : (
            <SingleActivityInfo
              link={ response._links && response._links.self[0].href }
              title={ htmlToReact.parse(response.title && response.title.rendered) }
              date={ response.acf && response.acf.date } 
              time={ response.acf && response.acf.time }
              location={ response.acf && response.acf.place }
              coverPhoto={ response.acf && response.acf.image.url }
              bookTitle={ htmlToReact.parse(response.title && response.title.rendered)  }
              others={ response.acf && response.acf.speakers }
              music={ response.acf && response.acf.music }
              description={ response.acf && response.acf.description }
              about={ htmlToReact.parse(textTranslation(response.acf && response.acf.about)) }
              writtenBy={ singleActivity.writtenBy }
              activityId={ props.id }
              allowParticipants={ response.acf && response.acf.allow_participants }
              stillActive={ checkActivityStatus() }
              category = {response.acf && response.acf.type}
            />
          )}
        </main>

      </Layout>
      
      <PopularActivities mobile={ props.mobile } />
    
    </Fragment>
  )
}

export default SingleActivity

import React from "react"
import styles from "./Footer.module.css"
import { Link } from "@reach/router"
import { Skeleton, Icon } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import useFetch from "../../hooks/useFetch"
import { useTranslation } from 'react-i18next';
import textTranslation from '../../textTranslation'
import quantox from "../../assets/images/quantox.png"
import vega from "../../assets/images/vega.webp"
import { Parser } from "html-to-react"

function Footer() {
  const htmlToReact = new Parser()
  const { t } = useTranslation("translation")
  const { response, isLoading } = useFetch("/wp-json/wp-api-menus/v2/menus/22")
  const { response: contact, isLoading: contactLoading } = useFetch(
    "/wp-json/wp/v2/settings?slug=podesavanja"
  )
 
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        {
          <Skeleton
            loading={isLoading || contactLoading}
            active
            paragraph={{ rows: 5 }}>
            {response.items &&
              response.items.map(el => {
                return (
                  <div className={styles.item} key={el.title}>
                    <h4 className={styles.header}>{textTranslation(el.title)}</h4>
                    <ul className={styles.list}>
                      {el.children.map((item, index) => {
                         const data = item.url === "/serijske-publikacije" ? "/digitalizacija/nasi-casopisi/informativni-bilten" : `${el.url}${item.url}`;
                         return (
                          <li key={index}>
                            <Link className={styles.footerLink} to={(el.url ==='/izdavastvo' || el.url ==='/za-bibliotekare') ? data : item.url}>
                              {textTranslation(item.title)}
                            </Link>
                          </li>
                        )
                      }
                      )}
                    </ul>
                  </div>
                )
              })}
            <div className={styles.item}>
            <h4 className={styles.header}>{t('translation:footer:kontakt')}</h4>
              <Paragraph className={styles.paragraf}>
                {contact.length && htmlToReact.parse(textTranslation(contact[0].acf.contact))}
              </Paragraph>
            </div>
          </Skeleton>
        }
      </div>
      <div className={styles.bottom} style={{flexDirection: "column"}}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <p className={styles.cc}>
          {t('translation:footer:sva-prava-zadrzana')}
          <Link to='politika-privatnosti'>
            {t('translation:footer:politika-privatnosti')}
          </Link>
          </p>
          <div className={styles.socialBox}>
            <span style={{opacity: ".75"}}>{t('translation:footer:pratite-nas')}</span>
            <ul className={styles.social}>
              <li>
                <a href="https://www.facebook.com/GradskaBibliotekaNoviSad" target="_blank" rel="noopener noreferrer">
                  <Icon type="facebook" style={{color: "#071013", opacity: ".3"}}/>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCkuOAG3oADb468sMyPQH4fw" target="_blank" rel="noopener noreferrer">
                  <Icon type="youtube" style={{color: "#071013", opacity: ".3"}}/>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", opacity: ".6"}}>
          <a href="https://quantox.com/" target="_blank" rel="noopener noreferrer">
            <img 
              style={{width: "40px", margin: "0 12px"}}
              src={quantox}
              alt="Quantox logo" 
            />
          </a>
          <a href="https://www.vegaitsourcing.rs/#" target="_blank" rel="noopener noreferrer">
            <img
              style={{margin: "0 12px"}} 
              src={vega}
              alt="Vega logo"
            />
          </a>
          <a href="https://www.saturized.com/home" target="_blank" rel="noopener noreferrer">
            <svg 
              style={{margin: "0 12px"}}
              id="Layer_1" 
              version="1.1" 
              xmlns="http://www.w3.org/2000/svg" 
              x="0px" y="0px" width="40px" height="36px" viewBox="0 0 120 106" 
              enableBackground="new 0 0 120 106" >
              <path fill="#ed4040" d="M114.161,67.873c-21.403-9.798-35.234-31.332-35.234-54.861l0.088-2.451l0.028-0.558
              c0.186-3.68-2.308-6.947-5.904-7.746c-4.406-0.979-8.824-1.476-13.131-1.476c-4.361,0-8.832,0.508-13.286,1.51
              c-3.586,0.807-6.071,4.067-5.886,7.738l0.03,0.584l0.085,2.398c0,23.472-13.788,44.987-35.126,54.811
              c-3.333,1.534-5.081,5.243-4.143,8.791c2.6,9.824,7.706,18.91,14.768,26.274c2.549,2.657,6.642,3.09,9.689,1.023
              c10.069-6.827,21.781-10.435,33.869-10.435c12.088,0,23.801,3.608,33.869,10.435c3.048,2.066,7.141,1.634,9.69-1.023
              c7.046-7.349,12.146-16.411,14.75-26.209C119.261,73.123,117.506,69.404,114.161,67.873z M73.164,51.412
              c0.993-0.993,2.603-0.993,3.596,0c0.992,0.992,0.992,2.602,0,3.595l-8.539,8.538c-0.992,0.994-2.602,0.994-3.596,0
              c-0.992-0.992-0.992-2.602,0-3.595L73.164,51.412z M39.159,62.047c-0.993-0.993-0.993-2.601,0-3.594l20.523-20.524
              c0.992-0.992,2.603-0.992,3.595,0c0.993,0.995,0.993,2.602,0,3.596L42.755,62.048C41.761,63.04,40.152,63.04,39.159,62.047z
              M43.653,66.542c-0.992-0.994-0.992-2.604,0-3.596l8.539-8.538c0.992-0.993,2.603-0.993,3.595,0c0.993,0.992,0.993,2.602,0,3.594
              l-8.538,8.54C46.256,67.534,44.646,67.534,43.653,66.542z M48.147,71.035c-0.993-0.992-0.993-2.603,0-3.595l20.523-20.522
              c0.993-0.993,2.602-0.993,3.595,0c0.994,0.991,0.994,2.603,0,3.595L51.742,71.035C50.75,72.028,49.139,72.028,48.147,71.035z
              M81.254,59.501L60.73,80.023c-0.992,0.993-2.602,0.993-3.595,0c-0.993-0.993-0.993-2.602,0-3.595l20.523-20.523
              c0.993-0.992,2.602-0.992,3.596,0C82.246,56.898,82.247,58.508,81.254,59.501z"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer

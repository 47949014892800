import React, { useRef, useState, useCallback, useImperativeHandle, forwardRef, useEffect} from "react"
import { Modal, Carousel, Row, Col, Button } from "antd"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { ZoomInOutlined, ZoomOutOutlined, FullscreenOutlined } from '@ant-design/icons'

import styles from "./DigitizedBookModal.module.css"
import MetaDecorator from "../../metaDecorator"

const DigitizedBookModal = forwardRef(({ isVisible, closeModal, data }, ref) => {
  const { t } = useTranslation("translation")
  const [currentPage, setCurrentPage] = useState(0)
  const [carouselRef, setCarouselRef] = useState(null)
  const carousel = useCallback(ref => { setCarouselRef(ref) }, [])
  const previewCarousel = useRef()

  function next() { carouselRef.slick.slickNext() }
  function prev() { carouselRef.slick.slickPrev() }

  const mainCarouselSettings = {
    className: "mainCarousel",
    dots: false,
    arrows: false,
    draggable: true,
    ref: carousel,
    afterChange: current => setCurrentPage(current),
    asNavFor: previewCarousel.current,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const previewCarouselSettings = {
    className: "previewCarousel",
    slidesToShow: 3,
    infinite: data.acf.pages.length > 3,
    slidesToScroll: 1,
    draggable: true,
    dots: false,
    centerMode: true,
    centerPadding: "10px",
    ref: previewCarousel,
    focusOnSelect: true,
    arrows: false,
    speed: 500,
    asNavFor: carouselRef
  }
  
 let ResetZoomButton;
 
//  var willZoomOut = new Promise(
//   function (resolve, reject) {
//       if (isVisible) {
//         setTimeout(function() {
//           ResetZoomButton.click()
//           resolve(); // fulfilled
//         }, 2000)
         
//       } else {
//           reject(); // reject
//       }
//   }
// );

// var closeModal = function () {
//   return new Promise(
//       function (resolve, reject) {
//         closeModal()
//         resolve();
//       }
//   );
// };

// var closeModalResetZoom= function () {
//   willZoomOut
//   .tnen(closeModal)
//   .then(function (fulfilled) {
//     console.log(fulfilled);
//   }). catch(function(error) {
//     console.log(error.message)
//   })
// }

  const handleZoomClick = function(){
    if(!ResetZoomButton.click()) {
      setTimeout( () => {
        closeModal()
      },200)
    }
  }

  return (
    <Modal
      className="digitizedModal"
      title={ null }
      visible={ isVisible }
      onCancel={ () => { handleZoomClick() } }
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={ null }
      bodyStyle={{ padding: 0 }}
      closeIcon={ <div className={ styles.customCloseIcon }></div> }
      width="100%"
      mask={ false }
    >

      <span className={`${styles.currentPage} ${styles.mobile}`}>
        {`${currentPage + 1}/${data.acf.pages.length}`}
      </span>

      <Row className={ styles.carouselRow }>
        <Col lg={{ span: 14, offset: 2 }}>
          <div className="carousel-wrapper">
            <Carousel { ...mainCarouselSettings }>
              {data.acf.pages.map( page => {
                return (
                  <div className={`${styles.singlePage}`} key={ page.id }>
                    <TransformWrapper
                      // defaultScale={ 1 }
                      defaultPositionX={ 200 }
                      defaultPositionY={ 100 }
                      options={{
                        centerContent: true
                      }}
                      reset={{
                        animation: false
                      }}
                      scale={1}
                    >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
                      
                      return(
                      <React.Fragment >
                        <MetaDecorator 
                          title = { textTranslation(data.post_title) }
                          description = { textTranslation(data.acf.description) }
                          image = { data.acf.pages && data.acf.pages[0].sizes.medium }
                          url = { window.location.href} 
                        />
                        <div className={ styles.tools } style={{display:"flex", justifyContent: "center", marginBottom:"1rem"}}>
                          <button style={{boxSizing: "border-box",height: "41px",
                          width: "41px",border: "1px solid #F1F2F5",borderRadius: "20px",
                          backgroundColor: "#FFFFFF",boxShadow: "0 5px 6px -3px rgba(7,16,19,0.1)",
                          color: "red",
                          fontSize: "17px",
                          marginLeft: "5px",
                          cursor: "pointer"}}
                          className={ styles.zoomBtn } onClick={ zoomIn }><ZoomInOutlined /></button>
                          <button style={{boxSizing: "border-box",height: "41px",
                          width: "41px",border: "1px solid #F1F2F5",borderRadius: "20px",
                          backgroundColor: "#FFFFFF",boxShadow: "0 5px 6px -3px rgba(7,16,19,0.1)",
                          color: "red",
                          fontSize: "17px",
                          marginLeft: "5px",
                          cursor: "pointer"}} className={ styles.zoomBtn } onClick={ zoomOut }><ZoomOutOutlined /></button>
                          <button  style={{boxSizing: "border-box",height: "41px",
                          width: "41px",border: "1px solid #F1F2F5",borderRadius: "20px",
                          backgroundColor: "#FFFFFF",boxShadow: "0 5px 6px -3px rgba(7,16,19,0.1)",
                          color: "red",
                          fontSize: "17px",
                          marginLeft: "5px",
                          cursor: "pointer"}} className={ styles.zoomBtn } onClick={ () => { resetTransform()} }><FullscreenOutlined /></button>
                          <button ref={button => ResetZoomButton = button} onClick={ () => { resetTransform() } } style={{display: "none"}}>
                            <i className="material-icons" >
                              close
                            </i>
                          </button>
                        </div>
                        
                        <TransformComponent>
                          <img src={ page.url } alt="test" />
                        </TransformComponent>
              
                      </React.Fragment> 
                    )}}
                    </TransformWrapper>
                  </div>
                )
              })}
            </Carousel>

            {/* previewZoom.current.focus() */}
            <Button className={ styles.carouselControll } onClick={ () => { prev() }} shape="circle" icon="left"></Button>
            <Button className={ styles.carouselControll } onClick={ () => {next() }} shape="circle" icon="right"></Button>

          </div>
        </Col>

        <Col lg={{ span: 5, offset: 1 }}>
          <div className={ styles.previewWrapper }>
            <Carousel { ...previewCarouselSettings }>
              {data.acf.pages.map( page => {
                return (
                  <div key={ page.id }>
                    <img src={ page.url } alt="" />
                  </div>
                )
              })}
            </Carousel>

            <span className={ styles.currentPage }>
              {`${currentPage + 1}/${data.acf.pages.length}`}
            </span>
          </div>

          <h2 className={ styles.bookTitle }>
            { textTranslation(data.post_title) }
          </h2>
          <div className={ styles.details }>
            <div className={ styles.detailsItem }>
              <p>{t('translation:digitizedBookModal:autor')} </p> <span>{ textTranslation(data.acf.author) }</span>
            </div>
            <div className={ styles.detailsItem }>
              <p>{t('translation:digitizedBookModal:godina')}</p> <span>{ data.acf.year }</span>
            </div>
            <div className={ styles.detailsItem }>
              <p>{t('translation:digitizedBookModal:isbn')}</p> <span>{ textTranslation(data.acf.isbn) }</span>
            </div>
          </div>
          <div className={ styles.description }>
            <p>{ textTranslation(data.acf.description) }</p>
          </div>
        </Col>
      </Row>
    </Modal>
  )
})

export default DigitizedBookModal

import React from 'react'
import { Row, Col } from 'antd'

//components
import Layout from '../layouts/Layout'
import FeaturedActivity from '../components/Activity/FeaturedActivity/FeaturedActivity'
import SideFaq from '../components/SideFaq/SideFaq'
import NextActivities from '../components/Activity/NextActivities/NextActivities'
import Survey from '../components/Survey/Survey'
import SideBaner from '../components/SideBaner/SideBaner'
import ForBooksLovers from '../components/ForBookLovers'
import MetaDecorator from '../metaDecorator'

function Index() {
	return (
		<Layout>
			<MetaDecorator />
			<FeaturedActivity />

			<Row style={{ marginTop: '146px' }}>
				
				<Col md={ 16 }>
					<NextActivities />
				</Col>
				
				<Col md={ 8 } style={{ borderLeft: '1px solid #F1F2F5', paddingLeft: '50px' }}>
					<SideFaq />
				</Col>
			
			</Row>
			
			<Row style={{ marginTop: '146px', marginBottom: '146px' }}>
				
				<Col md={ 24 }>
					<ForBooksLovers />
				</Col>
			
			</Row>
			
			<Row>
				<Col lg={{ span: 16, offset: 4 }}>		
					<Survey />
				</Col>

				<Col md={ 4 }>
					<SideBaner />
				</Col>
				
			</Row>
		</Layout>
	)
}

export default Index;

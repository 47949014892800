import React, { useState, useEffect } from "react"
import { Typography, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const { Title } = Typography

function WorkInProgress() {
    const { t } = useTranslation("translation")

    return (
     
        <div style={{ padding: '200px', textAlign: 'center' }}> 
          <div style={{  marginTop: '2rem' }}>
            <Title level={ 3 }>
                { t('translation:workInProgress:nasi-programeri') }<span role="img" aria-label="worker">👷</span>
            </Title>
            <Title level={ 4 }>{ t('translation:workInProgress:hvala-na-strpljenju') }<span role="img" aria-label="smile">😊</span></Title>
          </div>
        </div>
        
    )
}

export default WorkInProgress
import React from "react";
import { Collapse, Typography } from "antd";
import { ExpandIcon } from "../../components/CustomIcon/CustomIcon";
import styles from "./FAQ.module.css";

const { Text } = Typography;
const { Panel } = Collapse;

const SingleFAQ = ({ title, text, id }) => {
	return (
		<Collapse
			expandIconPosition="right"
			expandIcon={({ isActive }) => (
				<span className={styles.icon}>
					<ExpandIcon isActive={isActive} />
				</span>
			)}
			bordered={false}
			defaultActiveKey={0}>
			<Panel
				header={
					<div>
						<Text style={{ fontWeight: "500", fontSize: "18px" }}>{title}</Text>
					</div>
				}
				key={id}>
				<Text>{text}</Text>
			</Panel>
		</Collapse>
	);
};

export default SingleFAQ;

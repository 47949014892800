import React, { useState, useCallback } from "react"
import styles from "./NewBooks.module.css"
import { Carousel, Button } from "antd"

import useFetch from "../../../hooks/useFetch"
import MobileNewBook from "./MobileNewBook"



export default ({ url }) => {
	const [ref, setRef] = useState( null )


	const carousel = useCallback( ref => setRef( ref ), [] )
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	const { response } = useFetch( url )

	return (
		<>
			<Carousel 
				ref={ carousel } 
				dots={ false } 
				adaptiveHeight={ true }
				variableWidth={ true }
			>

				{response.map( row => {
					return row.acf.book.map(( book, i ) => (
						<MobileNewBook
							id={ book.ID }
							title={ book.post_title }
							author={ book.acf.author }
							imgSrc={ book.acf.image? book.acf.image.sizes.medium : ""}
							// info={ htmlToReact.parse(book.acf.about) }
							info={ book.acf.about }
							bckDrop={ i + 1 }
							ganre={ book.acf.genre }
						/>
					))
				})}

			</Carousel>
			
			<div 
				className={ styles.actions }
				style={{
					display: window.screen.width >= 600 ? "flex" : "none",
					justifyContent: "center"
				}}
			>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ prev }
						shape="circle"
						icon="left" 
					/>
				</span>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ next }
						shape="circle"
						icon="right"
					/>
				</span>
			</div>

		</>
	)
}

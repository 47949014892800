import React, { useState } from 'react'
import style from './FeaturedActivity.module.css'
import { Row, Col, Typography, Card, Button, Icon, Skeleton } from 'antd'
import { Link } from '@reach/router'
import { Parser } from 'html-to-react'
import useFetch from '../../../hooks/useFetch'
import textTranslation from '../../../textTranslation'
import moment from 'moment'
import 'moment/locale/sr'
// import convert from 'cyrillic-to-latin'
import convert from 'latin-to-serbian-cyrillic'
import './FeaturedActivityCard.css'

//components
import SingleLink from '../../SingleLink/SingleLink'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography

moment.locale('sr')
const getDay = date => moment(date, 'DD/MM/YYYY').format('dddd')
const getDate = date => moment(date, 'DD/MM/YYYY').format('DD. MMMM YYYY.')

const FeaturedActivity = () => {
	const { t } = useTranslation("translation")
	const htmlToReact = new Parser()

	const { response, isLoading } = useFetch(`/wp-json/gbns/v2/activities/featured`)

	const [ activityId, setActivityId ] = useState( 0 )

	const changeActivity = ( forward = true ) => {
		if ( forward && activityId === response.length - 1 ) {
			setActivityId(0)
		} else if ( !forward && activityId === 0 ) {
			setActivityId( response.length - 1 )
		} else {
			setActivityId( forward ? activityId + 1 : activityId - 1 )
		}
	}

	return (
		<Row>
			<Col md={ 16 }>
				<Row type='flex' justify='space-between' align='middle' style={{ marginBottom: '32px', marginTop: '4px' }}>
					<Title className={ style.title } level={ 2 } style={{ fontSize: "32px" }} />
					<SingleLink className={ style.link } to='/aktivnosti' title={t('translation:featuredActivity:sve-aktivnosti')}/>
				</Row>

				<Row>
					<Card className='featured-activity-card' bordered={ false } loading={ isLoading } style={{minHeight: "350px"}}>
						{!isLoading && response && response.length && (
							<>
								<Title level={ 2 } className={ style.cardTitle } ellipsis={{ rows: 1 }}>
									{ textTranslation(response[activityId].post_title) } 
								</Title>

								<Skeleton active={ true } loading={ isLoading } paragraph={{ rows: 10 }}/>

								<Paragraph ellipsis={{ rows: 2 }} style={{ height: "54px" }}>
									{ textTranslation(htmlToReact.parse( response[activityId].acf.description )) }
								</Paragraph>

								<br />

								{response[activityId].acf.speakers ? <>
									<Paragraph>
										{ textTranslation(response[activityId].acf.speakers) }
									</Paragraph>
								</>
								:
								<div style={{height: "54px"}}/>}

								<div className={ style.btnWrapper }>
									{response.length > 1 && (
										<>
											<div onClick={ () => changeActivity() } className={ style.pageBtn }>
												<Icon type='left' width='6px' className={ style.mAuto }/>
											</div>
											<div onClick={ () => changeActivity( false ) } className={`${style.pageBtn} ${style.mLeft}`}>
												<Icon type='right' width='6px' />
											</div>
										</>
									)}

									<Button className={ style.readMore } size='large' type='primary'>
										<Link to={`/aktivnosti/${response[activityId].ID}`}>
											{t('translation:featuredActivity:procitaj-vise')}
											<Icon className={ style.icon } height='12px' type='arrow-right' />
										</Link>
									</Button>

								</div>

							</>
						)}
					</Card>
				</Row>
			</Col>

			<Col md={ 8 }>
				{ !isLoading && response && response.length && response[activityId].acf.image && (
						<div className={ style.imageWrapper } style={{ backgroundImage: `url(${response[activityId].acf.image.url})` }}>
							<div className={ style.imageBackdrop }/>
							<div className={ style.featuredImageInfo }>
								{response[activityId].acf.date &&
                <>
									<div>
										<div className={ style.featuredImageDay }>
											{ localStorage.getItem("lang") === '' ? convert( getDay(response[activityId].acf.date)) : getDay(response[activityId].acf.date) }
										</div>
										<div className={ style.featuredImageDate }>
											{ localStorage.getItem("lang") === '' ? convert(getDate(response[activityId].acf.date )) : getDate(response[activityId].acf.date) }
										</div>
									</div>
								<span className={ style.featuredImageDivider }/>
							</>
						}
							<div className={ style.featuredImagePlace }>
								{ textTranslation(response[activityId].acf.place) }
							</div>
						</div>

					</div>
					)}
			</Col>
		</Row>
    )
    
}

export default FeaturedActivity
import React from "react"
import { List } from "antd"
import Masonry from "react-masonry-component"

import styles from "./GalleryMasonryList.module.css"

function GalleryMasonryList( props ) {
  const items = gallery => {
    return (
      <List.Item onClick={ () => props.onItemClick(gallery)}>
        {gallery.mime_type.includes("video") ? (
          <video className={ styles.item } loop muted controls>
            <source src={ gallery.url } type="video/mp4" />
          </video>
        ) : (
          <img className={ styles.item } src={ gallery.url } alt="slika"/>
        )}
      </List.Item>
    )
  }

  return (
    <React.Fragment>
      { props.loading && <div>loading</div> }
      <Masonry>
        <List
          grid={{
            gutter: 24,
            xs: 1,
            lg: 4
          }}
          loading={ props.loading }
          dataSource={ props.list.acf ? props.list.acf.gallery : [] }
          renderItem={ gallery => items(gallery) }
        />
      </Masonry>
    </React.Fragment>
  )
}

export default GalleryMasonryList
import React from "react"
import { Link } from "@reach/router"
import { Menu, Icon } from "antd"
import styles from "./SiderBottomLinks.module.css"
import { useTranslation } from 'react-i18next'


function SiderBottomLinks(props) {
  const title = props.title
	const { t } = useTranslation("translation")

  return (
    <div className={styles.wrapper}>
      <Menu className={styles.menu}>
        {title !== "Vodič za korisnike" && (
          <Menu.Item 
          className={styles.item}
           key="1"
           style={{
            display: title === "Водич за кориснике" ? "none" : "block"
          }}
           >
            <Link to="/vodic-za-korisnike/cesto-postavljana-pitanja">
              <Icon width="16px" type="question-circle" />
              {t('translation:mainNav:cesto-postavljana-pitanja')}
            </Link>
          </Menu.Item>
        )}
        {title !== "Vodič za korisnike" && (
          <Menu.Item
            className={styles.item}
            key="2"
            style={{
              display: title === "Водич за кориснике" || title === "Vodič za korisnike" ? "none" : "block"
            }}
            >
            <Link to="/kontakt/pitaj-bibliotekara">
              <Icon width="16px" type="user" />
              {t('translation:mainNav:pitajte-bibliotekara')}
            </Link>
          </Menu.Item>
        )}
        {title !== "Vodič za korisnike" && (
          <Menu.Item
            className={styles.item }
            key="3"
            style={{
              display: title === "Водич за кориснике" || title === "Vodič za korisnike" ? "none" : "block"
            }}
            >
            <Link to="/vodic-za-korisnike/cenovnik">
              <Icon width="16px" type="file-text" />
              {t('translation:mainNav:cenovnik')} 
            </Link>
          </Menu.Item>
        )}
        {title !== "Kontakt" && (
          <Menu.Item className={styles.item} key="4">
            <Link to="/kontakt">
              <Icon width="16px" type="mail" />
              {t('translation:mainNav:kontakt')}
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  )
}

export default SiderBottomLinks

import React from 'react'
import { Typography } from 'antd'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { Parser } from "html-to-react"

import style from "./Location.module.css"
const { Text } = Typography

const StreetMap = ({ locations }) => {
  const { t } = useTranslation("translation")
  const position = [45.25, 19.84]
  const htmlToReact = new Parser()

  return (
    <Map 
      className={ style.streetmap }
      center={ position } 
      zoom={ 13 } 
      tap={ false }
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      />

      { locations.length ? 
          locations.map(item => (
        <Marker key={ item.ID } position={ [item.acf.latitude, item.acf.longitude] } className={ style.map_marker }>
          <Popup>
            <div style={{width: "225px"}}>
              <div className={ style.panelContainer } style={{ flexDirection: "column", width: "100%", padding: "0" }} >
                <Text className={ style.header } style={{ marginBottom: "8px" }}>
                  <strong>{ item.post_title }</strong>
                </Text>
                <div style={{ display: window.screen.width <= 576 ? "none" : "block" }}>
                  <p className={ style.p } style={{ margin: "0" }}>
                    <Text type="secondary" style={{ marginRight: "10px" }}>
                      {t('translation:singleLocation:adresa')}
                    </Text>
                    <Text strong className={ style.accordionRegularText }>
                      { textTranslation(item.acf.addreess) }
                    </Text>{ " " }
                  </p>
                  <p className={ style.p }>
                    <Text type="secondary" style={{ marginRight: "10px" }}>
                      { t('translation:singleLocation:telefon')}:
                    </Text>
                    <Text strong className={ style.accordionRegularText }>
                      { item.acf.phone }
                    </Text>{ " " } 
                  </p>
                </div> 
              </div>
              <div className={ style.workTime } 
                style={{
                  flexDirection: "column",
                  display: window.screen.width <= 576 ? "none" : "flex"
                }} 
              >
                <p style={{ margin: "0" }} className={ `${style.p} ${style.workTimeLabel}` }>
                  <Text type="secondary" style={{ marginRight: "5px" }}>
                  { t('translation:singleLocation:radno-vreme') }
                  </Text>
                </p>
                { item.acf.use_text_for_working_hours ? 
                    <div className={ style.workTimeDay }>
                      { htmlToReact.parse(textTranslation(item.acf.working_hours_text)) }
                    </div> 
                    : 
                    <div style={{ marginLeft: "5px" }}>
                      {item.acf.monday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>{ t('translation:singleLocation:ponedeljak') }</Text>
                        </p>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>: { `${item.acf.monday_hours_from} - ${item.acf.monday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.tuesday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>{ t('translation:singleLocation:utorak') }</Text>
                        </p>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>: { `${item.acf.tuesday_hours_from} - ${item.acf.tuesday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.wednesday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>{ t('translation:singleLocation:sreda') }</Text>
                        </p>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>: { `${item.acf.wednesday_hours_from} - ${item.acf.wednesday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.thursday_hours_from &&<div className={ style.workTimeDay }>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>{ t('translation:singleLocation:cetvrtak') }</Text>
                        </p>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>: { `${item.acf.thursday_hours_from} - ${item.acf.thursday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.friday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>{ t('translation:singleLocation:petak') }</Text>
                        </p>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>: { `${item.acf.friday_hours_from} - ${item.acf.friday_hours_until}` }</Text>
                        </p>
                      </div>}
                      {item.acf.saturday_hours_from && <div className={ style.workTimeDay }>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>{ t('translation:locationPage:subota') }</Text>
                        </p>
                        <p className={ style.p } style={{margin: 0, fontSize: "12px"}}>
                          <Text strong>: { `${item.acf.saturday_hours_from} - ${item.acf.saturday_hours_until}` }</Text>
                        </p>
                      </div>}
                    </div>
                  }
                {/* <div style={{ marginLeft: "5px" }}>
                  <div className={ style.workTimeDay }>
                    <p style={{ margin: "0" }} className={ style.p }>
                      <Text strong>{ t('translation:singleLocation:ponedeljak') }</Text>
                    </p>
                    <p style={{ margin: "0" }} className={ style.p }>
                      <Text strong>: { `${item.acf.monday_hours_from} - ${item.acf.monday_hours_until}` }</Text>
                    </p>
                    </div>
                    <div className={ style.workTimeDay }>
                      <p style={{ margin: "0" }} className={ style.p }>
                        <Text strong>{ t('translation:singleLocation:utorak') }</Text>
                      </p>
                      <p style={{ margin: "0" }} className= { style.p }>
                        <Text strong>: { `${item.acf.tuesday_hours_from} - ${item.acf.tuesday_hours_until}` }</Text>
                      </p>
                    </div>
                    <div className={ style.workTimeDay }>
                      <p style={{ margin: "0" }} className={ style.p }>
                        <Text strong>{ t('translation:singleLocation:sreda') }</Text>
                      </p>
                      <p style={{ margin: "0" }} className={ style.p }>
                        <Text strong>: { `${item.acf.wednesday_hours_from} - ${item.acf.wednesday_hours_until}` }</Text>
                      </p>
                    </div>
                    <div className={ style.workTimeDay }>
                      <p style={{ margin: "0" }} className={ style.p }>
                        <Text strong>{ t('translation:singleLocation:cetvrtak') }</Text>
                      </p>
                      <p style={{ margin: "0" }} className={ style.p }>
                        <Text
                          strong>: { `${item.acf.thursday_hours_from} - ${item.acf.thursday_hours_until}` }</Text>
                      </p>
                    </div>
                    <div className={ style.workTimeDay }>
                      <p style={{ margin: "0" }} className={ style.p }>
                        <Text strong>{ t('translation:singleLocation:petak') }</Text>
                      </p>
                      <p style={{ margin: "0" }} className={ style.p }>
                        <Text
                          strong>: { `${item.acf.friday_hours_from} - ${item.acf.friday_hours_until}` }</Text>
                      </p>
                    </div>
                  </div> */}
              </div>
            </div>
          </Popup>
        </Marker>
      )) : null }
    </Map>
  )
}

export default StreetMap
import React from "react"
import { Typography } from "antd"
import { navigate } from "@reach/router"
import styles from "./RecommendedBook.module.css"
import { useTranslation } from 'react-i18next'
import textTranslation from "./../../../textTranslation"
import { Parser } from 'html-to-react'
const { Paragraph, Title, Text } = Typography

export default ({
	id,
	title,
	imgSrc,
	author,
	recommendationBy,
	info,
	bckDrop,
}) => {
	const { t } = useTranslation("translation")
	const htmlToReact = new Parser()
	function shortDesc() {
		return (htmlToReact.parse(textTranslation(info)))
	}
	return (
		<div 
			className={ styles.container } 
			onClick={() => navigate(`/knjiga/${id}`)}
			style={{ height: document.body.offsetWidth <= 576 ? "500px" : "300px" }}
		>
			<Title
				level={1}
				style={{ fontSize: "100px", top: "-5%" }}
				className={ styles.bckDrop }>
				{ bckDrop }
			</Title>
			<div>
				<img src={`${imgSrc}`} className={styles.img} alt=""/>
			</div>
			<div className={ styles.info }>
				{recommendationBy && <p className={ styles.recommendedBy }>
					<span style={{ opacity: "0.2" }}>{t('translation:recommendedBook:preporuka')}</span>
					{"  "}
					{ textTranslation(recommendationBy) }
				</p>}
				<Title
					ellipsis={{ rows: 2 }}
					level={4} 
					className={ styles.title }
					style={{ marginTop: recommendationBy ? "0" : "31px" }}
				>
					{ textTranslation(title) }
				</Title>
				<Text className={ styles.author } style={{ marginTop: "30px" }}>
					{ textTranslation(author) }
				</Text>
				<Paragraph className={ styles.text }>
					{ shortDesc(info) }
				</Paragraph>
			</div>
		</div>
	);
};

import React, { useState, useEffect } from "react"
import "./AboutSidebar.module.css"
import { Link } from "@reach/router"
import { Menu, Skeleton, Typography } from "antd"
import textTranslation from "../../textTranslation"

const { Item, ItemGroup } = Menu
const { Paragraph } = Typography



const AboutSidebar = props => {
  const apiUrl = process.env.REACT_APP_API_URL

  const [sidebarData, setSidebarData] = useState( null )
  const [isLoading, setIsLoading] = useState( false )

  useEffect(() => {
    setIsLoading( true )
    const createUrl = () => ( localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr' )
    ? `${apiUrl}/wp-json/wp-api-menus/v2/menus/12?lang=`
    : `${apiUrl}/wp-json/wp-api-menus/v2/menus/12?lang=${localStorage.getItem('lang')}`
    fetch(createUrl())
      .then(response => response.json())
      .then(data => {
        setSidebarData(data)
        setIsLoading(false)
      })
  }, [])


  return (
    <Skeleton title={false} paragraph={{ rows: 15 }} loading={isLoading} active>
      <Menu
        style={{ width: 256 }}
        defaultSelectedKeys={[`${props.uri.uri}`]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        className="digitizationMenu">
        {sidebarData && sidebarData.items.map(el => {
          if (el.children) {
            return (
              <ItemGroup key={el.id} title={textTranslation(el.title)} className="itemGroupTitle">
                {el.children && el.children.map(child => {
                  return (
                    <Item key={`/o-nama${el.url}${child.url}`} >
                      <Link to={`/o-nama${el.url}${child.url}`} >
                        <Paragraph ellipsis={{ row: 1 }}>
                          {textTranslation(child.title)}
                        </Paragraph>
                      </Link>
                    </Item>
                  )
                })}
              </ItemGroup>
          )}

          else {
            return (
              <Item key={`/o-nama${el.url}`} className="menuItemNotIndented">
                <Link to={`/o-nama${el.url}`}>
                  <Paragraph ellipsis={{ row: 1 }} style={{color: props.uri.uri && props.uri.uri === `/o-nama${el.url}` ? "rgb(0,0,0)" : "rgba(0,0,0,0.65)"}}>
                    {textTranslation(el.title)}
                  </Paragraph>
                </Link>
              </Item>
            )
          }
          })}
      </Menu>
    </Skeleton>
  )
}

export default AboutSidebar

import React, { useState, useEffect } from "react"
import { Row, Empty, Col, Typography, Button, Menu, List, Skeleton } from "antd"
import WithSider from "../../layouts/WithSider"
import ContactModal from "../../components/ContactModal/ContactModal"
import ContactItem from "../../components/ContactItem/ContactItem"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import { useTranslation } from 'react-i18next'
import useFetch from "../../hooks/useFetch"
import { Link } from "@reach/router"
import { Parser } from "html-to-react"
import textTranslation from "./../../textTranslation"
import styles from "../Activities/Activities.module.css"
import mobileStyles from "../Activities/MobileActivities.module.css"
import CustomButton from "../../components/CustomButton/CustomButton"
import MetaDecorator from "../../metaDecorator"

const { Text } = Typography

function Contact( props ) {
  const { t } = useTranslation("translation")
  const { activeItem, mobileContactPage } = props
  const [contactData, setContactData] = useState({
    employee: [],
    branch: [],
    department: []
  })
  const [page, setPage] = useState( 1 )
  const [modalVisible, setModalVisible] = useState( false )
  const [isLoading, setIsLoading] = useState( false )
  const [activeMenu, setActiveMenu] = useState( null )
  const [disabled, setDisabled] = useState( false )
  const closeModal = () => setModalVisible( false )
  const pageId = props.id
  const apiUrl = process.env.REACT_APP_API_URL

  const {
    response: responseAskLibrarian,
    isLoading: askLibrarianIsLoading
  } = useFetch("/wp-json/wp/v2/pages", {
    slug: "pitajte-bibliotekara"
  })

  const htmlToReact = new Parser()

  useEffect(() => {    
    switch (pageId) {
      case "zaposleni":
        setPage(1)
        setContactData({employee: [], branch: [], department: []})
        setActiveMenu("employee")
        break
      case "sluzbe":
        setPage(1)
        setContactData({employee: [], branch: [], department: []})
        setActiveMenu("department")
        break
      case "ogranci":
        setPage(1)
        setContactData({employee: [], branch: [], department: []})
        setActiveMenu("branch")
        break
      default:
        setActiveMenu("ask")
    }
  }, [pageId])

  useEffect(() => {
    let uri = null

    if (activeMenu === "employee") {
      uri = "employees"
    } else if (activeMenu === "department") {
      uri = "departments"
    } else if (activeMenu === "branch") {
      uri = "branches"
    }

    if (uri && activeMenu !== "ask") {
      const createUrl = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
      ? `${apiUrl}/wp-json/wp/v2/${uri}?lang=&page=${page}` 
      : `${apiUrl}/wp-json/wp/v2/${uri}?lang=${localStorage.getItem('lang')}&page=${page}`


      fetch(createUrl())
      .then(res => {
        const wpTotalPages = res.headers.get("X-WP-TotalPages")
        if (page >= wpTotalPages) {
          setDisabled( true )
        } else {
          setDisabled(false)
        }
        return res.json()})
      .then(res => {
        if (res.length) setContactData({ ...contactData, [activeMenu]: [...contactData[activeMenu] ,...res] })
        setIsLoading(false)
      })
      .catch(err => console.error("err",err))
    }
  }, [activeMenu, page])

  useEffect(() => {
    if (mobileContactPage) {
      if (activeMenu && activeMenu !== activeItem) {
        setActiveMenu(activeItem)
      }
    }
  }, [activeItem])
  const handleClick = (e, a)=>{
    console.log(e, a)
  }

  const loadMore = !isLoading ? (
    <CustomButton
      click={ () => setPage(page+1) }
      isLoading={ isLoading }
      disabled={ disabled }
      className={ mobileStyles.customButton }>
      {t('translation:activities:prikazite-jos')}
    </CustomButton>
  ) : null

  const locale = {
    emptyText: (
      <Empty
        image={ Empty.PRESENTED_IMAGE_SIMPLE }
        className={ styles.noResults }
        imageStyle={{ height: 60 }}
        description={ <span>{t('translation:activities:nema-rezultata')}</span> }
      ></Empty>
    )
  }

  const renderContent = () => {
    switch (activeMenu) {
      case "ask":
        return (
          <Row justify="center">
              <Col xs={24} md={{ span: 18, offset: 3 }}>
                
                <Text style={{fontFamily: "'Tinos', serif"}}>
                  { (!askLibrarianIsLoading && responseAskLibrarian.length && htmlToReact.parse(textTranslation(responseAskLibrarian[0].content.rendered)) )
                    || 
                  t('translation:contact:text')}
                </Text>
                
                <br /><br />
                
                <Button size="large" type="primary" onClick={ () => setModalVisible(!modalVisible) }>
                  {t('translation:contact:kontakt')}
                </Button>
                
                <ContactModal
                  title={t('translation:contact:pitajte-bibliotekara')}
                  visible={ modalVisible }
                  onCancel={ closeModal }
                  handleClick={ handleClick }
                />

              </Col>
            
            <Skeleton active={ true } loading={ askLibrarianIsLoading } paragraph={{ rows: 10 }} />
          </Row>
        )
      default:
        if (
          !isLoading &&
          contactData[activeMenu] &&
          contactData[activeMenu].length === 0
        ) {
          setIsLoading(true)
        }

        return (
          <List
            dataSource={ contactData[activeMenu] }
            loadMore={ loadMore }
            locale={ locale }
            renderItem={ item => <ContactItem data={ item }/> }>
            <Skeleton active={ true } loading={ isLoading } />
          </List>
        )
    }
  }

  let currentMenu = props.id
  let activeMenuItem = null

  switch (currentMenu) {
    case "zaposleni":
      activeMenuItem = "employee"
      break
    case "sluzbe":
      activeMenuItem = "department"
      break
    case "ogranci":
      activeMenuItem = "branch"
      break
    default:
      activeMenuItem = "ask"
  }
 
  return (
    <>
    <MetaDecorator
      title = {textTranslation("Контакт")}
      url = { window.location.href }
      />
    <WithSider
      siderTitle={ t('translation:contact:kontakt') } 
      sider={
        <Menu className="digitizationMenu" defaultSelectedKeys={ [`${props.uri}`] } mode="inline">
          <Menu.Item key="/kontakt/bibliotekar">
            <Link to="/kontakt/pitajte-bibliotekara">{t('translation:contact:pitajte-bibliotekara')}</Link>
          </Menu.Item>
          <Menu.Item key="/kontakt/zaposleni">
            <Link to="/kontakt/zaposleni">{t('translation:contact:zaposleni')}</Link>
          </Menu.Item>
          <Menu.Item key="/kontakt/sluzbe">
            <Link to="/kontakt/sluzbe">{t('translation:contact:sluzbe')}</Link>
          </Menu.Item>
          <Menu.Item key="/kontakt/ogranci">  
            <Link to="/kontakt/ogranci">{t('translation:contact:ogranci')}</Link>
          </Menu.Item>
        </Menu>
      }
      ignoreBottom={ ["contact"] }
      content={ renderContent() }
      moreContent={ <PopularActivities mobile={ mobileContactPage } /> }
    />
    </>
  )
}

export default Contact
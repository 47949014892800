import React, {useState, useEffect} from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import style from './SideBaner.module.css'

const { Title } = Typography;
const apiUrl = process.env.REACT_APP_API_URL

function SideBaner() { 
    const [data, setData] = useState( [] )
	const { t } = useTranslation("translation")

    const fetchBaners = async () => {
        const res = await fetch( `${apiUrl}/wp-json/wp/v2/banners`)
        const dataBaners = await res.json()
        setData([ ...dataBaners])
    }

    useEffect( () => {
        fetchBaners()
    }, [])
    
    return (<>
        {data.map(e => (
            <a href={e.acf.link} target='_blank' key={e.id}>
                <img alt="saradnik" src={ e.acf.image } className={ style.image } />
            </a>
        ))}
    </>)
}

export default SideBaner

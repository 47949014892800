import React from "react"
import styles from "./MobileAdditionalNav.module.css"
import { useTranslation } from 'react-i18next'
import { Select } from "antd"



const MobileAdditionalNav = () => {
  const { i18n } = useTranslation("translation")
  const language = localStorage.getItem("lang")
  const { Option } = Select

  const changeLanguage = code => {
    i18n.changeLanguage( code )
    localStorage.setItem( "lang", code )
    window.location.reload()
  }

  return (
    <div>
      <Select
        onChange={ changeLanguage }
        defaultValue={ language || "" }
        className={ styles.languagesMenu }
        style={{ fontWeight: 700, color: "#000" }}
      >
        <Option value="">Ћирилица</Option>
        <Option value="sr">Latinica</Option>
        {/* <Option value="en">EN</Option> */}
      </Select>
    </div>
  )
}

export default MobileAdditionalNav

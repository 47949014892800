import React, { Fragment, useState, useEffect } from "react"
import { Router, LocationProvider, Redirect, globalHistory } from "@reach/router"

import AboutUsWp from "./components/AboutUsWp/AboutUsWp"
import Activities from "./pages/Activities/Activities"
import AllTitles from "./pages/AllTitles/AllTitles"
import BookOfMonth from "./components/UsersGuide/BookOfMonth"
import Contact from "./pages/Contact/Contact"
import Digitization from "./pages/Digitization/Digitization"
import DigitizedMagazine from "./components/DigitizedMagazine/DigitizedMagazine"
import FaceOfNoviSad from "./pages/FaceOfNoviSad"
import FAQPage from "./pages/FAQ"
import FooterRubrics from "./pages/FooterRubrics"
import Gallery from "./pages/Gallery/Gallery"
import LocationAndWorkTime from "./pages/LocationAndWorkTime"
import LocationGroupList from "./components/LocationGroupList/LocationGroupList"
import LocationPage from "./components/LocationCard/LocationPage"
import MobileFooter from "./components/Footer/MobileFooter"
import MobileHeader from "./components/Header/MobileHeader"
import MobileIndex from "./pages/MobileIndex"
import MobileSearchResults from "./components/SearchResults/MobileSearchResults"
import MobileSingleActivity from "./pages/Activities/MobileSingleActivity"
import MostReadBooks from "./components/UsersGuide/MostReadBooks"
import NewBooks from "./components/UsersGuide/NewBooks"
import OurMagazines from "./components/OurMagazines/OurMagazines"
import Prices from "./pages/Prices"
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
import ProcuredTitles from "./components/UsersGuide/ProcuredTitles"
import PublicProcurement from "./components/PublicProcurement/PublicProcurement"
import Recommended from "./components/UsersGuide/Recommended"
import Services from "./pages/ServicesPage"
import SingleBook from "./components/UsersGuide/SingleBook"
import SingleGallery from "./pages/Gallery/SingleGallery"
import UserGuide from "./pages/UserGuide/UserGuide"
import WorkInProgress from "./components/WorkInProgress/WorkInProgress"
import './i18n'
import { useTranslation } from 'react-i18next'
import Notifications from "./pages/Notifications/Notifications"



function NestedRoute(props) {
  return props.children
}

function AppMobile() {
  const splitPath = window.location.pathname.split("/")
  const tempSlug = splitPath.slice(-1)[0]
  let activeSlug = null

  const { i18n } = useTranslation("translation")
  if (window.localStorage.getItem('lang') === null) {
    i18n.changeLanguage('')
    window.localStorage.setItem('lang', '')
  }

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        document.getElementById("root").scrollTo(0,0)
      }
    })
  }, [])

  if (tempSlug) {
    switch (tempSlug) {
      case "zaposleni":
        activeSlug = "employee"
        break
      case "sluzbe":
        activeSlug = "department"
        break
      case "ogranci":
        activeSlug = "branch"
        break
      default:
        activeSlug = "ask"
    }
  }

  const [activeContactItem, setActiveContactItem] = useState(activeSlug)

  const fetchContactOnClick = key => {
    setActiveContactItem(key)
  }

  return (
    <Fragment>

      <LocationProvider>
        {context => (
          <MobileHeader
            location={context.location}
            handleContactClick={key => {
              fetchContactOnClick(key)
            }}
          />
        )}
      </LocationProvider>

      <Router primary={false}>
        {/* LANDING PAGE */}
        <MobileIndex path="/" />

        {/* LOKACIJE I RADNO VREME */}
        <LocationAndWorkTime path="lokacije" />

        {/* DIGITALIZACIJA */}
        <NestedRoute path="digitalizacija">
          <NestedRoute path="/zavicajna-zbirka">
            <Digitization mobile={true} path="/:id" />
          </NestedRoute>
          <Digitization mobile={true} path="/:id" />
          <DigitizedMagazine path="/nasi-casopisi/:id" mobile={true} />
          <OurMagazines
            mobile={true}
            path="/nasi-casopisi/:id/prikaz-svih-brojeva"
          />
        </NestedRoute>

        {/* KNJIGA */}
        <SingleBook path="/knjiga/:id" />

        {/* NASLOVI */}
        <AllTitles path="/naslovi">
          <MostReadBooks  path="najcitaniji-naslovi" category='najcitaniji-naslovi'/>
          <NewBooks  path="novi-naslovi" category='nove-knjige'/>
          <Recommended  path="preporuka-bibliotekara" category='preporuka-bibliotekara'/>
          <BookOfMonth path="knjiga-meseca" category="knjiga-meseca"/>
          <ProcuredTitles path="/prinovljeni-naslovi" category="prinovljeni-naslovi"/>
        </AllTitles>

        {/* AKTIVNOSTI */}
        <NestedRoute path="aktivnosti">
					<Activities path="/" />
          <MobileSingleActivity mobile={true} path=":id" />
        </NestedRoute>

        {/* O NAMA */}
        <Redirect from="o-nama" to="/o-nama/o-biblioteci" noThrow />
        <NestedRoute path="/o-nama">
          <AboutUsWp mobile={true} path="/:id" />
          <AboutUsWp mobile={true} path="/organizacija/:id" />
          <AboutUsWp mobile={true} path="/projekti/:id" />
          <AboutUsWp mobile={true} path="/tradicionalne-manifestacije/:id" />
          <LocationGroupList mobile={true} path="/mreza-ogranaka/:id" />
          <LocationPage path={"/mreza-ogranaka/:id/:name"} />
          <PublicProcurement mobile={true} path="/javne-nabavke" />
        </NestedRoute>

        {/* FAQ */}
        <FAQPage path="vodic-za-korisnike/cesto-postavljana-pitanja" />

        {/* VODIC ZA KORISNIKE */}
        <NestedRoute path="vodic-za-korisnike">
          <UserGuide path="/:category" />
          <Services path="/usluge" />
        </NestedRoute>

        {/* GALERIJA */}
        <NestedRoute path="galerija">
          <Gallery mobile={true} path="/" />
          <SingleGallery path=":id" />
        </NestedRoute>

        {/* KONTAKT */}
        <Redirect from="kontakt" to="kontakt/pitajte-bibliotekara" noThrow />
        <NestedRoute path="kontakt">
          <Contact
            path="/:id"
            activeItem={activeContactItem}
            mobileContactPage={true}
          />
        </NestedRoute>

        {/* CENOVNIK */}
        <Prices path="cenovnik" />

        {/* KATALOG */}
        <MobileSearchResults path="katalog" />

        {/* PREGRAGA */}
        <MobileSearchResults path="pretraga" />

        {/* LICE NOVOG SADA */}
        <Redirect
          from="lice-novog-sada"
          to="/lice-novog-sada/narodi-u-novom-sadu/o-narodima-u-novom-sadu"
          noThrow
        />
        <NestedRoute path="lice-novog-sada">
          <FaceOfNoviSad path="/:id/:id" />
        </NestedRoute>

        {/* INFORMACIJE */}
        <NestedRoute path="/obavestenja" >
          <Notifications path="/" />
        </NestedRoute>
        
        {/* IZDAVASTVO */}
        <NestedRoute path="/izdavastvo" >
          <FooterRubrics path="/:pod_rubrika" />
        </NestedRoute>

        {/* ZA BIBLIOTEKARE */}
        <NestedRoute path="/za-bibliotekare" >
          <FooterRubrics path="/:pod_rubrika" />
        </NestedRoute>

        {/* POLITIKA PRIVATNOSTI */}
        <PrivacyPolicy path="politika-privatnosti" />

        <WorkInProgress default />

      </Router>

      <MobileFooter />

    </Fragment>
  )
}

export default AppMobile

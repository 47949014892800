import React, { useState, useCallback, useEffect } from 'react'
import styles from "./BookOfMonth.module.css"
import { Carousel, Button } from 'antd'

import useFetch from '../../../hooks/useFetch'
import MobileBookOfMonth from './MobileBookOfMonth'

const monthsSerbian = [
	"Јануар",
	"Фебруар",
	"Март",
	"Април",
	"Мај",
	"Јун",
	"Јул",
	"Август",
	"Септембар",
	"Октобар",
	"Новембар",
	"Децембар"
]

export default ({ url }) => {
	const [booksOfThemonth, setbooksOfThemonth] = useState( [] )
	const [ref, setRef] = useState( null )

	const carousel = useCallback( ref => setRef( ref ) , [] )
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	const { response } = useFetch(url)


	useEffect(() => {
		const modifiedArray = response.map( e => e.acf )
		
		modifiedArray.sort(
			(a, b) => (b.year - a.year) || (monthsSerbian.indexOf(b.month) - monthsSerbian.indexOf(a.month))
		   );
		   
		setbooksOfThemonth( modifiedArray )
	},[response])

    return (
        <>
			<Carousel
				ref={ carousel }
				dots={ false }
				adaptiveHeight={ true }
				variableWidth={ true }
			>

				{ booksOfThemonth.map(( book, i ) => {
					if (book.book) {
						return (
							<MobileBookOfMonth
								key={i}
								id={ book.book.ID }
								title={ book.book.post_title }
								author={ book.book.acf.author }
								imgSrc={ book.book.acf.image ? book.book.acf.image.sizes.medium : ""}
								info={ book.book.acf.about }
								bckDrop={ book.month.substring(0, 3) }
								ganre={ book.book.acf.genre }
							/>
						)
					}
				}
				)}

            </Carousel>
			<div className={ styles.actions }
				style={{
					display: window.screen.width >= 600 ? "flex" : "none",
					justifyContent: "center"
				}}	
			>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ prev }
						shape="circle"
						icon="left"
					/>
				</span>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ next }
						shape="circle"
						icon="right"
					/>
				</span>
			</div>
        </>
    )
}
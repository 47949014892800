import React, { useState, useCallback, Fragment } from "react"
import styles from "./NewBooks.module.css"
import { Carousel, Row, Col, Button, Skeleton } from "antd"
import chunk from "lodash.chunk"

import NewBook from "./NewBook"
import useFetch from "../../../hooks/useFetch"



export default ({ url }) => {
	let counter = 0

	const [ref, setRef] = useState( null )
	const [currentSelected, setCurrentSelected] = useState( 1 )

	const { response, isLoading } = useFetch( url )

	const carousel = useCallback( ref => setRef(ref), [] )
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	function setActive( id ) { setCurrentSelected( id ) }

	function spanRange( id ) {
		return currentSelected === id ? 9 : 3
	}

	return (
		<>
			<Skeleton active={ true } loading={ isLoading }/>


			<Fragment>
			
				<Carousel
					ref={ carousel }
					dots={ false }
					style={{ height: 450 }}
					beforeChange={ () => setCurrentSelected( 0 ) }
				>
					
					{response.map( row => {
						return chunk( row.acf.book, 5 ).map(( book, i ) => {
							return (
								<Fragment>
									<Row
										gutter={24}
										style={{
											display: "flex",
											justifyContent: book.length > 2 ? "center" : "flex-start"
										}}
									>
										
										{book.map(( b, id ) => {
											counter++
											return (
												<Col key={ id } span={ spanRange( id ) }>
													<NewBook
														id={ b.ID }
														title={ b.post_title }
														author={ b.acf.author }
														imgSrc={ b.acf.image? b.acf.image.sizes.medium : ""}
														info={ b.acf.about }
														setActive={ setActive }
														bckDrop={ counter }
														i={ id }
														active={ currentSelected === id }
														ganre={ b.acf.genre }
													/>
												</Col>
											)
										})}

									</Row>
								</Fragment>
							)
						})
					})}

				</Carousel>
				
				<div className={ styles.actions }>
					<span>
						<Button
							onClick={ prev }
							className={ styles.actionItem }
							shape="circle"
							icon="left"
						/>
					</span>
					<span>
						<Button
							onClick={ next }
							className={ styles.actionItem }
							shape="circle"
							icon="right"
						/>
					</span>
				</div>
			</Fragment>
		</>
	)
}

import React from "react"
import { Row, Button } from "antd"
import styles from "./CustomButton.module.css"

const CustomButton = ({ children, click, disabled, isLoading }) => {
  return (
    <Row type="flex" justify="center" className={styles.customButtonWrapper}>
      <Button
        onClick={click}
        className={`${styles.customButton}`}
        disabled={disabled}
        loading={isLoading}>
        {children}
      </Button>
    </Row>
  )
}

export default CustomButton

import React, { useState, useCallback } from "react"
import { Row, Col, Carousel, Skeleton, Typography, Button } from "antd"
import { navigate } from "@reach/router"
import useFetch from "../../../hooks/useFetch"
import { useTranslation } from 'react-i18next'
import textTranslation from "../../../textTranslation"

import styles from "./MobileNextActivites.module.css"

const { Title, Paragraph } = Typography

const MobileNextActivities = () => {
  const [ref, setRef] = useState( null )
  const { t } = useTranslation("translation")
  const [pos, setPos] = useState( null )
  const [activeSlide, setActiveSlide] = useState( 0 )

  const { response, isLoading } = useFetch("/wp-json/gbns/v2/activities/filtered")

  const carousel = useCallback( ref => { setRef( ref ) }, [])
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

  const carouselSettings = {
    dots: false,
    draggable: true,
    centerMode: true,
    centerPadding: "60px",
    swipeToSlide: true,
    beforeChange: (current, next) => {
      setActiveSlide(next)
    }
  }

  return (
    <Row>
      
      <Col span={ 20 }>
        <Title level={ document.body.offsetWidth <= 576 ? 3 : 2 } className={ styles.heading }>
          {t('translation:nextActivities:aktivnosti-koje-slede')}
        </Title>
      </Col>

      <Skeleton active={ true } loading={ isLoading } paragraph={{ rows: 10 }}/>
      { !isLoading && response && response.length && (
        <Col span={ 24 }>
          <div className={ styles.contentWrapper }>
            
            <Carousel ref={ carousel } { ...carouselSettings }>
              { response.map(( activity, index ) => {
                return (
                  <div
                    onMouseDown={ event => setPos( event.clientX ) }
                    onMouseUp={ event => { event.clientX === pos && navigate(`/aktivnosti/${activity.ID}`) }}
                    className={ `single-activity ${styles.singleActivityWrapper} ${index === activeSlide ? "active" : "inactive"}` }
                    key={ index }
                  > 
                    <div
                      className={ `${styles.activityImageWrapper} activity-image-wrapper` }
                      style={{ backgroundImage: `url(${ activity.acf.image ? activity.acf.image.sizes.large : "" })` }}
                    />
                    <div className={ `${styles.activityDescriptionWrapper} activity-description-wrapper`}>
                      <Title ellipsis={{ rows: 2 }}>
                        { textTranslation(activity.post_title) }
                      </Title>
                      <div className={ styles.dateTime }>
                        <p>{ textTranslation(activity.acf.date) }</p>
                        <h2>{ textTranslation(activity.acf.time) }</h2>
                      </div>
                      <div className={ styles.activityCaption }>
                        <Paragraph ellipsis={{ rows: 2 }}>
                          { textTranslation(activity.acf.description) }
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Carousel>
            
            { window.screen.width >= 576 && <div className={ styles.actions }>
              <span>
                <Button className={ styles.actionItem } onClick={ prev } shape="circle" icon="left" />
              </span>
              <span>
                <Button className={ styles.actionItem } onClick={ next } shape="circle" icon="right"/>
              </span>
            </div>}
          </div>
        </Col>
      )}
    </Row>
  )
}

export default MobileNextActivities
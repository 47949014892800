import React, { useState, useEffect } from "react"
import { Row, Col, Skeleton, List, Typography } from "antd"
import useFetch from "../../hooks/useFetch"
import styles from "./LocationGroup.module.css"
import LocationCard from "../LocationCard/LocationCard"
import WithSider from "../../layouts/WithSider"
import AboutSidebar from "../AboutSidebar/AboutSidebar"
import PopularActivities from "../PopularActivities/PopularActivities"
import { useTranslation } from 'react-i18next'
import convert from 'latin-to-serbian-cyrillic'

const { Item } = List
const { Title } = Typography

const LocationGroupList = props => {
  const [locationId, setLocationId] = useState(null)
  const [initLoading, setInitLoading] = useState(true)
  const [locationData, setLocationData] = useState([])

  const { t } = useTranslation("translation")
  const { response } = useFetch(
    "/wp-json/wp/v2/location_groups",
    {
      per_page: 30,
      page: 1
    }
  )

  const { id } = props
  useEffect(() => {
    setInitLoading(false)
    if (id === "gradski") {
      setLocationId("Градски огранци")
    } else if (id === "prigradski") {
      setLocationId("Приградски огранци")
    } 
  }, [id])

  useEffect(() => {
    if (id === "gradski") {
      setLocationId("Градски огранци")
    } else if (id === "prigradski") {
      setLocationId("Приградски огранци")
    }
  }, [id])

  useEffect(() => {
    let filteredData = filterLocations(response)
    setLocationData(filteredData)
  }, [response, locationId])

  function filterLocations(data) {
    return data.filter(el => el.title.rendered === locationId)
  }

  return (
    <WithSider
    siderTitle={t('translation:locationGroupList:o-nama')}
    sider={<AboutSidebar uri={props}/>}
    content={
      <Row>
        <Col>
          <Title className={styles.mainTitle} level={2}>
          {t(`translation:locationGroupList:${id}`)}
          </Title>
          {locationData[0] && (
            <List
              className={styles.itemList}
              grid={{ gutter: { xs: 20, sm: 20, md: 50 }, xs: 2, sm: 3, md: 4 }}
              loading={initLoading}
              dataSource={locationData[0].acf.locations}
              renderItem={item => (
                <Skeleton avatar title={false} loading={initLoading} active>
                  <Item>
                    <LocationCard
                      data={item}
                    />
                  </Item>
                </Skeleton>
              )}
            />
          )}
        </Col>
      </Row>
    }
    moreContent={<PopularActivities mobile={props.mobile} />}
  />
  )
}

export default LocationGroupList

import React, { useState, useCallback } from "react"
import { Modal, Row, Col, Button, Carousel, Icon } from "antd"
import { Parser } from "html-to-react"
import size from "../../hooks/useWindowSize"
import textTranslation from '../../textTranslation'

import "./Carousel.css"
import styles from "./GalleryDetailsModal.module.css"

function GalleryDetailsModal({ visible, onCancel, data, selectedItem }) {
  const currentIndex = data.acf.gallery.findIndex(
    item => item.id === selectedItem.id
  )
  const countItems = data.acf.gallery.length
  const [activeItem, setActiveItem] = useState(currentIndex)
  const [activeIndex, setActiveIndex] = useState(currentIndex)
  const htmlToReact = new Parser()

  function selectItemByIndex(index) {
    setActiveIndex(index)
    setActiveItem(data.acf.gallery.find((_, i) => i === index))
  }

  const [ref, setRef] = useState(null)
  const carousel = useCallback(ref => {
    setRef(ref)
  }, [])

  function next() {
    ref.slick.slickNext()
  }

  function prev() {
    ref.slick.slickPrev()
  }

  function calcPrevIndex(i) {
    return i === 0 ? data.acf.gallery.length - 1 : i - 1
  }

  function calcNextIndex(i) {
    return i === data.acf.gallery.length - 1 ? 0 : i + 1
  }

  function returnType(index, active) {
    const obj = data.acf.gallery[calcPrevIndex(index)]
    return obj.mime_type.includes("video") ? (
      <video
        width={ active ? 100 : 80 }
        height={ active ? 100 : 80 }
        className={ styles.item }
        controls>
        <source src={ obj.url } type="video/mp4" />
      </video>
    ) : (
      <img
        alt="test"
        width={ active ? 100 : 80 }
        height={ active ? 100 : 80 }
        src={ obj.url }
      />
    )
  }

  return (
    <Modal
      visible={ visible }
      onCancel={ onCancel }
      footer={ null }
      width={ 1200 }
      className={`${styles.galleryModal} closeButtonNone`}
      bodyStyle={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255, 0.95)",
        paddingTop: size.width > 991 ? "100px" : "40px",
        overflow: size.width > 991 ? "hidden" : "auto"
      }}>
      <Row className={ styles.carouselRow }>
        <Col lg={{ span: 14, offset: 2 }}>
          <div className="carousel-wrapper single-gallery-wrapper">
            <Carousel
              ref={ carousel }
              dots={ false }
              afterChange={ selectItemByIndex } >
              { data.acf.gallery.map(v => {
                return v.mime_type.includes("video") ? (
                  <video kay={ v.id } className={ styles.item } controls>
                    <source src={ v.url } type="video/mp4" />
                  </video>
                ) : (
                  <div className={`${styles.singlePage}`} key={ v.id } >
                    <img  alt="test" width={ 80 } src={ v.url } />
                  </div>
                )
                })}
            </Carousel>
            <Button
              className={ styles.carouselControll }
              onClick={ prev }
              shape="circle"
              icon="left">
            </Button>
            <Button
              className={ styles.carouselControll }
              onClick={ next }
              shape="circle"
              icon="right">
            </Button>
          </div>
        </Col>
        <Col lg={{ span: 5, offset: 1 }}>
          <div className={ styles.detailsContainer }>
            <Icon 
              type="close"
              className={ styles.modalCloseButton }
              onClick={ onCancel } />
            <div className={ styles.imagesContainer }>
              { returnType(calcPrevIndex(activeIndex)) }
              { returnType(activeIndex, true) }
              { returnType(calcNextIndex(activeIndex)) }
            </div>
            <p className={ styles.count }>{`${activeIndex + 1}/${countItems}`}</p>
            <h3 className={ styles.modalTitle }>{ textTranslation(data.title.rendered) }</h3>
            <div className={ styles.modalCaption }>
              { htmlToReact.parse(textTranslation(data.excerpt.rendered)) }
            </div>
          </div>
        </Col>  
      </Row>
    </Modal>
  ) 
}
export default GalleryDetailsModal
import React, { useRef, useState, useEffect } from "react"
import {
  Row,
  Col,
  Input,
  Radio,
  Checkbox,
  DatePicker,
  Icon,
  Button,
  Modal
} from "antd"

import moment from "moment"
// import convert from 'cyrillic-to-latin'
import convert from 'latin-to-serbian-cyrillic'

import SiderBottomLinks from "../SiderBottomLinks/SiderBottomLinks"
import { useTranslation } from 'react-i18next'

import styles from "../SidebarFilters/SidebarFilters.module.css"

const { Search } = Input

const GallerySearch = props => {
  const { t } = useTranslation("translation")
  const searchTermInput = useRef(null)
  const radioInput = useRef(null)
  const checkboxInput1 = useRef(null)
  const checkboxInput2 = useRef(null)
  const dateFrom = useRef(null)
  const dateTo = useRef(null)

  const [beginningDate, setBeginningDate] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [galleryFormat, setGalleryFormat] = useState([])
  const [galleryCategory, setGalleryCategory] = useState([])
  const [filtersActive, setFiltersActive] = useState(false)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [mobileFilters, setMobileFilters] = useState(false)
  const [moreContentLoaded, setMoreContentLoaded] = useState(false)
  const [filteredStats, setfilteredStats] = useState({
    "category": {
      "biblioteka": 0,
      "programi": 0,
      "događaji": 0,
      "dečije odeljenje": 0
    },
    "type": {
      "image": 0,
      "video": 0
    }
  })

  const apiUrl = process.env.REACT_APP_API_URL

  const {
    toggleFilter,
    resultsOfFilter,
    closeFilters,
    currentPage,
    setCurrentPage,
    setTotalItems,
    setTotalPages,
    loadMoreStatus,
    toggleLoadMore,
    setResultsLoading,
    mobile
  } = props

  const { 
    category: { библиотека, програми, догађаји, "дечје одељење": dečije_odeljenje },
    type: { image, video }
  } = filteredStats

  const catMap = {
    'Biblioteka': 'Библиотека',
    'Programi': 'Програми',
    'Događaji': 'Догађаји',
    'Dečije odeljenje': 'Дечје одељење'
  }

  let urlToPass = null
  let currentActivePage = currentPage
  let term = props.searchTerm

  useEffect(() => {
    if (mobileFilters) {
      createUrlToFetch(currentActivePage)
      setMobileFilters(false)
    }
  }, [mobileFilters, createUrlToFetch, currentActivePage])

  useEffect(() => {
    if (loadMoreStatus === true) {
      setMoreContentLoaded(true)
      createUrlToFetch(currentActivePage)
      toggleLoadMore(false)
    }

    if (filtersActive) {
      setResultsLoading(true)

      if (!mobile) {
        setCurrentPage(1)
        currentActivePage = 1
        createUrlToFetch(currentActivePage)
      }
      setFiltersActive(false)
    }
  }, [filtersActive, filtersApplied, loadMoreStatus, moreContentLoaded])

  useEffect(() => {
    if (
      !searchTerm &&
      !fromDate &&
      !toDate &&
      !galleryFormat.length &&
      !galleryCategory.length
    ) {
      currentActivePage = 1
      createUrlToFetch(currentActivePage)
    }
  }, [searchTerm == null, fromDate, toDate, galleryFormat, galleryCategory])

  function onStartChange(value, dateString) {
    setBeginningDate(value)
  }

  function disabledDate(endValue) {
    if (!endValue || !beginningDate) {
      return false
    }

    return endValue.valueOf() <= beginningDate.valueOf()
  }

  function handleGallerySearch(value) {
    setFiltersActive( true )
    setFiltersApplied( true )
    setSearchTerm( value )
  }

  function handleDatePickerDateFrom(date, dateString) {
    if ( !!dateString ) {
      const dateFrom = moment( dateString ).format( "DD/MM/YYYY" )
      urlToPass = `&from_date=${dateFrom}`
      setFromDate( dateFrom )
    } else {
      urlToPass = null
      setFromDate( null )
    }
    setFiltersActive( true )
    setFiltersApplied( true )
  }

  function handleDatePickerDateTo(date, dateString) {
    if ( !!dateString ) {
      const dateTo = moment(dateString).format("DD/MM/YYYY")
      urlToPass = `&to_date=${dateTo}`
      setToDate( dateTo )
    } else {
      urlToPass = null
      setToDate( null )
    }

    setFiltersActive( true )
    setFiltersApplied( true )
  }

  function handleDateRadioButtonChange(event) {
    const chosenTimeRange = event.target.value

    const startOfWeek = moment()
      .startOf("week")
      .format("DD/MM/YYYY")
    const endOfWeek = moment()
      .endOf("week")
      .format("DD/MM/YYYY")

    const startOfMonth = moment()
      .startOf("month")
      .format("DD/MM/YYYY")
    const endOfMonth = moment()
      .endOf("month")
      .format("DD/MM/YYYY")

    const today = moment().format("DD/MM/YYYY")

    switch (chosenTimeRange) {
      case "today":
        urlToPass = `?from_date=${today}&to_date=${today}`
        setFromDate( today )
        setToDate( today )
        break
      case "thisWeek":
        urlToPass = `?from_date=${startOfWeek}&to_date=${endOfWeek}`
        setFromDate( startOfWeek )
        setToDate( endOfWeek )
        break
      case "thisMonth":
        urlToPass = `?from_date=${startOfMonth}&to_date=${endOfMonth}`
        setFromDate( startOfMonth )
        setToDate( endOfMonth )
        break
      default:
        urlToPass = ""
        setFromDate( null )
        setToDate( null )
    }
    setFiltersActive( true )
  }

  function handleGalleryFormatChoice( value ) {
    if (value.length === 1) {
      setGalleryFormat( value )
    } else {
      setGalleryFormat( [] )
    }
    setFiltersActive( true )
  }

  function handleGalleryCategoriesChoice( value ) {
    if (value.length >= 1 && value.length < 4) {
      setGalleryCategory( value )
    } else {
      setGalleryCategory( [] )
    }
    setFiltersActive( true )
  }

  function createUrlToFetch( currentActivePage ) {
    let searchQuery = null
    let beginningDate = null
    let endDate = null
    let galleryFormats = []
    let galleryCategories = []

    if ( searchTerm ) {
      // searchQuery = `&search_term=${searchTerm}`
      searchQuery = `&search_term=${localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr' ? convert(searchTerm) : searchTerm}`
    }

    if ( fromDate ) {
      beginningDate = `&from_date=${fromDate}`
    }

    if ( toDate ) {
      endDate = `&to_date=${toDate}`
    }

    if ( galleryFormat.length ) {
      if ( galleryFormat.length === 1 ) {
        if ( galleryFormat[0] === "image" ) {
          galleryFormats = "&in_format=image"
        } else {
          galleryFormats = "&in_format=video"
        }
      }
    }

    if ( galleryCategory.length ) {
      galleryCategories = `&in_category=${galleryCategory.map(c => catMap[c])}`
    }

    let fetchUrl = `${apiUrl}/wp-json/gbns/v2/galleries/filtered?per_page=10&page=${currentActivePage}&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`

    if ( term !== '' && term !== undefined ) {
      fetchUrl = `${apiUrl}/wp-json/gbns/v2/galleries/filtered?per_page=10&page=${currentActivePage}&search_term=${term}&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`
    }

    let filteredStatsQuery = null

    if (
      searchQuery ||
      fromDate ||
      toDate ||
      galleryFormat.length ||
      galleryCategories.length
    ) {

      const query = `${searchQuery ? searchQuery : ""}${
        beginningDate ? beginningDate : ""
        }${endDate ? endDate : ""}${galleryFormats ? galleryFormats : ""}${
        galleryCategories ? galleryCategories : ""
        }`
        fetchUrl = `${fetchUrl}${query}&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`
        filteredStatsQuery = query
    }

    const urlFilter = !!filteredStatsQuery ?
      // `${apiUrl}/wp-json/gbns/v2/galleries/filtered/stats?${filteredStatsQuery}&lang=${localStorage.getItem('lang')}` :
      // `${apiUrl}/wp-json/gbns/v2/galleries/filtered/stats?lang=${localStorage.getItem('lang')}`
      `${apiUrl}/wp-json/gbns/v2/galleries/filtered/stats?${filteredStatsQuery}` :
      `${apiUrl}/wp-json/gbns/v2/galleries/filtered/stats`

    fetch(fetchUrl)
      .then(res => {
        setTotalItems(parseInt(res.headers.get("X-WP-Total")))
        setTotalPages(parseInt(res.headers.get("X-WP-TotalPages")))
        return res.json()
      })
      .then( res => {
        toggleFilter( true )
        resultsOfFilter( res )
        return fetch( urlFilter )
      })
      .catch(error => {
        console.log( error )
        Modal.error({
          title:
          t('translation:gallerySearch:doslo-je-do-greske-molimo-osvezite-stranicu-i-pokusajte-ponovo'),
          onOk() { }
        })
      })
      .finally(() => setResultsLoading( false ))
      .then(res => {
        return res.json()
      })
      .then(res => {
        setfilteredStats( res )
      })
      .catch(err => { console.error( err )})
  }

  const toggleMobileFilters = () => {
    setCurrentPage( 1 )
    window.setTimeout(() => {
      setMobileFilters( true )
      closeFilters()
    }, 0)
  }

  const resetFilters = () => {
    setCurrentPage( 1 )
    setSearchTerm( null )
    setFromDate( null )
    setToDate( null )
    setGalleryFormat( [] )
    setGalleryCategory( [] )

    //to actually reset the
    searchTermInput.current.input.state.value = null
    radioInput.current.state.value = "allDates"
    dateFrom.current.picker.state.value = undefined
    dateTo.current.picker.state.value = undefined
    checkboxInput1.current.state.value = []
    checkboxInput2.current.state.value = []
  }


  return (
    <Row className={`${styles.filtersOverlay} ${styles.filtersWrapper}`}>
      <Col span={ 24 } className={ styles.title }>
        <div className={ styles.titleWrapper }>
          <h1>{ t('translation:gallerySearch:galerija') }</h1>
          <Icon
            type="close"
            className={ styles.btnCloseFilters }
            style={{ fontSize: "28px", color: "#071013", fontWeight: 700 }}
            onClick={ closeFilters }
          />
        </div>
      </Col>
      <Col span={ 24 }>
        <Search
          ref={ searchTermInput }
          className={`search-activities ${styles.filtersOverlaySearch}`}
          placeholder={ t('translation:gallerySearch:pretrazite-galeriju') }
          onSearch={ handleGallerySearch }
          onChange={ event => {
            setSearchTerm( event.target.value )
          }}
        />
      </Col>
      <Col span={ 24 }>
        <div className={ styles.searchGroupName }>
          <span className={ styles.searchCategory }>{ t('translation:gallerySearch:datum') }</span>
        </div>
        <div className={ styles.searchGroup }>
          <Radio.Group
            ref={ radioInput }
            buttonStyle="outline"
            name="galleriesDates"
            defaultValue="allDates"
            onChange={ event => handleDateRadioButtonChange(event) }>
            <Radio value="allDates">{ t('translation:gallerySearch:svi-datumi') }</Radio>
            <Radio value="today">{ t('translation:gallerySearch:danas') }</Radio>
            <Radio value="thisWeek">{ t('translation:gallerySearch:ove-nedelje') }</Radio>
            <Radio value="thisMonth">{ t('translation:gallerySearch:ovog-meseca') }</Radio>
          </Radio.Group>
        </div>
        <div className={ styles.datePicker }>
          <span className={ styles.from }>{ t('translation:gallerySearch:od') }</span>
          <DatePicker
            placeholder={ t('translation:gallerySearch:datum') }
            ref={ dateFrom }
            // onChange={onStartChange}
            style={{ color: "red" }}
            name="galleriesDateFrom"
            onChange={ (date, dateString) => {
              onStartChange()
              handleDatePickerDateFrom(date, !!dateString ? dateString : null)
            }}
          />
        </div>
        <div className={ styles.datePicker }>
          <span className={ styles.to }>{ t('translation:gallerySearch:do') }</span>
          <DatePicker
            placeholder={ t('translation:gallerySearch:datum') }
            ref={ dateTo }
            disabledDate={ disabledDate }
            style={{ color: "red" }}
            name="galleriesDateUntil"
            onChange={ (date, dateString) =>
              handleDatePickerDateTo(date, !!dateString ? dateString : null)
            }
          />
        </div>
      </Col>
      <Col span={ 24 }>
        <div className={ styles.searchGroupName }>
          <span className={ styles.searchCategory }>{ t('translation:gallerySearch:format') }</span>
        </div>
        <div className={ styles.searchGroup }>
          <Checkbox.Group
            ref={ checkboxInput1 }
            defaultValue={ [] }
            name="galleryFormats"
            onChange={ checkValue => handleGalleryFormatChoice( checkValue )}
          >
            <Checkbox value="image">
            { t('translation:gallerySearch:fotografije') }
              <span className={ styles.count }>{ image }</span>
            </Checkbox>
            <Checkbox value="video">
            { t('translation:gallerySearch:video-zapis') }
              <span className={ styles.count }>{ video }</span>
            </Checkbox>
          </Checkbox.Group>
        </div>
      </Col>
      <Col span={ 24 }>
        <div className={ styles.searchGroupName }>
          <span className={ styles.searchCategory }>{ t('translation:gallerySearch:filter') }</span>
        </div>
        <div className={ styles.searchGroup }>
          <Checkbox.Group
            ref={ checkboxInput2 }
            name="galleryCategories"
            onChange={ checkValue => handleGalleryCategoriesChoice(checkValue) }>
            <Checkbox value="Biblioteka">
            { t('translation:gallerySearch:biblioteka') }
              <span className={ styles.count }>{ библиотека }</span>
            </Checkbox>
            <Checkbox value="Programi">
            { t('translation:gallerySearch:programi') }
              <span className={ styles.count }>{ програми }</span>
            </Checkbox>
            <Checkbox value="Događaji">
            { t('translation:gallerySearch:dogadjaji') }
              <span className={ styles.count }>{ догађаји }</span>
            </Checkbox>
            <Checkbox value="Dečije odeljenje">
            { t('translation:gallerySearch:decije-odeljenje') }
              <span className={ styles.count }>{ dečije_odeljenje }</span>
            </Checkbox>
          </Checkbox.Group>
        </div>
      </Col>
      <Col span={ 24 }>
        <Button 
          onClick={ resetFilters }
          className={ styles.reset }
        >
          { t('translation:gallerySearch:resetujte-filtere') }
        </Button>
      </Col>
      { mobile && (
        <React.Fragment>
          <Col span={ 24 }>
            <Button
              onClick={ toggleMobileFilters } 
              className={ styles.btnApplyFilters }
              block
            >
              { t('translation:gallerySearch:primenite-filtere') }
            </Button>
          </Col>
          <Col span={ 24 }>
            <SiderBottomLinks />
          </Col>
        </React.Fragment>
      )}
    </Row>
  )
}

export default GallerySearch
import React from "react"
import WithSider from "../../layouts/WithSider"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import DigitizationSidebar from "../../components/DigitizadionSidebar/DigitizationSidebar"
import BookList from "../../components/BookList/BookList"
import DigitizedMagazineList from "../../components/DigitizedMagazineList/DigitizedMagazineList"
import PostcardList from "../../components/PostcardList/PostcardList"
import WpPage from "../../components/WpPage/WpPage"
import UsefulLinks from "../../components/UsefulLinks/UsefulLinks"
import { useTranslation } from 'react-i18next'

function Digitization({id, mobile}) {
	const { t } = useTranslation("translation")

  function renderContent() {
    switch (id) {
      case "stampane-do-1867":
        return <BookList category={id} />
      case "stampane-od-1868":
        return <BookList category={id} />
      case "novine-casopisi":
        return <DigitizedMagazineList />
      case "razglednice":
        return <PostcardList />
      case "digitalni-omladinski-centar":
        return <WpPage wrapperClass={id} id={id}/>
      case "korisni-linkovi":
        return <UsefulLinks mobile={mobile} />
      default: 
        return <BookList category={id} />
    }
  }
  
  return (
    
    <WithSider
      siderTitle={t('translation:mainNav:digitalizacija')}
      sider={<DigitizationSidebar id={id}/>}
      content={renderContent()}
      moreContent={<PopularActivities mobile={mobile} />}
    />
  )
}

export default Digitization

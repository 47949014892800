import React, { useEffect, useState } from 'react'
import { Row, Typography, Select } from "antd"
import SingleBook from './SingleBook'
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import style from "./BookOfMonth.module.css"

const { Option } = Select
const { Title } = Typography
const apiUrl = process.env.REACT_APP_API_URL
const objMonths = {
  "Јануар": 0,
  "Фебруар": 1,
  "Март": 2,
  "Април": 3,
  "Мај": 4,
  "Јун": 5,
  "Јул": 6,
  "Август": 7,
  "Септембар": 8,
  "Октобар": 9,
  "Новембар": 10,
  "Децембар": 11
}

const BookOfMonth = () => {
  const { t } = useTranslation("translation")
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [booksOfMonth, setBooksOfMonth] = useState([])

  useEffect(() => {
    fetch(`${apiUrl}/wp-json/wp/v2/books_of_month?lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage('lang')}`)
      .then(response => response.json())
      .then(data => {
        let fetchedBooks = []
        data.forEach(e => {

          fetchedBooks.push({ date: new Date(e.acf.year, objMonths[e.acf.month]), title: e.title.rendered, book: e.acf.book })
        })

        fetchedBooks.sort(function (a, b) {
          return b.date - a.date;
        })

        setBooksOfMonth(fetchedBooks)
        setSelectedMonth(0)
      })
  }, [])

  return (
    <div>
      <Row type="flex">
        <Title
          className={ style.bom__select__title }
          level={ 3 }
          style={{ fontFamily: "Tinos" }}
        >
          {t('translation:bookOfMonth:knjiga-meseca')}
        </Title>
        {booksOfMonth.length !== 0 && (
          <Select
            style={{
              opacity: "0.6",
              color: "#071013",
              fontFamily: "Tinos"
            }}
            className={style.bom__select}
            defaultValue={booksOfMonth[0].title}
            onChange={(value, key) => {

              setSelectedMonth(key.key)
              return booksOfMonth[key.key].title
            }}
          >
            {booksOfMonth.map((item, index) => {
              return (
                <Option
                  className={style.bom__select__option}
                  key={index}
                  value={item.title}
                >
                  {textTranslation(item.title)}
                </Option>
              )
            })
            }
          </Select>
        )}
      </Row>
      {booksOfMonth[selectedMonth] && (
        <SingleBook
          id={booksOfMonth[selectedMonth].book.ID}
          ofMonth={true}
        />
      )
      }
    </div>
  )
}

export default BookOfMonth
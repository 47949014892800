import React, { Fragment, useEffect } from "react"
import { Router, LocationProvider, Redirect, globalHistory } from "@reach/router"

import AboutUsWp from "./components/AboutUsWp/AboutUsWp"
import Activities from "./pages/Activities/Activities"
import AllTitles from "./pages/AllTitles/AllTitles"
import BookOfMonth from "./components/UsersGuide/BookOfMonth"
import Contact from "./pages/Contact/Contact"
import Digitization from "./pages/Digitization/Digitization"
import DigitizedMagazine from "./components/DigitizedMagazine/DigitizedMagazine"
import FaceOfNoviSad from "./pages/FaceOfNoviSad"
import FAQ from "./pages/FAQ"
import Footer from "./components/Footer/Footer"
import FooterRubrics from "./pages/FooterRubrics"
import Friends from "./components/Friends/Friends"
import Gallery from "./pages/Gallery/Gallery"
import Header from "./components/Header/Header"
import Index from "./pages/Index"
import LocationAndWorkTime from "./pages/LocationAndWorkTime"
import LocationGroupList from "./components/LocationGroupList/LocationGroupList"
import LocationPage from "./components/LocationCard/LocationPage"
import MostReadBooks from "./components/UsersGuide/MostReadBooks"
import NewBooks from "./components/UsersGuide/NewBooks"
import Notifications from "./pages/Notifications/Notifications"
import OurMagazines from "./components/OurMagazines/OurMagazines"
import Prices from "./pages/Prices"
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
import ProcuredTitles from "./components/UsersGuide/ProcuredTitles"
import PublicProcurement from "./components/PublicProcurement/PublicProcurement"
import Recommended from "./components/UsersGuide/Recommended"
import SearchResults from "./components/SearchResults/SearchResults"
import Services from "./pages/ServicesPage"
import SingleActivity from "./pages/Activities/SingleActivity"
import SingleBook from "./components/UsersGuide/SingleBook"
import SingleGallery from "./pages/Gallery/SingleGallery"
import UserGuide from "./pages/UserGuide/UserGuide"
import WorkInProgress from "./components/WorkInProgress/WorkInProgress"
import WorkNotFinished from "./components/WorkInProgress/WorkNotFinished"
import './i18n'
import { useTranslation } from 'react-i18next'

function NestedRoute(props) {
  return props.children
}


function AppDesktop() {

  const { i18n } = useTranslation("translation")
  if (window.localStorage.getItem('lang') === null) {
    i18n.changeLanguage('')
    window.localStorage.setItem('lang', '')
  }

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        window.scroll(0,0)
      }
    })
  }, [])

  return (
    <Fragment>

      <LocationProvider>
        {context => <Header location={context.location} />}
      </LocationProvider>

      <Router primary={false}>
        {/* LANDING PAGE */}
        <Index path="/" />

        {/* LOKACIJE I RADNO VREME */}
        <LocationAndWorkTime path="lokacije" />

        {/* DIGITALIZACIJA */}
        <NestedRoute path="digitalizacija">
          <NestedRoute path="/zavicajna-zbirka">
            <WorkNotFinished default/>
            <Digitization path="/:id" />
          </NestedRoute>
          <Digitization path="/:id" />
          <DigitizedMagazine path="/nasi-casopisi/:id" />
          <OurMagazines path="/nasi-casopisi/:id/prikaz-svih-brojeva" />
        </NestedRoute>

        {/* KNJIGA */}
        <SingleBook path="/knjiga/:id" />

        {/* NASLOVI */}
        <AllTitles path="naslovi" >
          <MostReadBooks path="najcitaniji-naslovi" category='najcitaniji-naslovi' />
          <NewBooks path="novi-naslovi" category='nove-knjige' />
          <Recommended path="preporuka-bibliotekara" category='preporuka-bibliotekara' />
          <BookOfMonth path="knjiga-meseca" category="knjiga-meseca/:id" />
          <ProcuredTitles path="prinovljeni-naslovi" category="prinovljeni-naslovi" />
        </AllTitles>

        {/* AKTIVNOSTI */}
        <NestedRoute path="aktivnosti">
          <Activities path="/" />
          <SingleActivity path=":id" />
        </NestedRoute>

        {/* O NAMA */}
        <Redirect from="o-nama" to="/o-nama/o-biblioteci" noThrow />
        <NestedRoute path="/o-nama">
          <AboutUsWp path="/:id" />
          <AboutUsWp path="/organizacija/:id" />
          <AboutUsWp path="/projekti/:id" />
          <AboutUsWp path="/tradicionalne-manifestacije/:id" />
          <LocationGroupList path="/mreza-ogranaka/:id" />
          <LocationPage path="/mreza-ogranaka/:id/:name" />
          <PublicProcurement path="/javne-nabavke" />
          <Friends path="/prijatelji-biblioteke" />
        </NestedRoute>

        {/* VODIC ZA KORISNIKE */}
        <NestedRoute path="vodic-za-korisnike">
          <UserGuide path="/:category" />
          <Prices path="/cenovnik" />
          <FAQ path="/cesto-postavljana-pitanja" />
          <Services path="/usluge" />
          <Redirect from="/pitaj-bibliotekara" to="/kontakt/pitaj-bibliotekara" noThrow />
          <Redirect from="/bisis-katalog" to="/pretraga" noThrow />
        </NestedRoute>

        {/* GALERIJA */}
        <NestedRoute path="galerija">
          <Gallery path="/" />
          <SingleGallery path=":id" />
        </NestedRoute>

        {/* KONTAKT */}
        <NestedRoute path="kontakt">
          <Redirect from="/" to="/kontakt/pitajte-bibliotekara" noThrow />
          <Contact path="/:id" />
        </NestedRoute>

        {/* KATALOG */}
        <SearchResults path="katalog" />

        {/* PRETRAGA */}
        <SearchResults path="pretraga" />

        {/* LICE NOVOG SADA */}
        <Redirect
          from="lice-novog-sada"
          to="/lice-novog-sada/narodi-u-novom-sadu/o-narodima-u-novom-sadu"
          noThrow
        />

        <NestedRoute path="lice-novog-sada">
          <FaceOfNoviSad path="/:id/:id" />
        </NestedRoute>

        {/* INFORMACIJE */}
        <NestedRoute path="/obavestenja" >
          <Notifications path="/" />
        </NestedRoute>

        {/* IZDAVASTVO */}
        <NestedRoute path="/izdavastvo" >
          <FooterRubrics titleTransKey={'izdavastvo'} path="/:pod_rubrika" />
        </NestedRoute>

        {/* ZA BIBLIOTEKARE */}
        <NestedRoute path="/za-bibliotekare" >
          <FooterRubrics titleTransKey={'za-bibliotekare'} path="/:pod_rubrika" />
        </NestedRoute>

        {/* POLITIKA PRIVATNOSTI */}
        <PrivacyPolicy path="politika-privatnosti" />

        <WorkInProgress default />

      </Router>

      <Footer />

    </Fragment>
  )
}

export default AppDesktop

import React, { useState, useEffect } from "react"
import { Row, Col, Skeleton, Typography, List } from "antd"
import { navigate } from "@reach/router"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import { Parser } from "html-to-react"
import CustomButton from "../../components/CustomButton/CustomButton"

import styles from "./MostReadBooks.module.css"
import MetaDecorator from "../../metaDecorator"

const apiUrl = process.env.REACT_APP_API_URL

const { Paragraph, Title } = Typography

function Recommended() {
	const { t } = useTranslation("translation")

    const htmlToReact = new Parser()

    const [isLoading, setIsLoading] = useState( false )
    const [data, setData] = useState( [] )
    const [responseHeaders, setResponseHeaders] = useState( null )
    const [page, setPage] = useState( 1 )
    const per_page = 10

    const getBooksByCategory = async ( page, per_page ) => {

        try {
            const fetchUrl = new URL(apiUrl + `/wp-json/wp/v2/recommended_books?per_page=${per_page}&page=${page}&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage('lang')}`)
            const res = await fetch( fetchUrl )
            setResponseHeaders({
                totalItems: res.headers.get("X-WP-Total"),
                totalPages: res.headers.get("X-WP-TotalPages")
            })
            const json = await res.json()
        
            if (json[0] && json[0].acf) {
                setData([...data, ...json[0].acf.recommendations])
            }
            setIsLoading( false )
            // if (responseHeaders.totalPages <= page) { setDisabled( true ) }
            return json[0].acf.book
        } catch ( error ) {
            console.log( error )
            setIsLoading( false )
        }
    };

    const handleLoadMore = () => {
        if ( page < responseHeaders.totalPages ) {
            setPage( page + 1 )
            getBooksByCategory( page + 1, per_page )
        }
    }

    useEffect(() => {
        getBooksByCategory( page, per_page )  
        setData( data )
    }, [])

    const loadMore = !isLoading ? (
        <CustomButton
            click={ handleLoadMore }
            isLoading={ isLoading }
            disabled={ responseHeaders && page === parseInt( responseHeaders.totalPages ) }
            className={ styles.customButton }>
            { t('translation:userGuide:prikazite-jos') }
        </CustomButton>
    ) : null

    return (
        <>
            <MetaDecorator 
                title = {textTranslation("Препорука библиотекара")}
            />
            { isLoading ? (
                <Row>
                    <Skeleton />
                </Row>
            ) : (
                    <List
                        className={ styles.mrb__book }
                        grid={{
                            gutter: 24,
                            xs: 1,
                            lg: 3
                        }}
                        loading={ isLoading }
                        dataSource={ data }
                        loadMore={ loadMore }
                        renderItem={ ( b, id ) => {
                            return (
                                <Col
                                    className={ styles.mrb__book__col }  
                                    key={ data.ID } 
                                    span={ 12 } 
                                    onClick={ () => navigate(`/knjiga/${b.book.ID}`) }
                                >
                                    <div className={ styles.bookItemActive }>
                                        <div>
                                            <img 
                                                src={b.book.acf.image? b.book.acf.image.sizes.medium : ""}
                                                className={ styles.imageContainer }
                                                alt="book-cover"
                                            />
                                        </div>
                                        <div 
                                            className={ styles.details } style={{ height: "230px", overflow: "hidden" }}>
                                            <div className={ styles.invisible_number } style={{ fontSize: "300px", right: "100px" }}>&lsquo;&lsquo;</div>
                                            
                                            <p style={{ marginBottom: "0" }}>
                                                <span 
                                                className={styles.ganre}
                                                style={{ 
                                                    color: "rgba(0, 0, 0, 0.20)", 
                                                    fontSize: "16px", 
                                                    textTransform: "uppercase", 
                                                    fontWeight: "700", 
                                                    marginBottom: "0"
                                                }}
                                                >
                                                { t('translation:singleBook:preporuka') } 
                                                </span>
                                                <span className={ styles.recommended_by }> 
                                                    { textTranslation(b.recommended_by) }
                                                </span>
                                            </p>
                                            <Title 
                                                level={ 3 }
                                                ellipsis={{ rows: 2 }} 
                                                className={ styles.title }
                                                style={{
                                                    color: "#071013", 
                                                    fontFamily: "Tinos", 
                                                    height: "80px", 
                                                    fontSize: "30px", 
                                                    fontWeight: "bold", 
                                                    lineHeight: "40px", 
                                                    margin: "0"
                                                }}
                                            >
                                                { textTranslation(b.book.post_title) }
                                            </Title>

                                            <div className={ styles.author }
                                                style={{ marginBottom: "18px" }}
                                            >
                                                { textTranslation(b.book.acf.author) }
                                            </div>
                                            <Paragraph 
                                                ellipsis={{ rows: 3 }}
                                                style={{
                                                    width: "235px", 
                                                    color: "#071013", 
                                                    fontFamily: "Roboto", 
                                                    fontSize: "18px", 
                                                    lineHeight: "27px"
                                                }}
                                                className={ styles.text }>
                                                { htmlToReact.parse( textTranslation(b.book.acf.about) ) }
                                            </Paragraph>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }}>
                    </List>
                )}
        </>
    )
}

export default Recommended

import React from "react"
import { Row, Col, Tag, Icon, Typography } from "antd"
import { Link } from "@reach/router"
import textTranslation from '../../textTranslation'
import { Parser } from "html-to-react"

import styles from "./Activity.module.css"

const { Paragraph } = Typography

const ActivityItem = props => {
  const {
    activityStatus,
    date,
    location,
    category,
    title,
    description,
    id
  } = props
  
  const statusColors = {
    "U toku": "#4BD091",
    "Otkazano": "#CB595C",
    "Popunjeno": "#9959C5",
    "У току": "#4BD091",
    "Отказано": "#CB595C",
    "Попуњено": "#9959C5"
  }
  
  const htmlParser = new Parser()
  const status = activityStatus ? (
    <Tag className={ styles.tag } color={ statusColors[activityStatus] }>
      { activityStatus }
    </Tag>
  ) : null

  return category === 'ОБАВЕШТЕЊА' ? (
  
    <>
    <Link to={`/aktivnosti/${id}`}>
      <Row style={{ marginBottom: "11px" }}>
        <div className={ styles.activityItem }>
          <Col md={{ span: 24 }} lg={{ span: 24 }}>
            <Row>
              <Col span={ 24 }>
                <div className={ styles.description }>
                  <p className={ styles.event }>{ textTranslation(category) }</p>
                  <Paragraph 
                    className={ styles.title }
                    style={{ marginTop: "18px", marginBottom: "0" }}
                    ellipsis={{ rows: 2 }}>
                  { textTranslation(title) }
                  </Paragraph>
                  <div className={ styles.activityDesc }>{ htmlParser.parse(textTranslation(description)) }</div>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </Row>
    </Link>
  </>
  ) : (
    <>
      <Link to={`/aktivnosti/${id}`}>
  
        <Row style={{ marginBottom: "11px" }}>
          <div className={ styles.activityItem }>
            <Col md={ 24 } lg={ 6 } className={ styles.dateLocationWrapper }>
              <div className={ styles.date }>
                <Icon
                  size="large"
                  className={ styles.clockIcon }
                  type="clock-circle"
                />
                <span>{ date } </span>
              </div>
              <div className={ styles.address }>
                <Icon
                  size="large"
                  className={ styles.pinIcon }
                  type="environment"
                />
                <div className={ styles.location }>
                  <span>{ textTranslation(location) }</span>
                  <br />
                </div>
              </div>
            </Col>
            <Col md={{ span: 24 }} lg={{ span: 18 }}>
              <Row>
                <Col span={ 24 }>
                  <div className={ styles.description }>
                    <p className={ styles.event }>{ textTranslation(category) }</p>
                    <Paragraph 
                      className={ styles.title }
                      style={{ marginTop: "18px", marginBottom: "0" }}
                      ellipsis={{ rows: 2 }}>
                    { textTranslation(title) }
                    </Paragraph>
                    <div className={ styles.activityDesc }>{ htmlParser.parse(textTranslation(description)) }</div>
                    {/* htmlParser.parse(textTranslation(data.acf.working_hours_text)) */}
                    <span>{ status }</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
      </Link>
    </>
  )
}

export default ActivityItem
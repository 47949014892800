import React, { useState, useCallback, Fragment } from "react"
import useFetch from "../../../hooks/useFetch"
import { Carousel, Row, Col, Button, Skeleton } from "antd"
import chunk from "lodash.chunk"

import styles from "./RecommendedBook.module.css"
import RecommendedBook from "./RecommendedBook";
export default function RecommendedBooks({ url }) {
	const [ref, setRef] = useState(null)
	const { response, isLoading } = useFetch(url)

	const carousel = useCallback((ref) => setRef(ref), [])
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	return (
		<Fragment>
			<Skeleton active={true} loading={ isLoading } />
			<Carousel ref={ carousel } dots={false}>
				{response.map( row => {
					return chunk(row.acf.recommendations, 2).map( books => {
						return (
							<Fragment>
								<Row
									gutter={24}
									style={{
										display: "flex",
										justifyContent: "center",
										height: 400
									}}>
									{books.map(({ book }) => {
										if (book.acf) {
											return (
												<Col sm={24} md={12} key={ book.ID }>
													<RecommendedBook
														id={ book.ID }
														title={ book.post_title }
														imgSrc={ book.acf.image.url }
														author={ book.acf.author }
														info={ book.acf.about }
														recommendationBy={ book.acf.recommended_by }
													/>
												</Col>
											)
										}
										})}
								</Row>
							</Fragment>
						);
					});
				})}
			</Carousel>
			<div className={ styles.actions }>
				<span>
					<Button 
						className={ styles.actionItem }
						onClick={ prev }
						shape="circle"
						icon="left"
					/>
				</span>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ next }
						shape="circle"
						icon="right"
					/>
				</span>
			</div>
		</Fragment>
	);
}

import React, { useRef, useEffect } from "react"
import textTranslation from '../../textTranslation'

import styles from "./GalleryCard.module.css"

import { Typography } from "antd"
import { Parser } from "html-to-react"

const { Title } = Typography

function GalleryCard({ imageSrc, title, desc, onClick }) {
  const galleryDesc = useRef()
  const htmlToReact = new Parser()

  useEffect(() => {
    if (galleryDesc.current) {
      galleryDesc.current.innerHTML = desc
    }
  }, [desc])

  return (
    <div onClick={ onClick } className={ styles.card }>
      <div
        style={{
          backgroundImage: `url(${imageSrc})`
        }}
        className={ styles.image }
        alt="book cover"
      />
      <Title
        level={ 4 }
        className={`${styles.title} ${styles.singleItemTitle}`}
        ellipsis={{ rows: 2 }}>
        { textTranslation(title) }
      </Title>
      { desc && <div className={ styles.desc }>{ textTranslation(htmlToReact.parse(desc)) }</div> }
    </div>
  )
}

export default GalleryCard

import React, { useState, useEffect, useCallback } from "react"

import { Link } from "@reach/router"
import { Menu } from "antd"
import textTranslation from '../../textTranslation'

function AboutDropdown({ toggleDropdown }) {
  const [aboutUsData, setAboutUsData] = useState( false )

  const { SubMenu } = Menu

  const apiUrl = process.env.REACT_APP_API_URL

  const getDropdownMenu = useCallback(() => {
    const createUrl = () => (
      localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr')
      ? `${apiUrl}/wp-json/wp-api-menus/v2/menus/12?lang=`
      : `${apiUrl}/wp-json/wp-api-menus/v2/menus/12?lang=${localStorage.getItem('lang')}`

    return fetch(createUrl())
    .then(response => response.json())
    .then(data => setAboutUsData(data))
  }, [apiUrl])

  useEffect(() => {
    getDropdownMenu()
  }, [getDropdownMenu])

  const handleLinkClick = () => toggleDropdown(false)



  return (
    <Menu className="headerDropdownWrapper">
      { aboutUsData && aboutUsData.items.map(item => {
          return item.children ? (
            <SubMenu key={ item.id } title={ textTranslation(item.title) }>
              { item.children.map(childItem => {
                return (
                  <Menu.Item key={ childItem.id }>
                    <Link
                      style={{ fontSize: "12px", fontWeight: 700 }}
                      to={`/o-nama${item.url}${childItem.url}`}
                      onClick={ handleLinkClick }
                    >
                      { textTranslation(childItem.title) }
                    </Link>
                  </Menu.Item>
                )
              })}
            </SubMenu>

          ) : (

            <Menu.Item key={ item.id }>
              <Link
                style={{ fontSize: "12px" }}
                to={`/o-nama${item.url}`}
                onClick={ handleLinkClick }
              >
                { textTranslation(item.title) }
              </Link>
            </Menu.Item>
          )
        })}
    </Menu>
  )
}

export default AboutDropdown

import React from "react"
import WithSider from "../../layouts/WithSider"
import AllTitlesSidebar from "../../components/AllTitlesSidebar"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import { useTranslation } from 'react-i18next'


function AllTitles( props ) {
  const { uri, category } = props
  const { t } = useTranslation("translation")

  return (
    <WithSider
      siderTitle={t('translation:allTitles:naslovi') }
      sider={
				<AllTitlesSidebar
					address={ props.location.pathname }
					category={ category }
					uri={ uri }
				/>
		}
      content={
        <>
          { props.children }
        </>
      }
      moreContent={<PopularActivities mobile={ document.body.offsetWidth <= 991 ? true : false } />}
    />
  )
}
export default AllTitles



import React, { useEffect, useState, useCallback } from 'react'
import styles from "./ProcuredTitles.module.css"
import { Row, Typography, Button, Col, Checkbox, Select, Popover } from "antd"
import textTranslation from "../../textTranslation"
import MetaDecorator from "../../metaDecorator"

import { useTranslation } from 'react-i18next'

const { Title } = Typography
const { Option } = Select

const ProcuredTitles = () => {
  const apiUrl = process.env.REACT_APP_API_URL
	const { t } = useTranslation("translation")
  
  const [months, setMonths] = useState([])
  const [selectedMonth, setSelectedMonth] = useState()
  const [books, setBooks] = useState({
    booksArr: [],
    page: 1
  })
  const [filteredBooks, setFilteredBooks] = useState({
    books: [],
    page: 1
  })
  const [genres, setGenres] = useState([])
  const [selectedGenres, updateSelectedGenres] = useState([])
  const [total, setTotal] = useState({})

  // fetch procured books based on month
  useEffect(() => {
    if (Object.keys(months).length > 0) {
      fetch(`${apiUrl}/wp-json/gbns/v2/procured_books?slug=${Object.keys(months).find(k=>months[k]===selectedMonth)}&per_page=14&page=${books.page}`)
      .then(response =>{
        setTotal({data: response.headers.get("X-WP-Total"), pages: response.headers.get("X-WP-TotalPages")})
        return response.json()
      })
      .then(resp => resp[0] && setBooks({...books, booksArr: resp[0].acf.book})
      )
    }
    
  }, [months, selectedMonth, selectedGenres.length === 0])

  //fetch procured books genres
  const fetchGenres = useCallback(() => {
    fetch(`${apiUrl}/wp-json/gbns/v2/get_genres`)
    .then(response => response.json())
    .then(data => setGenres(data))
  }, [apiUrl])

  useEffect(() => {
    fetchGenres()
  }, [fetchGenres])

//fetch procured books dates
const fetchDates = useCallback(()=> {
  fetch(`${apiUrl}/wp-json/gbns/v2/procured_books_years`)
    .then(response => response.json())
    .then(data => {
      setSelectedMonth(data[Object.keys(data)[0]])
      setMonths(data)
    })
  }, [apiUrl])

  useEffect(() => {
    fetchDates()
  }, [fetchDates])

//fetch filtered procured books based on selected genres
  useEffect(() => {
    if (selectedGenres.length > 0) {
      fetch(`${apiUrl}/wp-json/gbns/v2/procured_books?slug=${Object.keys(months).find(k=>months[k]===selectedMonth)}&per_page=14&page=${filteredBooks.page}&genre=${selectedGenres.join(',')}`)
      .then(response => {
        setTotal({data: response.headers.get("X-WP-Total"), pages: response.headers.get("X-WP-TotalPages")})
        return response.json()
      })
      .then(resp => {
        if (resp[0]) {
          return setFilteredBooks({...filteredBooks, books: resp[0].acf.book})
        } else {
          return setFilteredBooks({...filteredBooks, books: []})
        }
      })
    }
  }, [selectedGenres, selectedMonth])

//fetch additional pages
  useEffect(() => {
    if (selectedGenres.length > 0) {
      if (filteredBooks.page !== 1) {
        fetch(`${apiUrl}/wp-json/gbns/v2/procured_books?slug=septembar-2019&per_page=14&page=${filteredBooks.page}&genre=${selectedGenres.join(',')}`)
        .then(response => {
          setTotal({data: response.headers.get("X-WP-Total"), pages: response.headers.get("X-WP-TotalPages")})
          return response.json()
        })
        .then(resp => {
          if (resp[0]) {
            return setFilteredBooks({...filteredBooks, books: [...filteredBooks.books, ...resp[0].acf.book]})
          }
        })
      }
    } else {
      if (books.page !== 1) {
        fetch(`${apiUrl}/wp-json/gbns/v2/procured_books?slug=${Object.keys(months).find(k=>months[k]===selectedMonth)}&per_page=14&page=${books.page}`)
        .then(response => {
          setTotal({data: response.headers.get("X-WP-Total"), pages: response.headers.get("X-WP-TotalPages")})
          return response.json()
        })
        .then(resp => {
          if (resp[0]) {
            return setBooks({...books, booksArr: [...books.booksArr, ...resp[0].acf.book]})
          }
        })
      }
    }
  }, [filteredBooks.page, books.page])

  const createCheckboxGroup = () => {
    return (
      <Checkbox.Group
        className={styles.procuredTitles__checkboxGroup}
        name="activitiesLocation"
        defaultValue={[]}
        onChange={val => updateSelectedGenres(val)}
        >
          {genres && Object.keys(genres).map(item => (
            <Checkbox key={item} value={item} className={styles.procuredTitles__checkboxGroup__checkbox} style={{marginLeft: "0"}}>
              <span>{genres[item]}</span>
              <br />
            </Checkbox>
          ))}
			</Checkbox.Group>
    )
  }

  const renderLoadmoreButton = () => {
    return (
      <Row type="flex" justify="center">
        <Button className={styles.procuredTitles__loadmore}
          onClick={() => {
            return selectedGenres.length > 0 ? setFilteredBooks({...filteredBooks, page: filteredBooks.page + 1}) : setBooks({...books, page: books.page + 1})
          }}
          disabled={selectedGenres.length > 0 ? (total.pages === books.page || filteredBooks.books.length === 0 || total.data === filteredBooks.books.length) : (total.pages === books.page)}
        >
          {t('translation:userGuide:prikazite-jos')}
        </Button>
      </Row>
    )
  }


  const renderList = (arr) => {
    if (window.screen.width <= 576) {
      return arr && arr.map(book => {
        return (
          <div className={styles.procuredTitles__row}>
            <p className={styles.procuredTitles__row__authgen}>
              <span>{ book.acf.author }</span> { textTranslation(book.acf.genre) }
            </p>
            <p className={styles.procuredTitles__row__title}> { book.post_title } </p>
          </div>
        )
      })
    } else {
      return arr && arr.map(book => {
        return (
          <Row key={book.ID} gutter={24} className={styles.procuredTitles__row}>
            <Col span={8} className={styles.procuredTitles__info}>
              {textTranslation(book.acf.genre)}
            </Col>
            <Col span={8} className={styles.procuredTitles__info}>
              {textTranslation(book.post_title)}
            </Col>
            <Col span={8} className={styles.procuredTitles__info}>
              {textTranslation(book.acf.author)}
            </Col>
          </Row>
        )
      })
    }
  }




  return (
    <>
     <MetaDecorator 
      title = {textTranslation(`Приновљени наслови`)}
    />
      <div className={styles.procuredTitles__dropdown} >
        <Title 
          level={window.screen.width <= 576 ? 2 : 3 } 
          style={{fontFamily: "Tinos"}}
          >
        {
          window.screen.width <= 576 ? t('translation:procuredTitles:prinovljeni-naslovi') : t('translation:procuredTitles:prinovljeni-naslovi-za-mesec')
        }
        </Title>
        {/* {window.screen.width <= 576 && books.booksArr.length !== 0 && (
          <div>
            ({total.data} prinovljenih naslova)
          </div>
        )} */}
        {Object.keys(months).length !== 0 && (
          <div>
            <span className={styles.procuredTitles__meseci}>{t('translation:procuredTitles:meseci')} </span>
            <Select
              className="yearSelect"
              defaultValue={textTranslation(selectedMonth)}
              onChange={(value, option) => {
                setBooks({...books, page: 1})
                setFilteredBooks({...filteredBooks, page: 1})
                setSelectedMonth(months[value])
              }}
              style={{opacity: "0.6", color: "#071013", fontFamily: "Tinos"}}
            >
              {Object.keys(months).map(item => {
                  return (<Option key={item} value={item}>{textTranslation(months[item])}</Option>)
              })}
            </Select>
          </div>
        )}
      </div>
      {window.screen.width >= 576 && books.booksArr.length !== 0 && (
        <div>
          ({total.data} {t('translation:procuredTitles:prinovljenih-naslova')})
        </div>
      )}

      <Row gutter={24} className={styles.procuredTitles__headingSection} style={{marginTop: "42px"}}>
      	<Col span={8} className={styles.procuredTitles__heading}>
          <Popover placement="bottom" content={createCheckboxGroup()} trigger="click">
            <span style={{cursor: "pointer"}}>{t('translation:procuredTitles:oblast')}</span><span style={{fontSize: ".8rem", color: "grey"}}>▼</span>
          </Popover>
      	</Col>
      	<Col span={8} className={styles.procuredTitles__heading}>
        {t('translation:procuredTitles:naslov')}
      	</Col>
      	<Col span={8} className={styles.procuredTitles__heading}>
        {t('translation:procuredTitles:autor')}
      	</Col>
      </Row>

      { books.booksArr.length !== 0 && selectedGenres.length === 0 && renderList(books.booksArr) }

      { selectedGenres.length > 0 && renderList(filteredBooks.books) }

      {renderList()}

      {renderLoadmoreButton()}
		</>
  )
}

export default ProcuredTitles
import React, { useState } from 'react'
import parentStyle from '../FeaturedActivity/FeaturedActivity.module.css'
import style from './NextActivities.module.css'
import { Row, Typography, Card, Button, Icon, Skeleton } from 'antd'
import { Link } from '@reach/router'
import { Parser } from 'html-to-react'
import useFetch from '../../../hooks/useFetch'
import { useTranslation } from 'react-i18next'
import SingleLink from '../../SingleLink/SingleLink'
import textTranslation from '../../../textTranslation'

const { Title, Paragraph } = Typography

const NextActivities = () => {
	const { t } = useTranslation("translation")
	const htmlToReact = new Parser()
	const { response, isLoading } = useFetch(
		`/wp-json/gbns/v2/activities/filtered?lang=${localStorage.getItem("lang")}`
	)
	const [activityId, setActivityId] = useState( 0 )

	const changeActivity = ( forward = true ) => {
		if ( forward && activityId === response.length - 1 ) {
			setActivityId(0)
		} else if ( !forward && activityId === 0 ) {
			setActivityId( response.length - 1 )
		} else {
			setActivityId( forward ? activityId + 1 : activityId - 1 )
		}
	}

	return (
		<div className={ style.wrapper }>
			<Row type='flex' justify='space-between' align='middle' style={{ marginBottom: '12px', marginTop: '4px' }}>
				<Title className={ parentStyle.title } level={ window.screen.width <= 576 ? 3 : 2 } style={{ fontSize: "32px" }}/>
				<SingleLink style={{marginRight: "0", marginBottom: "1rem"}} className={ parentStyle.link } to='/aktivnosti'
					title={t('translation:nextActivities:sve-aktivnosti')}
				/>
			</Row>

			<Row>
				<Card className={ style.main } bordered={ false } loading={ isLoading } bodyStyle={{padding: '32px',border: '1px solid rgb(255, 255, 255, 0.4)'}}>
					<Skeleton active={ true } loading={ isLoading } paragraph={{ rows: 10 }}/>
					
					{!isLoading && response && response.length && (
						<>
							<Title level={ 2 } ellipsis={{ rows: 2 }} className={ style.title }>
								{ textTranslation(response[activityId].post_title) } 
							</Title>
							
							<span className={ style.date }>
								{ textTranslation(response[activityId].acf.date) }
							</span>
							<span className={ style.time }>
								{ textTranslation(response[activityId].acf.time) }
							</span>
							
							<Paragraph className={style.shortDesc} ellipsis={{ rows: 2 }}>
								{ textTranslation(htmlToReact.parse( response[activityId].acf.description )) }
							</Paragraph>
							
							<Button size='large' ghost={ true } style={{ borderRadius: "6px", border: "2px solid #FFF" }}>
								<Link to={`/aktivnosti/${response[activityId].ID}`}>
									{t('translation:nextActivities:vise-informacija')}
									<Icon className={ parentStyle.icon } height='12px' type='arrow-right' />
								</Link>
							</Button>
							
							<div className={ style.btnWrapper }>
								{ response.length > 1 && (
									<>
										<div onClick={ () => changeActivity() } className={ parentStyle.pageBtn }>
											<Icon type='left' width='6px' className={ parentStyle.mAuto }/>
										</div>
										<div onClick={ () => changeActivity(false) } className={`${parentStyle.pageBtn} ${parentStyle.mLeft}`}>
											<Icon type='right' width='6px'/>
										</div>
									</>
								)}
							</div>
							
							{response[activityId].acf.image && response[activityId].acf.image.url && (
								<div className={ style.image } style={{ backgroundImage: `url(${response[activityId].acf.image.url})`}}/>
							)}

						</>
					)}
				</Card>
			</Row>
		</div>
	)
}

export default NextActivities
import React from "react"
import styles from "./MainNav.module.css"
import { Link } from "@reach/router"
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from "antd"

import { mainNavData } from "./mainNavData"


const MainNav = props => {
	const { t } = useTranslation("translation")
  const { location } = props
  const splitPath = location.pathname.split("/")

  const digiMenu = (
    <Menu subMenuOpenDelay={0.3}>    
      
      <Menu.SubMenu popupClassName="digiSub" key="sub1"
        title={<span style={{ fontSize: "12px" }} >{t('translation:mainNav:digitalni-materijal')}</span>}
      >
        {/* <Menu.SubMenu popupClassName="digiSub" key="sub2" className={styles.main_dropdown_element}
          title={<Link to="/digitalizacija/zavicajna-zbirka" style={{display:"block", width:"10rem", fontSize: "12px"}}>{t('translation:mainNav:zavicajna-zbirka')}</Link>}
        >   */}
        <Menu.SubMenu popupClassName="digiSub" key="sub2" className={styles.main_dropdown_element}
          title={<span style={{display:"block", width:"10rem", fontSize: "12px"}}>{t('translation:mainNav:zavicajna-zbirka')}</span>}
        >          
          {/* {mainNavData.ZAVICAJNAZBIRKA.map(e => (
            <Menu.Item key={ e.key }><Link style={{ fontSize: "12px" }} to={  e.link }>{t( e.trans )}</Link></Menu.Item>
          ))} */}
        </Menu.SubMenu>   
        
        
        <Menu.SubMenu popupClassName="digiSub" key="sub3" style={{position:"relative", background: "white", zIndex: "10"}}
          title={<span style={{ fontSize: "12px" }} >{t('translation:mainNav:nasi-casopisi')}</span>}
        >    
          {mainNavData.NASICASOPISI.map(e => (
            <Menu.Item key={ e.key }><Link style={{ fontSize: "12px" }} to={ e.link }>{t( e.trans )}</Link></Menu.Item>
          ))}
        </Menu.SubMenu>
      
      </Menu.SubMenu>
      
      
      <Menu.Item key="3">
        <Link style={{ fontSize: "12px" }} to="/digitalizacija/digitalni-omladinski-centar">
          {t('translation:mainNav:digitalni-omladinski-centar')} 
        </Link>
      </Menu.Item>

      <Menu.Item key="4">
        <Link style={{ fontSize: "12px" }} to="/digitalizacija/korisni-linkovi">
          {t('translation:mainNav:korisni-linkovi')}
        </Link>
      </Menu.Item>


    </Menu>
  )

  return (
    <Menu selectedKeys={ splitPath[1] } mode="horizontal" style={{fontSize: "13px"}}>      

      {mainNavData.MAINNAV.map(e => {
        if (e.dropdown) {
          return (
            <Menu.Item key={ e.key }>
              <Dropdown overlay={ digiMenu } placement="bottomCenter" overlayClassName="dropdownMenu">
                <Link to={ e.link }>{t( e.trans )}</Link>
              </Dropdown>
            </Menu.Item>
          )
        } 
        if(e.key === 'katalog'){
          return (
            <Menu.Item key={ e.key }>
              <a href="https://opac.bisis.rs/lib/gbns" target="_blank" rel="noopener noreferrer">
                {t( e.trans )}
              </a>
          </Menu.Item>
          )
        }
        else {
          return (
            <Menu.Item key={ e.key }>
              <Link to={ e.link }>{t( e.trans )}</Link>
            </Menu.Item>
          )
        }
      })}

    </Menu>
  )
}

export default MainNav

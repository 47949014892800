import React, { useState, useEffect, useCallback } from "react"
import styles from "../FaceOfNoviSad/FaceOfNsContent.module.css"
import { Parser } from "html-to-react"

function IzdavackaDelatnostContent({ uri }) {
  const [magazineData, setMagazineData] = useState(false)
  const htmlToReact = new Parser()
  const slugs = uri.split("/")
  const currentSlug = uri
  const apiUrl = process.env.REACT_APP_API_URL

  const fetchIzdavackaDelatnost = useCallback( () => {
    fetch(
      `${apiUrl}/wp-json/wp/v2/pages?slug=${currentSlug}&_embed&lang=${localStorage.getItem('lang')}`
    )
      .then(response => response.json())
      .then(data => {
        setMagazineData(data)
      })
  }, [apiUrl, currentSlug])

  useEffect(() => {
    fetchIzdavackaDelatnost()
  }, [currentSlug, fetchIzdavackaDelatnost])

  let artists = slugs.includes("umetnici-sa-petrovaradinske-tvrdave")
//   return("content")
  return (
    <>
      {magazineData[0] && (
        <div
          className={`${styles.singleItem} container-publisher facens--single-item ${
            artists ? "artist" : ""
          }`}  
          key={magazineData[0].id}>
            <p className="heading-publisher">{htmlToReact.parse(magazineData[0].title.rendered)}</p>
          {htmlToReact.parse(magazineData[0].content.rendered)}
        </div>
      )}
    </>
  )
 }

export default IzdavackaDelatnostContent

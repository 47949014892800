import { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

const apiUrl = process.env.REACT_APP_API_URL

export default function useFetch(
  url,
  params = { per_page: 10, page: 1},
  options
) {
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [responseHeaders, setResponseHeaders] = useState(null)
  const fetchUrl = new URL(apiUrl + url)
  // const langStorage = localStorage.getItem("lang")
  const langStorage = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') ? '' : localStorage.getItem("lang")
  //   if (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') {
  //     return ''
  //   } else {
  //     return localStorage.getItem("lang")
  //   }
  // }
  params.lang = langStorage()

  fetchUrl.search = fetchUrl.search ?
    `${fetchUrl.search}&${(new URLSearchParams(params)).toString()}`
    : (new URLSearchParams(params)).toString();

  const { i18n } = useTranslation("translation")

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"))
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const res = await fetch(fetchUrl, options)
        const totalItems = parseInt(res.headers.get("X-WP-Total"))
        const totalPages = parseInt(res.headers.get("X-WP-TotalPages"))

        const responseHeaders = {
          totalItems,
          totalPages
        }

        const json = await res.json()
        setResponse(json)
        setResponseHeaders(responseHeaders)
        setIsLoading(false)
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [url, options, params.page, params.per_page, params.lang])
  return { response, error, isLoading, responseHeaders }
}

import React, { useState, useEffect } from 'react'
import { Row, Col, Skeleton, Typography, Select, List } from 'antd'
import { Parser } from 'html-to-react'
import { navigate } from '@reach/router'
import CustomButton from '../../components/CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import styles from './MostReadBooks.module.css'
import MetaDecorator from '../../metaDecorator'

const apiUrl = process.env.REACT_APP_API_URL
const { Paragraph, Title } = Typography
const { Option } = Select

function MostReadBooks({ category }) {
	const { t } = useTranslation( "translation" )
  const htmlToReact = new Parser()
  const [isLoading, setIsLoading] = useState( false )
  const [disabled, setDisabled] = useState( false )
  const [data, setData] = useState( [] )
  const [yearMonth, setYearMonth] = useState( {} )
  const [selectedYear, setSelectedYear] = useState( "Oktobar-2019" )
  const [responseHeaders, setResponseHeaders] = useState( null )
  const [page, setPage] = useState( 1 )
  const per_page = 10

  const getBooksByCategory = async (date, page, per_page, reset = false) => {
     try {
      const fetchUrl = new URL(apiUrl + `/wp-json/gbns/v2/most_read_books?slug=${date}&page=${page}&per_page=${per_page}&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage('lang')}`)
      const res = await fetch( fetchUrl )
      setResponseHeaders({
        totalItems: res.headers.get("X-WP-Total"),
        totalPages: res.headers.get("X-WP-TotalPages")
      })
      const json = await res.json()
      if (json[0] && json[0].acf.book) {
        if (reset) {
          setData([...json[0].acf.book])
        } else {
          setData([...data, ...json[0].acf.book])
        }
      }
      setIsLoading( false )
      if (responseHeaders.totalPages <= page) { setDisabled( true ) }
        return json[0].acf.book
      } catch ( error ) {
        setIsLoading( false )
      }
  }

  useEffect( () => {
    selectedYear && getBooksByCategory( selectedYear, page, per_page, true )
  }, [selectedYear] )

  const yearMonthMostReadBooks = async () => {
    const fetchUrl = new URL(apiUrl + `/wp-json/gbns/v2/most_read_books_years?lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage('lang')}`)
    const res = await fetch( fetchUrl )
    const json = await res.json()
    setSelectedYear( Object.keys( json )[0] )
    setYearMonth( json )
  }

  const handleLoadMore = () => {
    if ( page < responseHeaders.totalPages ) {
      setPage( page + 1 )
      getBooksByCategory( selectedYear, page + 1, per_page )
    }
  }

  useEffect(() => {
    yearMonthMostReadBooks()
  }, [])

  const loadMore = !isLoading ? (
    <CustomButton
      click={ handleLoadMore }
      isLoading={ isLoading }
      disabled={ responseHeaders !== null && parseInt( responseHeaders.totalPages ) <= page }
      className={ styles.customButton }>
      { t('translation:userGuide:prikazite-jos') }
    </CustomButton>
  ) : null

  return (
    <>
    <MetaDecorator 
      title = {textTranslation(`Најчитанији наслови`)}
    />
      { Object.keys( yearMonth ).length !== 0 && <Row type="flex" justify="space-between">
        <div className={ styles.selectedTitle }>
          <Title level={ 3 } > { t('translation:mostReadBooks:najčitaniji-naslovi-za-mesec') }</Title>
          <Select
            className="yearSelect"
            defaultValue={ Object.keys(yearMonth)[0] }
            onChange={ value => setSelectedYear( value ) }
            style={{ 
              margin:'0px', 
              opacity: "0.6", 
              color: "#071013", 
              fontFamily: "Tinos" 
            }}
          >
          { Object.keys( yearMonth ).map( item => {
            return (
              <Option key={ item } value={ item }> 
                { yearMonth[item] } 
              </Option>
            )
          })}
          </Select>
        </div>
      </Row>}
          { isLoading ? <Row><Skeleton /></Row> : (
            <List
              className={ styles.mrb__book }
              grid={{ gutter: 24, xs: 1, lg: 3 }}
              loading={ isLoading }
              dataSource={ data }
              loadMore={ loadMore }
              renderItem={ (b, id) => {

              return (
                <Col
                  className={ styles.mrb__book__col } 
                  key={ data.ID } 
                  span={ 12 } 
                  onClick={ () => navigate(`/knjiga/${b.id}`) }
                >
                  <div className={ styles.bookItemActive }>
                    <div>
                      <img
                        src={ b.acf.image ? b.acf.image.sizes.medium : ""}
                        className={ styles.imageContainer }
                        alt="book-cover"
                      />
                    </div>
                    <div className={ styles.details }>
                      <div>
                        <div className={ styles.invisible_number }>
                          { id+1 < 10 ? "0" + ( id+1 ) : id+1 }
                        </div>
                        <span className={ styles.genre }>
                          { b.acf && textTranslation(b.acf.genre) }
                        </span>
                        <Title 
                          level={ 3 }
                          ellipsis={{ rows: 2 }}
                          style={{
                            wordBreak: "break-word",
                            color: "#071013", 
                            fontFamily: "Tinos", 
                            height: "80px", 
                            fontSize: "30px", 
                            margin: "0"
                          }}
                        >
                          { textTranslation(b.post_title) }
                        </Title>
                        <div className={ styles.author }>
                          { textTranslation(b.acf.author) }
                        </div>
                      </div>
                      <Paragraph
                        ellipsis={{ rows: 3 }}
                        style={{ 
                          width: "235px", 
                          color: "#071013", 
                          fontFamily: "Roboto", 
                          fontSize: "18px", 
                        }}
                      >
                        { htmlToReact.parse( textTranslation(b.acf.about) ) }
                      </Paragraph>
                    </div>
                  </div>
                </Col>
                )
              }}>
            </List>
          )}
        </>
    )
}

export default MostReadBooks
import React, { useState, useEffect } from "react"
import { Skeleton, List } from "antd"
import { useTranslation } from 'react-i18next'
import useFetch from "../../hooks/useFetch"
import MagazineCard from "../MagazineCard/MagazineCard"
import DigitizedMagazineModal from "../DigitizedMagazineModal/DigitizedMagazineModal"
import CustomButton from "../CustomButton/CustomButton"
import textTranslation from "../../textTranslation"
import MetaDecorator from "../../metaDecorator"

const DigitizedMagazineList = () => {
  const { t } = useTranslation("translation")
  const [currentPage, setCurrentPage] = useState( 1 )
  const [initLoading, setInitLoading] = useState( true )
  const [magazineData, setMagazineData] = useState( [] )
  const [isModalOpen, setIsModalOpen] = useState( false )
  const [modalData, setModalData] = useState( null )
  const [disabled, setDisabled] = useState( true )

  const { response, isLoading, responseHeaders } = useFetch(
    "/wp-json/wp/v2/digitized_magazines",
    {
      per_page: 8,
      page: currentPage
    }
  )

  useEffect(() => {
    setInitLoading(false)
  }, [])


  useEffect(() => {
    setMagazineData(response)
  }, [response])

  useEffect(() => {
    if (
      responseHeaders &&
      responseHeaders.totalPages &&
      currentPage < responseHeaders.totalPages
    ) {
      setDisabled( false )
    } else {
      setDisabled( true )
    }
  }, [responseHeaders, currentPage])

  function handleLoadMore() {
    if (currentPage < responseHeaders.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  function handleOpenModal(book) {
    setModalData(book)
    setIsModalOpen(true)
  }

  function handleCloseModal() {
    setIsModalOpen(false)
  }

  return (
    <> 
      <MetaDecorator 
        title = {textTranslation('Новине и часописи')}
      />
      {
        <List
          className="magazineList"
          grid={{ gutter: window.screen.width <= 576 ? 20: 50, xs: 2, sm: 3, md: 4 }}
          loading={ initLoading }
          loadMore={
            !isLoading && (
              <CustomButton
                click={ handleLoadMore }
                isLoading={ isLoading }
                disabled={ disabled }>
                { t('translation:activities:prikazite-jos') }
              </CustomButton>
            )
          }
          dataSource={ magazineData }
          renderItem={ item => (
            <Skeleton avatar title={ false } loading={ initLoading } active>
              <List.Item>
                <MagazineCard
                  year={ item && item.acf.year }
                  number={ item.acf && item.acf.number } 
                  month={ textTranslation(item.acf && item.acf.month) }
                  imgSrc={ item.acf.pages[0] && item.acf.pages[0].sizes.medium }
                  click={ () => handleOpenModal( item ) }
                />
              </List.Item>
            </Skeleton>
          )}
        />
      }
      { modalData && (
        <DigitizedMagazineModal
          isVisible={ isModalOpen }
          closeModal={ handleCloseModal }
          data={ modalData }
        />
      )}
    </>
  )
}

export default DigitizedMagazineList
import React, { useState, useCallback } from 'react'
import style from './MobileFeaturedActivity.module.css'
import './FeaturedActivityCard.css'
import { navigate } from "@reach/router"
import { Row, Typography, Carousel, Button } from 'antd'
import useFetch from '../../../hooks/useFetch'
import moment from 'moment'
import 'moment/locale/sr'
import { useTranslation } from 'react-i18next'
import textTranslation from './../../../textTranslation'
const { Title, Paragraph } = Typography

moment.locale('sr')
const getDay = date => moment(date, 'DD/mm/yyyy').format('dddd')
const getDate = date => moment(date, 'DD/mm/yyyy').format('DD. MMMM YYYY.')

const MobileFeaturedActivity = () => {
	const [ref, setRef] = useState(null)
	const { t } = useTranslation("translation")
	const [ pos, setPos ] = useState(null)
	const { response } = useFetch('/wp-json/gbns/v2/activities/featured')

	const carousel = useCallback( ref => {setRef(ref)}, [] )
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	const CarouselSettings = {
		dots: false,
		draggable: true,
		centerMode: true,
		swipeToSlide: true,
		centerPadding: "50px"
	}

	return (
		<Row>
			<Title level={window.screen.width <= 576 ? 3 : 2} className={style.heading}>
				{t('translation:mobileFeaturedActivity:izdvojene-aktivnosti-vesti')}
			</Title>

			<Carousel ref={carousel} {...CarouselSettings} className="carous">
				{response.map(( activity, index ) => (
					<Row key={ index }>
						<div
							onMouseDown={ event => setPos(event.clientX) }
							onMouseUp={ event => { event.clientX === pos && navigate(`/aktivnosti/${activity.ID}`) }}
							className={ style.card }
							style={{ height: '470px', margin: '0 10px' }}
						>

							<div className={ style.imageWrapper } style={{ backgroundImage: `url(${activity.acf.image.url})`}}>
								<div className={ style.imageBackdrop } />
								<div className={ style.featuredImageInfo }>

									{activity.acf.date &&
										<>
											<div>
												<div className={ style.featuredImageDay }>
													{ getDay(activity.acf.date) }
												</div>
												<div className={ style.featuredImageDate }>
													{ getDate( activity.acf.date ) }
												</div>
											</div>
											<span className={ style.featuredImageDivider } />
										</>
									}

									{activity.acf.place &&
										<div className={ style.featuredImagePlace }>
											{ textTranslation(activity.acf.place) }
										</div>
									}

								</div>
							</div>

							<div className={ style.activityInfo }>
								<Title ellipsis={{rows: 2}} className={ style.title } style={{fontSize: "16px"}}>
									{ textTranslation(activity.post_title) }
								</Title>
								{activity.acf.speakers &&
								<>
									<Paragraph className={ style.Paragraph }>
										{ textTranslation(activity.acf.speakers) }
									</Paragraph>
								</>
								}
							</div>
						</div>
					</Row>
				))}
			</Carousel>

			{window.screen.width >= 576 && <div className={ style.actions }>
				<span>
					<Button className={ style.actionItem } onClick={ prev } shape="circle" icon="left" />
				</span>
				<span>
					<Button className={ style.actionItem } onClick={ next } shape="circle" icon="right" />
				</span>
			</div>}
		</Row>
	)
}

export default MobileFeaturedActivity;

export const mainNavData = {
  ZAVICAJNAZBIRKA: [
    {
      key: 'do-1867',
      link: '/digitalizacija/zavicajna-zbirka/stampane-do-1867',
      trans: 'translation:mainNav:knjige-stampane-do-1867'
    },
    {
      key: 'od-1868',
      link: '/digitalizacija/zavicajna-zbirka/stampane-od-1868',
      trans: 'translation:mainNav:knjige-stampane-posle-1868'
    },
    {
      key: 'novine-casopisi',
      link: '/digitalizacija/zavicajna-zbirka/novine-casopisi',
      trans: 'translation:mainNav:novine-i-casopisi'
    },
    {
      key: 'razglednice',
      link: '/digitalizacija/zavicajna-zbirka/razglednice',
      trans: 'translation:mainNav:razglednice'
    }
  ],  
  NASICASOPISI: [
    {
      key: 'info-bilten',
      link: '/digitalizacija/nasi-casopisi/informativni-bilten',
      trans: 'translation:mainNav:informativni-bilten'
    },
    {
      key: 'info-bilten-list',
      link: '/digitalizacija/nasi-casopisi/informativni-bilten/prikaz-svih-brojeva',
      trans: 'translation:mainNav:prikaz-svih-brojeva'
    },
    {
      key: 'mozaik',
      link: '/digitalizacija/nasi-casopisi/mozaik',
      trans: 'translation:mainNav:mozaik'
    },
    {
      key: 'mozaik-list',
      link: '/digitalizacija/nasi-casopisi/mozaik/prikaz-svih-brojeva',
      trans: 'translation:mainNav:prikaz-svih-brojeva'
    },
    {
      key: 'bibliotecki-putokaz',
      link: '/digitalizacija/nasi-casopisi/bibliotecki-putokaz-za-decu',
      trans: 'translation:mainNav:bibliotecki-putokaz-za-decu'
    },
    {
      key: 'bibliotecki-putokaz-list',
      link: '/digitalizacija/nasi-casopisi/bibliotecki-putokaz-za-decu/prikaz-svih-brojeva',
      trans: 'translation:mainNav:prikaz-svih-brojeva'
    },
  ],  
  VODICZAKORISNIKE: [
    {
      key: 'decaIRoditelji',
      link: '/vodic-za-korisnike/deca-i-roditelji',
      trans: 'translation:mobileMainNav:deca-i-roditelji'
    },
    {
      key: 'mladi',
      link: '/vodic-za-korisnike/mladi',
      trans: 'translation:mobileMainNav:mladi'
    },
    {
      key: 'studenti',
      link: '/vodic-za-korisnike/studenti',
      trans: 'translation:mobileMainNav:studenti'
    },
    {
      key: 'odrasli',
      link: '/vodic-za-korisnike/odrasli',
      trans: 'translation:mobileMainNav:odrasli'
    }
  ],
  CONTACT: [
    {
      key: 'ask',
      link: '/kontakt/pitajte-bibliotekara',
      trans: 'translation:mainNav:pitajte-bibliotekara'
    },
    {
      key: 'employee',
      link: '/kontakt/zaposleni',
      trans: 'translation:mainNav:zaposleni'
    },
    {
      key: 'service',
      link: '/kontakt/sluzbe',
      trans: 'translation:mainNav:sluzbe'
    },
    {
      key: 'branch',
      link: '/kontakt/ogranci',
      trans: 'translation:mainNav:ogranci'
    }
  ],  
  FOOTER: [
    {
      key: '1',
      link: '/vodic-za-korisnike/cesto-postavljana-pitanja',
      type: 'question-circle',
      trans: 'translation:mainNav:cesto-postavljana-pitanja'
    },
    {
      key: '2',
      link: '/kontakt/pitaj-bibliotekara',
      type: 'user',
      trans: 'translation:mainNav:pitajte-bibliotekara'
    },
    {
      key: '3',
      link: '/cenovnik',
      type: 'file-text',
      trans: 'translation:mainNav:cenovnik'
    }
  ],
  MAINNAV: [
    {
      key: 'katalog',
      link: 'katalog',
      trans: 'translation:mainNav:katalog'
    },
    {
      key: 'digitalizacija',
      link: '/digitalizacija/zavicajna-zbirka/stampane-do-1867',
      trans: 'translation:mainNav:digitalizacija',
      dropdown: true
    },
    {
      key: 'aktivnosti',
      link: 'aktivnosti',
      trans: 'translation:mainNav:aktivnosti'
    },
    {
      key: 'vodic-za-korisnike',
      link: 'vodic-za-korisnike/deca-i-roditelji',
      trans: 'translation:mainNav:vodic-za-korisnike'
    },
    {
      key: 'galerija',
      link: 'galerija',
      trans: 'translation:mainNav:galerija'
    }
  ]
}
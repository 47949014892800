import React from "react";
import { Row, Skeleton } from "antd";
import WithSider from "../../layouts/WithSider";
import useFetch from "../../hooks/useFetch";
import UserGuidenceSidebar from "../../components/UserGuidenceSidebar";
import PopularActivities from "../../components/PopularActivities/PopularActivities";
import { Parser } from "html-to-react";
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

export default function ServicesPage({ uri }) {
	const { t } = useTranslation("translation")
	const { response, isLoading } = useFetch("/wp-json/wp/v2/pages", {
		slug: "usluge"
	});
	const htmlParser = new Parser();
	let servicesContent;

	if (response && response.length) {
		servicesContent = <>{htmlParser.parse(textTranslation(response[0].content.rendered))}</>;
	}

	return (
		<WithSider
			siderTitle={t('translation:userGuide:vodic-za-korisnike')}
			sider={<UserGuidenceSidebar uri={uri} />}
			content={
				<Skeleton
					title={true}
					loading={isLoading}
					paragraph={{ rows: 8 }}
					active>
					<Row>
						{servicesContent}
					</Row>
				</Skeleton>
			}
			moreContent={<PopularActivities />}
		/>
	);
}

import React, { useRef, useState, useEffect } from "react"
import styles from "../SidebarFilters/SidebarFilters.module.css"
import { Row, Col, Input, Radio, Checkbox, DatePicker, Icon, Button, Modal } from "antd"
import moment from "moment"
import SiderBottomLinks from "../SiderBottomLinks/SiderBottomLinks"
import { useTranslation } from 'react-i18next'
// import convert from 'cyrillic-to-latin'
import convert from 'latin-to-serbian-cyrillic'

const { Search } = Input

const ActivitySearch = props => {
  const searchTermInput = useRef( null )
  const radioInput = useRef( null )
  const checkboxInput1 = useRef( null )
  const checkboxInput2 = useRef( null )
  const checkboxInput3 = useRef( null )
  const dateFrom = useRef( null )
  const dateTo = useRef( null )

  const { t } = useTranslation( "translation" )

  const [beginningDate, setBeginningDate] = useState( null )
  const [searchTerm, setSearchTerm] = useState( null )
  const [fromDate, setFromDate] = useState( null )
  const [toDate, setToDate] = useState( null )
  const [locations, setLocations] = useState( [] ) 
  const [activityType, setActivityType] = useState( [] ) 
  const [activityAudience, setActivityAudience] = useState( [] ) 
  const [filtersActive, setFiltersActive] = useState( false )
  const [filtersApplied, setFiltersApplied] = useState( false )
  const [mobileFilters, setMobileFilters] = useState( false )
  const [moreContentLoaded, setMoreContentLoaded] = useState( false )
  const [filteredStats, setfilteredStats] = useState(
    {
      "location_type": {
        "prigradsko naselje": 0,
        "grad": 0
      },
      "type": {
        "kursevi": 0,
        "konkursi": 0,
        "događaji": 0
      },
      "target_audience": {
        "deca": 0,
        "mladi": 0,
        "studenti": 0,
        "odrasli": 0
      }
    })

  let urlToPass = null

  const apiUrl = process.env.REACT_APP_API_URL

  const {
    toggleFilter,
    resultsOfFilter,
    closeFilters,
    setCurrentPage,
    setTotalItems,
    setTotalPages,
    loadMoreStatus,
    toggleLoadMore,
    setResultsLoading,
    mobile
  } = props

  const {
    location_type: { grad, "prigradsko naselje": prigradsko_naselje },
    type: { 
      knjizevne_promocije, 
      konkursi, 
      kursevi, 
      programi, 
      predavanja, 
      izlozbe, 
      obavestenja, 
      edukacija, 
      radionice, 
      projekti,  
      programi_za_decu,
      tutorijali,
      kviz,
    },
    target_audience: { деца, млади, студенти, одрасли }
  } = filteredStats

  let currentActivePage = props.currentActivePage
  let term = props.searchTerm

  const useMountEffect = (fun) => useEffect(fun, [])

  useMountEffect((()=> {
    if ( props.defaultActivityType && props.defaultActivityType.length >= 1 && props.defaultActivityType.length < 3 ) {
          setActivityType( props.defaultActivityType )
        } else {
          setActivityType( [] )
        }
    
        setFiltersActive( true )
        setFiltersApplied( true )
  }))

  useEffect(() => {
    if ( mobileFilters ) {
      createUrlToFetch( currentActivePage )
      setMobileFilters( false )
    }
  }, [mobileFilters, createUrlToFetch, currentActivePage])

  useEffect(() => {
    if ( loadMoreStatus === true ) {
      setMoreContentLoaded( true )
      createUrlToFetch( currentActivePage )
      toggleLoadMore( false )
    }

    if ( filtersActive ) {
      setResultsLoading( true )

      if ( !mobile ) {
        setCurrentPage( 1 )
        currentActivePage = 1
        createUrlToFetch( currentActivePage )
      }
      setFiltersActive( false )
    }
  }, [filtersActive, filtersApplied, loadMoreStatus, moreContentLoaded])

  useEffect(() => {
    if (
      !searchTerm &&
      !fromDate &&
      !toDate &&
      !locations.length &&
      !activityType.length &&
      !activityAudience.length
    ) {
      currentActivePage = 1
      createUrlToFetch( currentActivePage )
    }
  }, [searchTerm == null, fromDate, toDate, locations, activityType, activityAudience])

  function onStartChange( value, dateString ) {
    setBeginningDate( value )
  }

  function disabledDate( endValue ) {
    if ( !endValue || !beginningDate ) {
      return false
    }

    return endValue.valueOf() <= beginningDate.valueOf()
  }

  function handleActivitiesSearch( value ) {
    setFiltersActive( true )
    setFiltersApplied( true )
    setSearchTerm( value )
  }

  function handleDatePickerDateFrom( date, dateString ) {
    if ( !!dateString ) {
      const dateFrom = moment( dateString ).format( "DD/MM/YYYY" )
      urlToPass = `&from_date=${dateFrom}`
      setFromDate( dateFrom )
    } else {
      urlToPass = null
      setFromDate( null )
    }

    setFiltersActive( true )
    setFiltersApplied( true )
  }

  function handleDatePickerDateTo( date, dateString ) {
    if ( !!dateString ) {
      const dateTo = moment( dateString ).format( "DD/MM/YYYY" )
      urlToPass = `&to_date=${dateTo}`
      setToDate( dateTo )
    } else {
      urlToPass = null
      setToDate( null )
    }

    setFiltersActive( true )
    setFiltersApplied( true )
  }

  function handleDateRadioButtonChange( event ) {
    const chosenTimeRange = event.target.value

    const startOfWeek = moment()
      .startOf( "week" )
      .format( "DD/MM/YYYY" )
    const endOfWeek = moment()
      .endOf( "week" )
      .format( "DD/MM/YYYY" )

    const startOfMonth = moment()
      .startOf( "month" )
      .format( "DD/MM/YYYY" )
    const endOfMonth = moment()
      .endOf( "month" )
      .format( "DD/MM/YYYY" )

    const today = moment().format( "DD/MM/YYYY" )

    switch ( chosenTimeRange ) {
      case "today":
        urlToPass = `?from_date=${today}&to_date=${today}`
        setFromDate( today )
        setToDate( today )
        break
      case "thisWeek":
        urlToPass = `?from_date=${startOfWeek}&to_date=${endOfWeek}`
        setFromDate( startOfWeek )
        setToDate( endOfWeek )
        break
      case "thisMonth":
        urlToPass = `?from_date=${startOfMonth}&to_date=${endOfMonth}`
        setFromDate( startOfMonth )
        setToDate( endOfMonth )
        break
      default:
        urlToPass = ""
        setFromDate( null )
        setToDate( null )
    }
    setFiltersActive( true )
  }

  function handleActivitiesLocationChoice( value ) {
    if ( value.length === 1 ) {
      setLocations( value )
    } else {
      setLocations( [] )
    }

    setFiltersActive( true )
    setFiltersApplied( true )
  }

  function handleActivitiesTypeChoice( value ) {
    if ( value.length >= 1 && value.length < 3 ) {
      setActivityType( value )
    } else {
      setActivityType( [] )
    }

    setFiltersActive( true )
    setFiltersApplied( true )
  }

  function handleActivitiesAudienceChoice( value ) {
    if ( value.length >= 1 && value.length < 4 ) {
      setActivityAudience( value )
    } else {
      setActivityAudience( [] )
    }

    setFiltersActive( true )
    setFiltersApplied( true )
  }

  function createUrlToFetch( currentActivePage ) {
    let searchQuery = null
    let beginningDate = null
    let endDate = null
    let activitiesLocations = []
    let activitiesTypes = []
    let activitiesAudience = []

    if ( searchTerm ) {
      searchQuery = `&search_term=${localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr' ? convert(searchTerm) : searchTerm}`
    }

    if ( fromDate ) {
      beginningDate = `&from_date=${fromDate}`
    }

    if ( toDate ) {
      endDate = `&to_date=${toDate}`
    }

    if ( locations.length ) {
      if ( locations.length === 1 ) {
        
        switch(locations[0]) {
          case "Grad":
            activitiesLocations = "&locations=Grad"
            break;
          case "Град":
            activitiesLocations = "&locations=Град"
            break;
          case "Prigradsko naselje":
            activitiesLocations = "&locations=Prigradsko naselje"
            break;
          case "Приградско насеље":
            activitiesLocations = "&locations=Приградско насеље"
            break;
          default:
            activitiesLocations = `&locations=${t('translation:activitySearch:prigradsko-naselja')}`
        } 
      }
    }

    if ( activityType.length ) {
      activitiesTypes = `&type=${activityType}`
    }

    if ( activityAudience.length ) {
      activitiesAudience = `&target_audience=${activityAudience}`
    }

    let fetchUrl = `${apiUrl}/wp-json/gbns/v2/activities/filtered?per_page=10&page=${currentActivePage}&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`

    if ( term !== '' && term !== undefined ) {
      fetchUrl = `${apiUrl}/wp-json/gbns/v2/activities/filtered?per_page=10&page=${currentActivePage}&search_term=${term}&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`
    }
    
    let filteredStatsQuery = null

    if (
      searchQuery ||
      fromDate ||
      toDate ||
      locations.length ||
      activityType.length ||
      activityAudience.length
    ) {

      const query = `${searchQuery ? searchQuery : ""}${
        beginningDate ? beginningDate : ""
        }${endDate ? endDate : ""}${
        activitiesLocations ? activitiesLocations : ""
        }${activitiesTypes ? activitiesTypes : ""}${
        activitiesAudience ? activitiesAudience : ""
        }`

      fetchUrl = `${fetchUrl}${query}`
      fetchUrl = encodeURI(fetchUrl)
      filteredStatsQuery = query
    }

    let urlFilter = !!filteredStatsQuery ?
      `${apiUrl}/wp-json/gbns/v2/activities/filtered/stats?${filteredStatsQuery}` :
      `${apiUrl}/wp-json/gbns/v2/activities/filtered/stats`

    fetch( fetchUrl )
      .then( res => {
        setTotalItems( parseInt(res.headers.get("X-WP-Total")) )
        setTotalPages( parseInt(res.headers.get("X-WP-TotalPages")) )
        return res.json()
      })
      .then( res => {
        toggleFilter( true )
        resultsOfFilter( res )
        return fetch( `${urlFilter}` )
      })
      // .catch(error => {
      //   console.error("error", error )
      //   Modal.error({
      //     title:
      //       t('translation:gallerySearch:doslo-je-do-greske-molimo-osvezite-stranicu-i-pokusajte-ponovo'),
      //     onOk() { }
      //   })
      // })
      .finally(() => setResultsLoading( false ))
      .then(res => { 
        return res.json() 
      })
      .then(res => { 
        setfilteredStats( res ) 
      })
      .catch(err => { console.error( err ) })
   }

  const toggleMobileFilters = () => {
    setCurrentPage( 1 )
    window.setTimeout (() => { 
      setMobileFilters( true ) 
      closeFilters()
    }, 0)
  }

  const resetFilters = () => {
    setCurrentPage( 1 )
    setSearchTerm( null )
    setFromDate( null )
    setToDate( null )
    setLocations( [] )
    setActivityType( [] )
    setActivityAudience( [] )

    //to actually reset the
    searchTermInput.current.input.state.value = null
    radioInput.current.state.value = "allDates"
    dateFrom.current.picker.state.value = undefined
    dateTo.current.picker.state.value = undefined
    checkboxInput1.current.state.value = []
    checkboxInput2.current.state.value = []
    checkboxInput3.current.state.value = []
  }

  return (
    <Row className={`${styles.filtersOverlay} ${styles.filtersWrapper}`}>  
      <Col span={ 24 } className={ styles.title }>
        <div className={ styles.titleWrapper }>
          <h1>{t('translation:activitySearch:aktivnosti')}</h1>
          <Icon
            type="close"
            className={ styles.btnCloseFilters }
            style={{ fontSize: "28px", color: "#071013", fontWeight: 700 }}
            onClick={ closeFilters }
          />
        </div>
      </Col>
      <Col span={ 24 }>
        <Search
          ref={ searchTermInput }
          className={`search-activities ${styles.filtersOverlaySearch}`}
          placeholder={t('translation:activitySearch:pretrazite-aktivnosti')}
          onSearch={ handleActivitiesSearch }
          onChange={ event => {
            setSearchTerm( event.target.value )
          }}
        />
      </Col>
      <Col span={ 24 }>
        <div className={ styles.searchGroupName }>
          <span className={ styles.searchCategory }>{t('translation:activitySearch:datum')}</span>
        </div>
        <div className={ styles.searchGroup }>
          <Radio.Group
            ref={ radioInput }
            buttonStyle="outline"
            name="activitiesDates"
            defaultValue="allDates"
            onChange={ event => handleDateRadioButtonChange( event )}
          >
            <Radio value="allDates">{t('translation:activitySearch:svi-datumi')}</Radio>
            <Radio value="today">{t('translation:activitySearch:danas')}</Radio>
            <Radio value="thisWeek">{t('translation:activitySearch:ove-nedelje')}</Radio>
            <Radio value="thisMonth">{t('translation:activitySearch:ovog-meseca')}</Radio>
          </Radio.Group>
        </div>
        <div className={ styles.datePicker }>
          <span className={ styles.from }>{t('translation:activitySearch:od')}</span>
          {localStorage.getItem("lang") ?
           <DatePicker
           placeholder={t('translation:activitySearch:datum')}
           ref={ dateFrom }
           style={{ color: "red" }}
           name="activitiesDateFrom"
           onChange={( date, dateString ) => {
             onStartChange()
             handleDatePickerDateFrom( date, !!dateString ? dateString : null )
             }
           }
         /> : 
         <DatePicker
         placeholder={t('translation:activitySearch:datum')}
         ref={ dateFrom }
         style={{ color: "red" }}
         name="activitiesDateFrom"
         onChange={( date, dateString ) => {
           onStartChange()
           handleDatePickerDateFrom( date, !!dateString ? dateString : null )
           }
         }
       />
         }

        </div>
        <div className={ styles.datePicker }>
          <span className={ styles.to }>{t('translation:activitySearch:do')}</span>
          <DatePicker
            placeholder={t('translation:activitySearch:datum')}
            ref={ dateTo }
            disabledDate={ disabledDate }
            style={{ color: "red" }}
            name="activitiesDateUntil"
            onChange={( date, dateString ) => {
              handleDatePickerDateTo( date, !!dateString ? dateString : null )
            }}
          />
        </div>
      </Col>
      <Col span={ 24 }>
        <div className={ styles.searchGroupName }>
          <span className={ styles.searchCategory }>{t('translation:activitySearch:lokacija')}</span>
        </div>
        <div className={ styles.searchGroup }>
          <Checkbox.Group
            ref={ checkboxInput1 }
            name="activitiesLocation"
            defaultValue={ [] }
            onChange={ checkValue => handleActivitiesLocationChoice( checkValue )}
          >
            <Checkbox value='Град'>
              {t('translation:activitySearch:grad')}
              <span className={ styles.count }>{ grad }</span>
            </Checkbox>
            <Checkbox value='Приградско насеље'>
              {t('translation:activitySearch:prigradska-naselja')}
              <span className={ styles.count }>{ prigradsko_naselje }</span>
            </Checkbox>
          </Checkbox.Group>
        </div>
      </Col>
      <Col span={ 24 }>
        <div className={ styles.searchGroupName }>
          <span className={ styles.searchCategory }>{t('translation:activitySearch:tip-aktivnosti')}</span>
        </div>
        <div className={ styles.searchGroup }>
          <Checkbox.Group
            ref={ checkboxInput2 }
            name="activitiesType"
            defaultValue={ props.defaultActivityType ?? [] }
            onChange={ checkValue => handleActivitiesTypeChoice( checkValue ) }
          >
            <Checkbox value='књижевне промоције'>
              {t('translation:activitySearch:knjizevne_promocije')}
              <span className={ styles.count }>{ knjizevne_promocije }</span>
            </Checkbox>
            <Checkbox value='курсеви'>
              {t('translation:activitySearch:kursevi')}
              <span className={ styles.count }>{ kursevi }</span>
            </Checkbox>
            <Checkbox value='догађаји'>
              {t('translation:activitySearch:konkursi')}
              <span className={ styles.count }>{ konkursi }</span>
            </Checkbox>
            <Checkbox value='програми'>
              {t('translation:activitySearch:programi')}
              <span className={ styles.count }>{ programi }</span>
            </Checkbox>
            <Checkbox value='предавања'>
              {t('translation:activitySearch:predavanja')}
              <span className={ styles.count }>{ predavanja }</span>
            </Checkbox>
            <Checkbox value='изложбе'>
              {t('translation:activitySearch:izlozbe')}
              <span className={ styles.count }>{ izlozbe }</span>
            </Checkbox>
            <Checkbox value='едукација'>
              {t('translation:activitySearch:edukacija')}
              <span className={ styles.count }>{ edukacija }</span>
            </Checkbox>
            <Checkbox value='радионице'>
              {t('translation:activitySearch:radionice')}
              <span className={ styles.count }>{ radionice }</span>
            </Checkbox>
            <Checkbox value='projekti'>
              {t('translation:activitySearch:projekti')}
              <span className={ styles.count }>{ projekti }</span>
            </Checkbox>
            <Checkbox value='programi_za_decu'>
              {t('translation:activitySearch:programi_za_decu')}

              <span className={ styles.count }>{ programi_za_decu }</span>
            </Checkbox>
            <Checkbox value='tutorijali'>
              {t('translation:activitySearch:tutorijali')}
              <span className={ styles.count }>{ tutorijali }</span>
            </Checkbox>
            <Checkbox value='kviz'>
              {t('translation:activitySearch:kviz')}
              <span className={ styles.count }>{ kviz }</span>
            </Checkbox>
          </Checkbox.Group>
        </div>
      </Col>
      <Col span={ 24 }>
        <div className={ styles.searchGroupName }>
          <span className={ styles.searchCategory }>{t('translation:activitySearch:ciljna-grupa')}</span>
        </div>
        <div className={ styles.searchGroup }>
          <Checkbox.Group
            ref={ checkboxInput3 }
            name="activitiesAudience"
            onChange={ checkValue => handleActivitiesAudienceChoice( checkValue ) }
          >
            <Checkbox value='деца'>
              {t('translation:activitySearch:deca')}
              <span className={ styles.count }>{ деца }</span>
            </Checkbox>
            <Checkbox value='млади'>
              {t('translation:activitySearch:mladi')}
              <span className={ styles.count }>{ млади }</span>
            </Checkbox>
            <Checkbox value='студенти'>
              {t('translation:activitySearch:studenti')}
              <span className={ styles.count }>{ студенти }</span>
            </Checkbox>
            <Checkbox value='одрасли'>
              {t('translation:activitySearch:odrasli')}
              <span className={ styles.count }>{ одрасли }</span>
            </Checkbox>
          </Checkbox.Group>
        </div>
      </Col>
      <Col span={24}>
        <Button className={ styles.reset } onClick={ resetFilters }>
          {t('translation:activitySearch:resetujte-filtere')}
        </Button>
      </Col>
      {mobile && (
        <React.Fragment>
          <Col span={ 24 }>
            <Button
              className={ styles.btnApplyFilters }
              block
              onClick={ toggleMobileFilters }
            >
              {t('translation:activitySearch:primenite-filtere')}
            </Button>
          </Col>
          <Col span={ 24 }>
            <SiderBottomLinks />
          </Col>
        </React.Fragment>
      )}
    </Row>
  )
}

export default ActivitySearch
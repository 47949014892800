import React, { useState } from "react"
import { Button } from "antd"
import Layout from "../../layouts/Layout"
import GalleryMasonryList from "../../components/GalleryMasonryList/GalleryMasonryList"
import GalleryDetailsModal from "../../components/GalleryDetailsModal/GalleryDetailsModal"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import useFetch from "../../hooks/useFetch"
import useWindowSize from "../../hooks/useWindowSize"
import { useTranslation } from "react-i18next"
import textTranslation from '../../textTranslation'
import MetaDecorator from "../../metaDecorator"

import "./SingleGallery.css"

function SingleGallery(props, mobile) {
  const { t } = useTranslation("translation")
  const { response, isLoading } = useFetch(`/wp-json/wp/v2/galleries/${props.id}`)
  const [visible, setVisible] = useState(false)
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(null)
  const size = useWindowSize()

  function handleClick(gallery) {
    setVisible(true)
    setSelectedGalleryItem(gallery)
  }

  function handleCancel() {
    setVisible(false)
  }

  function onBack() {
    props.navigate("../")
  }

  return size.width > 991 ? ( 
    <React.Fragment>
      <MetaDecorator
        title = { textTranslation(response.title && response.title.rendered) }
        image = { response.acf && response.acf.gallery[0].url }
      />
      <Layout>
        {!isLoading && response && (
          <React.Fragment>
            <header className="title-wrapper">
              <Button
                className="btn-back"
                icon="left"
                onClick={onBack}
                type="link">
                { t('translation:singleActivity:nazad-na-pretragu') }
              </Button>
              <h2 className="gallery-title" >{ textTranslation(response.title && response.title.rendered) }</h2>
              <p className="images-count">
                {`${response.acf && response.acf.gallery.length}`} fotografija
              </p>
            </header>
            <main className="single-gallery-main-wrapper">
              <GalleryMasonryList
                onItemClick={ handleClick }
                list={ response }
                loading={ isLoading }
              />
              { selectedGalleryItem && (
                <GalleryDetailsModal
                  data={ response }
                  visible={ visible }
                  onCancel={ handleCancel } 
                  selectedItem={ selectedGalleryItem }
                />
              )}
              <Button className="customButton" size="large" block>
                { t('translation:userGuide:prikazite-jos') }
              </Button>
            </main>
          </React.Fragment>
        )}
      </Layout>
      <PopularActivities/>
      </React.Fragment>
  ) : ( 
      <Layout>
        { !isLoading && response && (
          <React.Fragment>
            <header className="title-wrapper">
              <Button
                className="btn-back"
                icon="left"
                onClick={ onBack }
                type="link">
                { t('translation:singleActivity:nazad-na-pretragu') }
              </Button>
              <h2 className="gallery-title" >{ textTranslation(response.title && response.title.rendered) }</h2>
              <p className="images-count">
                {`${response.acf && response.acf.gallery.length}`} fotografija
              </p>
            </header>
            <main className="single-gallery-main-wrapper">
              <GalleryMasonryList
                onItemClick={ handleClick }
                list={ response }
                loading={ isLoading }
              />
              { selectedGalleryItem && (
                <GalleryDetailsModal
                  data={ response } 
                  visible={ visible }
                  onCancel={ handleCancel }
                  selectedItem={ selectedGalleryItem }
                />
              )}
              <Button className="customButton" size="large" block>
                { t('translation:userGuide:prikazite-jos') }
              </Button>
            </main>
          </React.Fragment>
        )}
          <PopularActivities mobile={ mobile }/>
      </Layout>
)
}

export default SingleGallery
import React from "react"
import { useTranslation } from 'react-i18next'
import { Link } from "@reach/router"
import { Menu } from "antd"
import "../HeaderDropdown/HeaderDropdown.module.css"

import { mainNavData } from "../MainNav/mainNavData"


function ContactDropdown({ toggleDropdown }) {
  const { t } = useTranslation("translation")

  const handleLinkClick = () => toggleDropdown( false )

  return (
    <>
      <Menu className="headerDropdownWrapper">

        {mainNavData.CONTACT.map(e => (
          <Menu.Item key={ e.key }>
            <Link style={{ fontSize: "12px" }} to={ e.link } onClick={ handleLinkClick }>
              {t( e.trans )}
            </Link>
          </Menu.Item>
        ))}

      </Menu>
    </>
  )
}

export default ContactDropdown

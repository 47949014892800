import React from 'react'
import styles from './Logo.module.css'
import logo from '../../assets/images/logo.svg'
import textTranslation from '../../textTranslation'

const Logo = () => {
  return (
    <div className={ styles.container }>
      <img src={ logo } alt="logo" className={ styles.logoImage } />
      <p className={ styles.logo }>
        { textTranslation("Градска библиотека у Новом Саду") }
      </p>
    </div>
  )
}

export default Logo

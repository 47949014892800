import React, { useState, useCallback, Fragment } from "react"
import styles from "./ProcuredBooks.module.css"
import { Row, Typography, Carousel, Button, Col, Skeleton } from "antd"
import chunk from "lodash.chunk"
import { useTranslation } from 'react-i18next'
import useFetch from "../../../hooks/useFetch"
import SingleLink from "../../SingleLink/SingleLink"
import textTranslation from "./../../../textTranslation"
const { Title } = Typography

export default function ProcuredBooksList({ url }) {
	const { t } = useTranslation( "translation" )
	const [ref, setRef] = useState( null )
	const { response, isLoading } = useFetch( url )
	const carousel = useCallback( ref => setRef( ref ), [] )
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	return (
		<>
			<Skeleton active={ true } loading={ isLoading } />
			<Carousel 
				slidesToShow={1}
				ref={ carousel }
				dots={ false }
			>
				{response.map( row => {
					let month = row.acf.month
					let year = row.acf.year
					let length = row.acf.book.length
					return chunk(row.acf.book, 5).map((books) => (
						<Fragment>
							<Row
								type="flex"
								justify="space-between"
								align="middle"
								style={{
									marginBottom: "32px",
									marginTop: "4px"
								}}
							>
								<Title 
									level={3} 
									className={ styles.title } 
									style={{ fontSize: "14px" }}
								>
									{ textTranslation(month) } - { year } ({ length } {t('translation:procuredTitles:prinovljenih-naslova')})
								</Title>
								<SingleLink 
									to="/naslovi/prinovljeni-naslovi"
									title={t('translation:procuredTitles:pogledaj-sve')}
								/>
							</Row>
							<Row gutter={24} className={ styles.headingSection }>
								<Col span={8} className={ styles.heading }>
									{t('translation:procuredTitles:oblast')}
								</Col>
								<Col span={8} className={ styles.heading }>
									{t('translation:procuredTitles:naslov')}
								</Col>
								<Col span={8} className={ styles.heading }>
									{t('translation:procuredTitles:autor')}
								</Col>
							</Row>
							{books.map( book => {
								return (
								<Row gutter={24} className={ styles.row } key={book.ID+Math.random()}>
									<Col span={8} className={ styles.info }>
										{ book.acf.genre
											? textTranslation(book.acf.genre.post_title)
											: "/"
										}
									</Col>
									<Col span={8} className={ styles.info }>
										{ textTranslation(book.post_title) }
									</Col>
									<Col span={8} className={ styles.info }>
										{ textTranslation(book.acf.author) }
									</Col>
								</Row>
							)})}
						</Fragment>
						)
					)
				})}
			</Carousel>
			<div className={ styles.actions }>
				<span>
					<Button 
						className={ styles.actionItem }
						onClick={ prev }
						shape="circle"
						icon="left"
					/>
				</span>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ next }
						shape="circle"
						icon="right" 
					/>
				</span>
			</div>
		</>
	)
}

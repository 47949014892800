import React from "react";
import { List } from "antd";
import styles from "./Price.module.css";
import textTranslation from "../../textTranslation"

export default function PriceItem({ item }) {
	return (
		<List.Item
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between"
			}}>
			<p className={styles.title}>{textTranslation(item.post_title)}</p>
			<strong className={styles.price}>{textTranslation(item.acf.price)}</strong>
		</List.Item>
	);
}

import React, { useState, useEffect } from "react"
import { Link } from "@reach/router"
import { Menu } from "antd"
import { useTranslation } from 'react-i18next'

import styles from "./DigitizationSidebar.module.css"
import MetaDecorator from "../../metaDecorator"

const ZAVICAJNAZBIRKA = [
  {key: 'stampane-do-1867', link: '/digitalizacija/zavicajna-zbirka/stampane-do-1867', text: 'translation:mainNav:knjige-stampane-do-1867'},
  {key: 'stampane-od-1868', link: '/digitalizacija/zavicajna-zbirka/stampane-od-1868', text: 'translation:mainNav:knjige-stampane-posle-1868'},
  {key: 'novine-casopisi', link: '/digitalizacija/zavicajna-zbirka/novine-casopisi', text: 'translation:mainNav:novine-i-casopisi'},
  {key: 'razglednice', link: '/digitalizacija/zavicajna-zbirka/razglednice', text: 'translation:mainNav:razglednice'}
  // {key: 'radovi-u-toku', link '/digitalizacija/zavicajna-zbirka/razglednice', text: 'Bisis'}
]

const NASICASOPISI = [
  {key: 'informativni-bilten', link: '/digitalizacija/nasi-casopisi/informativni-bilten', text: 'translation:mainNav:informativni-bilten'},
  {key: '/digitalizacija/nasi-casopisi/informativni-bilten/prikaz-svih-brojeva', link: '/digitalizacija/nasi-casopisi/informativni-bilten/prikaz-svih-brojeva', text: 'translation:mainNav:prikaz-svih-brojeva', indented: true},
  {key: 'mozaik', link: '/digitalizacija/nasi-casopisi/mozaik', text: 'translation:mainNav:mozaik'},
  {key: '/digitalizacija/nasi-casopisi/mozaik/prikaz-svih-brojeva', link: '/digitalizacija/nasi-casopisi/mozaik/prikaz-svih-brojeva', text: 'translation:mainNav:prikaz-svih-brojeva', indented: true},
  {key: 'bibliotecki-putokaz', link: '/digitalizacija/nasi-casopisi/bibliotecki-putokaz', text: 'translation:mainNav:bibliotecki-putokaz-za-decu'},
  {key: '/digitalizacija/nasi-casopisi/bibliotecki-putokaz-za-decu/prikaz-svih-brojeva', link: '/digitalizacija/nasi-casopisi/bibliotecki-putokaz-za-decu/prikaz-svih-brojeva', text: 'translation:mainNav:prikaz-svih-brojeva', indented: true},
]


const { Item, ItemGroup } = Menu
const DigitizationSidebar = ({ id }) => {
  const { t } = useTranslation("translation")
  //required to rerender navigation if user decides to use dropdown menu while sidebar is visible...
  const [render, setRerender] = useState(true)
  useEffect(() => {setRerender(!render)}, [id])

  return (
    <Menu
      style={{ width: 256 }}
      defaultOpenKeys={ ["sub1"] }
      defaultSelectedKeys={ [`${id}`] }
      mode="inline"
      className="digitizationMenu"
    >
      <MetaDecorator />
      <span className={ styles.menuSubTitle }>
        <span>{ t('translation:mainNav:digitalni-materijal') }</span>
      </span>

      {/* <ItemGroup key="g1" title={ t('translation:mainNav:zavicajna-zbirka') }>
        {ZAVICAJNAZBIRKA.map(e => (
          <Item key={e.key}>
            <Link to={e.link}>
            { t(e.text) }
            </Link>
          </Item>
        ))}
      </ItemGroup> */}

      <ItemGroup key="g2" title={ t("translation:mainNav:nasi-casopisi") }>
        {NASICASOPISI.map(e => (
          <Item key={e.key} className={e.indented && "indentedMenuItem"}>
          <Link to={e.link}>
          { t(e.text) }
          </Link>
        </Item>
        ))}
      </ItemGroup>

      <Item className="indentedMenuItem" key="digitalni-omladinski-centar">
        <Link to="/digitalizacija/digitalni-omladinski-centar/">
        { t('translation:mainNav:digitalni-omladinski-centar') }
        </Link>
      </Item>
      <Item className="indentedMenuItem" key="korisni-linkovi">
        <Link to="/digitalizacija/korisni-linkovi/">
        { t('translation:mainNav:korisni-linkovi') }
        </Link>
      </Item>
    </Menu>
  )
}

export default DigitizationSidebar
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const allowedLanguages = ['sr', 'sr_cyr', 'en']
const defaultLng = 'sr_cyr';
let lng = defaultLng;

const storageLanguage = localStorage.getItem('lang');
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1){
  lng = storageLanguage;
}
// debugger
i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    lng,
    fallbackLng: 'sr',
    whitelist: ['sr', 'sr_cyr', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });
  
export default i18n;
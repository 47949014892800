import React, { useState, useCallback, Fragment } from "react"
import useFetch from "../../../hooks/useFetch"
import { Carousel, Row, Col, Button, Skeleton } from "antd"
import styles from "./RecommendedBook.module.css"
import RecommendedBook from "./RecommendedBook"


export default function RecommendedBooks({ url }) {
	const [ref, setRef] = useState(null)

	const carousel = useCallback( ref => setRef(ref), [])
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	const { response, isLoading } = useFetch(url)

	return (
		<Fragment>
			<Skeleton active={ true } loading={ isLoading } />
			<Carousel
				variableWidth={ true }
				ref={ carousel }
				dots={ false }
				adaptiveHeight={ true }
				style={{ marginBottom: "50px" }}
			>
				{ response.map( row => {
					return row.acf.recommendations.map( (book, i) => {
						if (book.book) {
							return (
								<Fragment key={i}>
									<Row gutter={ 24 }>
										<Col span={ 24 }>
											<RecommendedBook
												id={ book.book.ID }
												title={ book.book.post_title }
												imgSrc={ book.book.acf.image.url }
												author={ book.book.acf.author }
												// info={ htmlToReact.parse(book.book.acf.about) }
												info= { book.book.acf.about }
												recommendationBy={ book.book.acf.recommended_by }
											/>
										</Col>
									</Row>
								</Fragment>
							)
						}
					})
				})}
			</Carousel>
			
			<div className={ styles.actions }
				style={{ display: window.screen.width >= 600 ? "flex" : "none", justifyContent: "center" }}
			>
				<span>
					<Button 
						className={ styles.actionItem }
						onClick={ prev }
						shape="circle"
						icon="left"
					/>
				</span>
				<span>
					<Button 
						className={ styles.actionItem }
						onClick={ next }
						shape="circle"
						icon="right"
					/>
				</span>
			</div>
		</Fragment>
	);
}

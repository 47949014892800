import React, { useState, useEffect, useCallback } from "react"
import styles from "./FaceOfNsContent.module.css"
import { Parser } from "html-to-react"

function FaceOfNsContent({ uri }) {
  const [magazineData, setMagazineData] = useState(false)
  const htmlToReact = new Parser()
  const slugs = uri.split("/")
  const currentSlug = slugs.slice(-1)[0]
  const apiUrl = process.env.REACT_APP_API_URL

  const fetchFaceOfNS = useCallback( () => {
    fetch(
      `${apiUrl}/wp-json/wp/v2/pages?slug=${currentSlug}&_embed&lang=${localStorage.getItem('lang')}`
    )
      .then(response => response.json())
      .then(data => {
        setMagazineData(data)
      })
  }, [apiUrl, currentSlug])

  useEffect(() => {
    fetchFaceOfNS()
  }, [currentSlug, fetchFaceOfNS])

  let artists = slugs.includes("umetnici-sa-petrovaradinske-tvrdave")

  return (
    <>
      {magazineData[0] && (
        <div
          className={`${styles.singleItem} facens--single-item ${
            artists ? "artist" : ""
          }`}
          key={magazineData[0].id}>
          {htmlToReact.parse(magazineData[0].content.rendered)}
        </div>
      )}
    </>
  )
}

export default FaceOfNsContent

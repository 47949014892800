import React, { useState, useEffect, useRef } from "react"
import { Input, Select, Button, Row, Col, Modal } from "antd"
import Highlighter from "react-highlight-words"
import convert from "cyrillic-to-latin"
import { useTranslation } from 'react-i18next'
import useDebounce from "../../hooks/useDebounce"
import styles from "./SearchInput.module.css"
import axios from "axios"
import { navigate } from "@reach/router"
import MetaDecorator from "../../metaDecorator"
import textTranslation from "../../textTranslation"

const { Option } = Select
const customOptions = {
  headers: {
    Library: "gbns",
    "Content-Type": "text/plain"
  }
}

const SearchInput = props => {
  const { t } = useTranslation("translation")
  const [searchTerm, setSearchTerm] = useState("")
  const [results, setResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [keyUp, setKeyUp] = useState(false)
  const [chosenDepartment, setChosenDepartment] = useState(null)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const searchInputField = useRef(null)
  const autocompleteWrapper = useRef(null)

  useEffect(() => {
    if (keyUp) {
      setKeyUp(false)
      return
    }

    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      searchCharacters(debouncedSearchTerm)
        .then(response => {
          const searchResults = response.data
          setResults(searchResults)
          setIsSearching(true)
        })
        .catch(error => {
          Modal.error({
            title:
            t('translation:gallerySearch:doslo-je-do-greske-molimo-osvezite-stranicu-i-pokusajte-ponovo'),
            onOk() {}
          })
        })
    } else {
      setResults([])
    }
  }, [debouncedSearchTerm])

  const bookCategories = [
    {value:"1" ,name:t('translation:searchInput:djura-danicic')},
    {value:"2" ,name:t('translation:searchInput:stevan-sremac')},
    {value:"3" ,name:t('translation:searchInput:petefi-sandor')},
    {value:"4" ,name:t('translation:searchInput:tosa-trifunov')},
    {value:"5" ,name:t('translation:searchInput:kosta-trifkovic')},
    {value:"6" ,name:t('translation:searchInput:kamenica-j-j-zmaj')},
    {value:"7" ,name:t('translation:searchInput:decije-odeljenje')},
    {value:"8" ,name:t('translation:searchInput:bibliotecki-fond')},
    {value:"9" ,name:t('translation:searchInput:petar-kocic')},
    {value:"10" ,name:t('translation:searchInput:milica-stojadinovic')},
    {value:"11" ,name:t('translation:searchInput:djordje-aracki')},
    {value:"12" ,name:t('translation:searchInput:majur')},
    {value:"13" ,name:t('translation:searchInput:mihal-babinka')},
    {value:"14" ,name:t('translation:searchInput:endre-adi')},
    {value:"15" ,name:t('translation:searchInput:7-jul')},
    {value:"16" ,name:t('translation:searchInput:nikola-tesla')},
    {value:"17" ,name:t('translation:searchInput:futog-j-j-zmaj')},
    {value:"18" ,name:t('translation:searchInput:djura-jaksic')},
    {value:"19" ,name:t('translation:searchInput:veljko-petrovic')},
    {value:"20" ,name:t('translation:searchInput:laza-kostic')},
    {value:"21" ,name:t('translation:searchInput:strana-knjiga')},
    {value:"22" ,name:t('translation:searchInput:vladimir-nazor')},
    {value:"23" ,name:t('translation:searchInput:citaonica')},
    {value:"24" ,name:t('translation:searchInput:medicinska-skola')},
    {value:"25" ,name:t('translation:searchInput:branko-radicevic')},
    {value:"26" ,name:t('translation:searchInput:zarko-zrenjanin')},
    {value:"27" ,name:t('translation:searchInput:danilo-kis')},
    {value:"28" ,name:t('translation:searchInput:ivo-andric')},
    {value:"29" ,name:t('translation:searchInput:serijske-publikacije')},
    {value:"30" ,name:t('translation:searchInput:knjigobus')},
    {value:"31" ,name:t('translation:searchInput:zavicajna-zbirka')},
    // {value:"39" ,name:t('translation:searchInput:bibliotecki-fond')},
    {value:"40" ,name: t('translation:searchInput:trifun-dimic')},
    {value:"41" ,name:t('translation:searchInput:izvan-biblioteke')},
    {value:"42" ,name:t('translation:searchInput:posebne-usluge')},
    {value:"43" ,name:t('translation:searchInput:mz-omladinski-pokret')},
    {value:"99" ,name:t('translation:searchInput:koricenje')},
    {value:"44" ,name:t('translation:searchInput:nikita-stanesku')},
    {value:"45" ,name:t('translation:searchInput:digitalni-omladinski-centar')}
  ]

  bookCategories.sort( (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  const searchDepartmentsList = (
    <Select
      defaultValue= {t('translation:searchInput:svi-ogranci')}
      onChange={(option) => setChosenDepartment(option)}

      style={{ width: 150 }}>
      <Option value="">{t('translation:searchInput:svi-ogranci')}</Option>
      { bookCategories.map((el, index) => <Option value={el.value} key={index }>{el.name}</Option>) }
    </Select>
  )

  function renderPrefName(param) {
    switch (param) {
      case "authors": return "autor"
      case "titles": return "naslov"
      case "keywords": return "ključna reč"
      case "publishers": return "izdavač"
      default: return ""
    }
  }

  function prefNameAbbreviation(prefName) {
    switch (prefName) {
      case "authors": return "AU"
      case "titles": return "TI"
      case "keywords": return "KI"
      case "publishers": return "PU"
      default: return ""
    }
  }

  const handleSearch = (event, autocomplete, keyUp = false) => {
    event.persist()
    if (keyUp) {
      let keyCode = event.keyCode || event.which
      if (keyCode !== 13) {
        return
      } else {
        setKeyUp(true)
      }
    }

    let singleSearchResult

    if (autocomplete) {
      singleSearchResult = event.currentTarget
      let searchText = singleSearchResult

        .querySelector("span")
        .textContent.toLowerCase()

      navigate(
        `/pretraga?oper1=AND&pref1=${singleSearchResult.getAttribute(
          "data-prefname"
        )}&text1=${searchText}`
      )
    } else {
      singleSearchResult = searchInputField.current.state.value.toLowerCase()
      if (chosenDepartment) {
        // console.log(`/pretraga?pref1=PU&text1=""&oper1=OR&pref2=TI&text2=${singleSearchResult}*&oper2=OR&pref3=AU&text3=${singleSearchResult}*&oper3=OR&pref4=PY&text4=${singleSearchResult}*&oper4=OR&departments=${chosenDepartment}`);
        navigate(`/pretraga?pref1=PU&text1=""&oper1=OR&pref2=TI&text2=${singleSearchResult}*&oper2=OR&pref3=AU&text3=${singleSearchResult}*&oper3=OR&pref4=PY&text4=${singleSearchResult}*&oper4=OR&departments=${chosenDepartment}`)
      } else {
        // console.log(`/pretraga?pref1=PU&text1=""&oper1=OR&pref2=TI&text2=${singleSearchResult}*&oper2=OR&pref3=AU&text3=${singleSearchResult}*&oper3=OR&pref4=PY&text4=${singleSearchResult}*&oper4=OR`);
        navigate(`/pretraga?pref1=PU&text1=""&oper1=OR&pref2=TI&text2=${singleSearchResult}*&oper2=OR&pref3=AU&text3=${singleSearchResult}*&oper3=OR&pref4=PY&text4=${singleSearchResult}*&oper4=OR`)
      }
    }

    searchInputField.current.state.value = ""
    setIsSearching(false)

  }

  return (
    <Row gutter={24}>
      <Col span={20}>
        <Input
          ref={searchInputField}
          size="large"
          addonAfter={searchDepartmentsList}
          onKeyUp={event => {
            setSearchTerm(event.target.value)
            handleSearch(event, false, true)
          }}
          defaultValue=""
          placeholder={t('translation:searchInput:unesite-naziv-knjige-ime-autora-ili-kljucnu-rec')}
        />

        <div
          ref={autocompleteWrapper}
          className={`autocomplete-suggestions ${
            results.length ? styles.hasResults : ""
          } ${!isSearching ? styles.dropdownClosed : ""}`}>
          {results &&
              results.map((result, index) => (
                <div
                  className={styles.searchResultWrapper}
                  key={index}
                  onClick={event => handleSearch(event, true)}
                  data-prefname={prefNameAbbreviation(result.prefName)}>
                  <Highlighter
                    searchWords={[debouncedSearchTerm]}
                    highlightStyle={{
                      backgroundColor: "#fff",
                      fontWeight: "bold",
                      padding: 0
                    }}
                    textToHighlight={convert(result.value)}
                  />
                  <span className={styles.prefName}>
                    {renderPrefName(result.prefName)}
                  </span>
                </div>
              ))}
        </div>
      </Col>
      <Col span={4}>
        <Button
          block
          type="primary"
          style={{height: "40px"}}
          onClick={event => handleSearch(event, false)}>
          {t('translation:searchInput:pretrazite-katalog')}
        </Button>
      </Col>
    </Row>
  )
}

function searchCharacters(search) {
  return axios
    .post(
      "https://app.bisis.rs/bisisWS/opac/autocomplete",
      search,
      customOptions
    )
    .then(res => {
      return res
    })
    .catch(error => {
      // console.log(error);
      throw new Error(error)
    })
}

export default SearchInput

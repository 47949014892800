import React, { useState, useCallback, Fragment } from 'react'
import styles from './ProcuredBooks.module.css'
import useFetch from '../../../hooks/useFetch'
import SingleLink from '../../SingleLink/SingleLink'
import chunk from 'lodash.chunk'
import { Row, Typography, Carousel, Skeleton, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import textTranslation from "./../../../textTranslation"
const { Title } = Typography



export default function MobileProcuredBooks({ url }) {
	const { t } = useTranslation( "translation" )
	const [ref, setRef] = useState( null )

	const carousel = useCallback( ref => setRef( ref ), [] )
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	const { response, isLoading } = useFetch( url )

	return (
		<>
			<Skeleton active={ true } loading={ isLoading }/>

			<Carousel
				slidesToShow={1}
				ref={ carousel }
				dots={ false }
			>

				{response.map( row => {
					let month = row.acf.month
					let year = row.acf.year
					let length = row.acf.book.length
					return chunk( row.acf.book, 5 ).map( books => (
							<Fragment>
								
								<Row
									type='flex'
									justify='center'
									align='middle'
									style={{
										marginBottom: '32px',
										marginTop: '4px',
										display: "flex",
										justifyContent: "space-between"
										}}
									>
									
									<Title
										level={4}
										className={ styles.title }
										style={{ fontSize: "13px" }}
									>
										{ textTranslation(month) } - { year } ({ length } {t('translation:procuredTitles:prinovljenih-naslova')})
									</Title>
									
									<SingleLink 
										className={ styles.singlelink }
										to='/naslovi/prinovljeni-naslovi'
										title={t('translation:procuredTitles:pogledaj-sve')}
									/>

								</Row>
								
								{books.map(( book, id ) => (
									<Row 
										key={ id }
										gutter={24} 
										className={ styles.row }
									>
										<div
											style={{
												width: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between'
											}}
										>
											<span className={ styles.author }>
												{ textTranslation(book.acf.author) }
											</span>
											<span className={ styles.ganre }>
												{ book.acf.genre ? textTranslation(book.acf.genre.post_title) : "/" }
											</span>	
										</div>

										<div className={ styles.bookInfo }>
											{ textTranslation(book.post_title) }
										</div>
									
									</Row>
								))}

							</Fragment>
						)
					)
				})}
			</Carousel>
			
			<div 
				className={ styles.actions }
				style={{
					display: window.screen.width >= 600 ? "flex" : "none", 
					justifyContent: "center"
				}}	
			>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ prev }
						shape="circle"
						icon="left"
					/>
				</span>
				<span>
					<Button
						className={ styles.actionItem }
						onClick={ next }
						shape="circle"
						icon="right"
					/>
				</span>
			</div>

		</>
	)
}

import React from "react"
import styles from "./Header.module.css"
import { Link } from "@reach/router"
import { Parser } from "html-to-react"
import useFetch from "../../hooks/useFetch"

//components
import Logo from "../Logo/Logo"
import SearchInput from "../SearchInput/SearchInput"
import MainNav from "../MainNav/MainNav"
import AdditionalNav from "../AdditionalNav/AdditionalNav"

const Header = props => {
  const htmlToReact = new Parser()

  const { response: contact, isLoading: contactLoading } = useFetch(
    "/wp-json/wp/v2/settings?slug=podesavanja"
  )

  const annoucment = <div style={{ color:"#dbab42", backgroundColor:"#EFB22C", fontWeight:"bold",padding:"6px"}}>
  <Link style={{textDecoration: "underline", maxWidth: "1200px", margin: "0 auto", color:"#f7f7f5", display:"block"}} to={`/aktivnosti/${contact[0]?.acf?.important_news[0]?.ID && contact[0].acf.important_news[0].ID}`}> {contact[0]?.acf?.important_news[0]?.post_title && htmlToReact.parse(contact[0].acf.important_news[0].post_title)}
 </Link>
</div>

  return (
    <header className={ styles.mobileHidden }>
      {contact[0]?.acf?.important_news[0]?.post_title && annoucment}
      
      <div className={ styles.container }>
        <div className={ styles.inner }>  
          
          <Link to="/">
            <Logo />
          </Link>
          
          <div className={ styles.right }>
            <div className={ styles.nav }>
              <AdditionalNav location={ props.location }/>
            </div>
            <div className={ styles.mainNav }>
              <MainNav location={ props.location }/>
            </div>
          </div>
        
        </div>
      </div>
      
      <div className={ styles.search }>
        <div className={ styles.container }>
          <SearchInput />
        </div>
      </div>
    
    </header>
  )
}

export default Header

import React from "react";
import Layout from "../layouts/Layout";
import './../components/Activity/FeaturedActivity/MobileFeaturedActivity.module.css' 
import { Row, Col } from "antd";
import MobileFeaturedActivity from "../components/Activity/FeaturedActivity/MobileFeaturedActivity";
import MobileNextActivities from "../components/Activity/NextActivities/MobileNextActivities";
import Survey from '../components/Survey/Survey'
import SideBaner from "../components/SideBaner/SideBaner";
import MobileForBooksLovers from "../components/ForBookLovers/MobileIndex";
import SingleLink from './../components/SingleLink/SingleLink'
import { useTranslation } from 'react-i18next'
import MetaDecorator from "../metaDecorator";

function MobileIndex() {
	const { t } = useTranslation("translation")

	return (
		<Layout>
			<MetaDecorator />
			<MobileFeaturedActivity />
			<MobileNextActivities />
			<SingleLink
				style={{ margin: '20px auto', display: 'block', textAlign: 'center', width: '150px' }}
				to='/aktivnosti'
				title={t('translation:mobileIndex:sve-aktivnosti')}
			/>
			<Row>
				<Col md={24}>
					<MobileForBooksLovers />
				</Col>
			</Row>
			<Row style={{ marginBottom: "50px" }}>
				<Col style={{ marginTop: "50px" }}>
					<Survey />
				</Col>
			</Row>
			<Row>
				<Col md={4}>
					<SideBaner />
				</Col>
			</Row>
		</Layout>
	);
}

export default MobileIndex;

import React, { useState } from "react"
import SingleLocation from "./SingleLocation"
import StreetMap from './StreetMap.js'
import { Row, Col, Button, Empty, Layout } from "antd"
import { useTranslation } from 'react-i18next'

import style from "./Location.module.css"

const { Content } = Layout
const ButtonGroup = Button.Group
const LocationContainer = props => {
  const { t } = useTranslation("translation")
  const [view, setView] = useState("list")
  const toggleView = e => {
    setView(e.target.name)
  }

  return (
    <>
      <Row justify="center" type="flex" className="mobileTitleRow">
        <Col xs={ 12 } className={ style.mobileTitle }>
          <h3>{ t('translation:locationContainer:lokacije') }</h3>
        </Col>
        <Col xs={ 12 } md={{ span: 12 }}>
          <ButtonGroup className={ style.buttonGroup }>
            <Button
              className={
                view === "list"
                  ? `${style.toggleButtonActive}`
                  : `${style.toggleButtonDisabled}`
              }
              onClick={ e => toggleView(e) }
              name="list">
              { t('translation:locationContainer:lista') }
            </Button>
            <Button
              className={
                view === "map"
                  ? `${style.toggleButtonActive}`
                  : `${style.toggleButtonDisabled}`
              }
              onClick={ e => toggleView(e) }
              name="map">
              { t('translation:locationContainer:mapa') }
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Content className={style.locationContainer}>
        { view === "list" ? (
          props.data[0] ? (
            <SingleLocation
              locations={ props.checkedLanguages.length ? props.data : props.data[0].acf.locations }
            />
          ) : (
            <Empty
              description={ <span>{ t('translation:locationContainer:nema-rezultata-za-izabranu-pretragu') }</span> }
            />
          )
        ) : (
          props.data[0] ? ( 
          <StreetMap locations={ props.checkedLanguages.length ? props.data : props.data[0].acf.locations }/>
          ) : (
            <Empty
            description={ <span>{ t('translation:locationContainer:nema-rezultata-za-izabranu-pretragu') }</span> }
          />
          )
          )}
      </Content>
    </>
  )
}

export default LocationContainer
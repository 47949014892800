import React, { Fragment, useState } from "react"
import axios from "axios"
import { Row, Col, Icon, Button, Popover, notification } from "antd"
import apiUrl from "../../apiUrl"
import ModalSubscribe from "../ContactModal/modalSubscribe"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { Parser } from "html-to-react"

import styles from "./SingleActivityInfo.module.css"
import MetaDecorator from "../../metaDecorator"

const SingleActivityInfo = props => {
  const {
    link,
    title,
    date,
    time,
    location,
    coverPhoto,
    bookTitle,
    author,
    others,
    music,
    description,
    about,
    activityId,
    allowParticipants,
    stillActive,
    category
  } = props
  
  const htmlToReact = new Parser()
  const [modalVisible, setModalVisible] = useState( false )
  const [confirmLoading, setConfirmLoading] = useState( false )
  const closeModal = () => setModalVisible( false )
  const { t } = useTranslation("translation")

  const content = (
    <div className={ styles.socialMedia }>
      <LinkedinShareButton url={ link }>
        <Icon style={{ color: "#FF595E", margin: "0 4px" }} type="linkedin" />
      </LinkedinShareButton>
      <TwitterShareButton url={ link }>
        <Icon style={{ color: "#FF595E", margin: "0 4px" }} type="twitter" />
      </TwitterShareButton>
      <FacebookShareButton url={ link }>
        <Icon style={{ color: "#FF595E", margin: "0 4px" }} type="facebook" />
      </FacebookShareButton>
    </div>
  )

  const subscribeActivityData = ( full_name, email, subject, message, g_recaptcha_response, activity_id ) => {
    return {
      full_name,
      email,
      subject,
      message,
      g_recaptcha_response,
      activity_id
    }
  }

  const handleClick = ({ full_name, email, subject, message }, recaptchaKeyResponse) => {
    setConfirmLoading( true )
    const data = subscribeActivityData( full_name, email, subject, message, recaptchaKeyResponse, activityId )

    axios.post(`${apiUrl}/wp-json/gbns/v2/activities/register`, JSON.stringify(data)).then( e => {
      setConfirmLoading( false )
      setModalVisible( false )
      notification.success({
        message: e.data.message
      })
    }).catch( e => {
      setConfirmLoading( false )
      if ( e.response && e.response.data && e.response.data.message ) {
        e.response.data.message.forEach( message => {
          notification.warning({ message: message })
        })
      } else {
        notification.warning({ message: t('translation:singleActivityInfo:doslo-je-do-greske') })
      }
    })
  }
  return (
    <Fragment>

      <Row style={{ marginTop: "10px" }}>
      
        <Col span={ 14 }>
          <h1 className={ styles.title }>{ textTranslation(title) } </h1>
        </Col>
      
        <Col span={ 10 }>
          { category === 'ОБАВЕШТЕЊА' ? ( 
            <div></div>
          ) : (
            <div>
              <div className={ styles.dateAndTime }>
                <Icon
                  size="large"
                  className={ styles.clockIcon }
                  type="clock-circle"
                />
                <div className={ styles.time }>
                  <span>{ date }</span>
                  <br />
                  <span>{ time }</span>
                </div>
              </div>
    
              <div className={ styles.location }>
                <Icon size="large" className={ styles.pinIcon } type="environment" />
                <div className={ styles.address }>
                  <span>{ textTranslation(location) }</span>
                </div>
              </div>
            </div>
          )}
         
        </Col>
      
      </Row>
      
      <Row style={{ fontSize: "18px" }} gutter={24}>
      
        <Col span={11}>
          <div
            style={{ backgroundImage: `url(${coverPhoto})` }}
            className={ styles.coverPhoto }></div>
        </Col>
      
        <Col className={ styles.activityInfo } span={13}>
          <div className={ styles.block }>
            <h3 className={ styles.bookTitle } style={{ width: "80%" }}>{ textTranslation(bookTitle) }</h3>
            <span className={ styles.infoSectionCaption }>{ textTranslation(author) }</span>
          </div>
    
          {others && <div className={ styles.block }>
            {/* <p className={ styles.bold }>{t('translation:singleActivityInfo:o-romanu-govore')}</p> */}
            <div className={ styles.infoSectionCaption }>{ textTranslation(others) }</div>
          </div>}
    
          {music && <div className={ styles.block }>
            {/* <p className={ styles.bold }>{t('translation:singleActivityInfo:muzicka-pratnja')}</p> */}
            <div className={ styles.infoSectionCaption }>{ textTranslation(music) }</div>
          </div>}
    
          { about && <div className={ styles.block }>
            <div>{ about }</div>
          </div>}
    
          { allowParticipants &&
            <div className={ styles.block }>
              <h3 className={ styles.subscribe }>{t('translation:singleActivityInfo:prijava')}</h3>
              <p className={ styles.infoSectionCaption }>
              {t('translation:singleActivityInfo:molimo-vas-da-vase-prisustvo-prijavite-klikom-na-dugme-ispod')}
            </p>
              <Button
                disabled={ stillActive } 
                className={ styles.btnSubscribe } 
                type="danger" 
                onClick={() => setModalVisible(!modalVisible)}
              >
                {t('translation:singleActivityInfo:prijavite-se')}
              <Icon type="arrow-right" />
              </Button>
            </div>
          }
    
          <div className={ styles.share }>
            <p>{t('translation:singleActivityInfo:podelite-aktivnost')}</p>
            <Popover placement="left" content={ content } trigger="click">
              <Icon className={ styles.shareIcon } type="share-alt" />
            </Popover>
          </div>
      
        </Col>
      
      </Row>
      
      <ModalSubscribe
        title={t('translation:singleActivityInfo:prijava')}
        visible={ modalVisible }
        onCancel={ closeModal }
        handleClick={ handleClick }
        confirmLoading={ confirmLoading }
      />

    </Fragment>
  )
}

export default SingleActivityInfo
import React, { useState, useEffect, useCallback } from "react"
import styles from "./Activities.module.css"
import mobileStyles from "./MobileActivities.module.css"
import { Row, Skeleton, List, Empty} from "antd"
import { Parser } from "html-to-react"

import WithSider from "../../layouts/WithSider"
import ActivityItem from "../../components/ActivityItem/ActivityItem"
import useFetch from "../../hooks/useFetch"
import CustomButton from "../../components/CustomButton/CustomButton"

import useWindowSize from "../../hooks/useWindowSize"
import { useTranslation } from 'react-i18next';
import MetaDecorator from "../../metaDecorator"
import textTranslation from "../../textTranslation"



function Notifications( props ) {
  const { t } = useTranslation("translation")
  const htmlToReact = new Parser()

  const fetchUrl = `/wp-json/gbns/v2/activities/filtered?type=обавештења`

  const { response, isLoading } = useFetch( fetchUrl )

  const [initLoading, setInitLoading] = useState( true )
  const [filterActive, setFilterActive] = useState( false )
  const [activitiesFilterResults, setActivitiesFilterResults] = useState( [] )
  const [currentPage, setCurrentPage] = useState( 1 )
  const [disabled, setDisabled] = useState( false )
  const [wpTotalItems, setWpTotalItems] = useState( null )
  const [wpTotalPages, setWpTotalPages] = useState( null )
  const [activitiesList, setActivitiesList] = useState( [] )
  const [fetchMore, setFetchMore] = useState( false )
  const [resultsLoading, setResultsLoading] = useState( false )
  const [loadMoreClicked, setLoadMoreClicked] = useState( false )

  const [term, setTerm] = useState( '' )
  const [filteredData, setFilteredData] = useState( [] )

  const apiUrl = process.env.REACT_APP_API_URL
  const fullUrl = `${apiUrl}${fetchUrl}&per_page=10&page=1&lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`

  const size = useWindowSize()

  function handleLoadMore() {
    if ( currentPage < wpTotalPages ) {
      setCurrentPage( currentPage + 1 )
      setResultsLoading( true )
      setFetchMore( true )
      setLoadMoreClicked( true )
    }
  }
 
  useEffect(() => {
    if ( typeof wpTotalPages === "number" ) {
      if ( currentPage >= wpTotalPages || isNaN( wpTotalPages )) {
        setDisabled( true )
      } else {
        if ( disabled ) {
          setDisabled(false)
        }
      }
    }
  }, [currentPage, wpTotalItems, wpTotalPages, disabled])


  const getTotals = useCallback(() => {
    return fetch(
      fullUrl
    ).then( res => {
      setWpTotalItems( parseInt(res.headers.get("X-WP-Total")) )
      setWpTotalPages( parseInt(res.headers.get("X-WP-TotalPages")) )
      return res.json()
    })
  }, [fullUrl])

  useEffect(() => {
    getTotals()
  }, [getTotals])

  useEffect(() => {
    setLoadMoreClicked( false )
  }, [activitiesFilterResults])

  useEffect(() => {
    setCurrentPage( 1 )
    if ( term !== '' ) {
      fetch(
        fullUrl
      )
      .then( response => {
        setWpTotalItems( parseInt(response.headers.get("X-WP-Total")) )
        setWpTotalPages( parseInt(response.headers.get("X-WP-TotalPages")) )
        return response.json()
      })
      .then( resp => {
        if ( resp.length > 0 ) setFilteredData( resp )
        else setFilteredData( [] )
      })
      .catch( err => console.error( err ) )
    } 
    else {
      setFilteredData( [] )
    }
  }, [term, apiUrl])

  useEffect(() => {
    if ( filteredData.length !== 0 && term.length !== 0 ) {
      setInitLoading( false )
      setActivitiesList( filteredData )
    } else if ( filteredData.length === 0 && term.length !== 0 ) {
      setInitLoading( false )
      setActivitiesList( [] )
    } 
    else if ( filteredData.length === 0 ) {
      if ( response && response.length ) {
        setInitLoading( false )
        setActivitiesList( response )
      }
    } 
  }, [response, filteredData, term])

  useEffect(() => {
    if ( filterActive ) {
      if ( loadMoreClicked ) {
        if ( activitiesFilterResults.length ) {
          setActivitiesList( prevState => {
            return [...prevState, ...activitiesFilterResults]
          })
        } else {
          setActivitiesList( [] )
        }
      } else {
        setWpTotalItems( wpTotalItems )
        setWpTotalPages( wpTotalPages )
        setActivitiesList(() => {
          return [...activitiesFilterResults]
        })
      }
      setFilterActive( false )
    } else {
      setActivitiesList( prevState => {
        return [...prevState, ...activitiesFilterResults]
      })
    }
  }, [activitiesFilterResults, wpTotalItems, wpTotalPages])
     
  const loadMore = !isLoading ? (
    <CustomButton
      click={ handleLoadMore }
      isLoading={ isLoading }
      disabled={ disabled }
      className={ mobileStyles.customButton }>
      {t('translation:activities:prikazite-jos')}
    </CustomButton>
  ) : null

  const locale = {
    emptyText: (
      <Empty
        image={ Empty.PRESENTED_IMAGE_SIMPLE }
        className={ styles.noResults }
        imageStyle={{ height: 60 }}
        description={ <span>{t('translation:activities:nema-rezultata')}</span> }
      ></Empty>
    )
  }

  return size.width > 991 ? (
    <WithSider
      siderStyle = {{display: "none"}}
      siderTitle={t('translation:activitySearch:obavestenja')}
      content={
        <>
          <MetaDecorator 
            title = { textTranslation("Обавештења") }
          />
          <Row style={{ marginBottom: "15px" }}>
          <h1
            style={{
              fontFamily: '"Tinos", serif',
              color: "#292929",
              fontSize: "30px",
              fontWeight: "bold",
              lineHeight: "40px"
            }}
          >
            {t('translation:activitySearch:obavestenja')}
          </h1>
        </Row>
        <Row style={{ marginBottom: 40 }}>
          {initLoading ? (
            <Skeleton
              active={ true }
              loading={ true }
              paragraph={{ rows: 10 }}
            ></Skeleton>
          ) : (
            <List
              loading={ isLoading }
              itemLayout="horizontal"
              dataSource={ activitiesList }
              loadMore={ loadMore }
              locale={ locale }
              renderItem={ activity => (
                <ActivityItem
                  key={ activity.id }
                  id={ activity.id }
                  category={ activity.acf.type }
                  title={ htmlToReact.parse(activity.title.rendered) }
                  description={ activity.acf.about }
                />
              )}
            ></List>
          )
          }
        </Row>
        </>
      }
    />
  ) : (
    <div style={{ padding: "20px" }}>
      <MetaDecorator 
        title = { textTranslation("Обавештења") }
      />
      <Row style={{ marginBottom: "15px" }}>
        <h1
          style={{
            fontFamily: '"Tinos", serif',
            color: "#292929",
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "40px"
          }}
        >
          {t('translation:activitySearch:obavestenja')}
        </h1>
      </Row>
      <Row>
        {isLoading ? (
          <Skeleton
            active={ true }
            loading={ true }
            paragraph={{ rows: 10 }}
          ></Skeleton>

        ) : (

          <List
            loading={ isLoading }
            itemLayout="horizontal"
            dataSource={ activitiesList }
            loadMore={ loadMore }
            locale={ locale }
            renderItem={ activity => (
              <ActivityItem
                key={ activity.id }
                id={ activity.id }
                category={ activity.acf.type }
                title={ htmlToReact.parse( activity.title.rendered ) }
                description={ activity.acf.about }
              />
            )}
          ></List>
        )}
      </Row>

      <div
        className={ mobileStyles.activitiesFiltersWrapper }
        style={{ display: "none" }}
      >
      </div>

    </div>
  )
}

export default Notifications
import React from "react"
import { Typography } from "antd"
import { navigate } from '@reach/router'
import textTranslation from '../../textTranslation'

import styles from "./LocationCard.module.css"
import MetaDecorator from "../../metaDecorator"
import { useTranslation } from 'react-i18next'

const { Paragraph } = Typography

function LocationCard({ data }) {
  const { t, i18n } = useTranslation("translation")
  
  return (
    <div onClick={() => navigate(`/o-nama/mreza-ogranaka/${data.acf.location_type}/${data.ID}`, { state: { 
      props: data } 
    })} 
      className={ styles.card } 
      style={{ cursor: "pointer" }}
      >
      <div
        className={ styles.coverImg } 
        style={{ backgroundImage: `url(${data.acf.image ? data.acf.image.sizes.medium : ""})` }}
      >
        <MetaDecorator 
          title = { t(`translation:locationPage:${data.acf.location_type}`)}
        />
      </div>
      <Paragraph 
        ellipsis={{ rows: 2 }} 
        className={ styles.city }
      >
        { textTranslation(data.post_title) } {!!data.acf.city && <>({ textTranslation(data.acf.city) })</>}
      </Paragraph>
    </div>
  )
}
export default LocationCard

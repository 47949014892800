import React, { useState, useEffect, useRef } from "react"
import styles from "./MobileHeader.module.css"
import { Link } from "@reach/router"

//components
import Logo from "../Logo/Logo"
import MobileSearchInput from "../SearchInput/MobileSearchInput"
import MobileMainNav from "../MainNav/MobileMainNav"
import MobileAdditionalNav from "../AdditionalNav/MobileAdditionalNav"
import { Parser } from "html-to-react"
import useFetch from "../../hooks/useFetch"

const MobileHeader = props => {
  const [ showMobileMenu, setShowMobileMenu ] = useState( false )
  const mobileMenu = useRef( null )
  const handleMenuClick = () => setShowMobileMenu( !showMobileMenu )
  const htmlToReact = new Parser()
  const { response: contact, isLoading: contactLoading } = useFetch(
    "/wp-json/wp/v2/settings?slug=podesavanja"
  )
  const annoucment = <div style={{ color:"#dbab42", backgroundColor:"#EFB22C", fontWeight:"bold",padding:"6px"}}>
  <Link style={{textDecoration: "underline", maxWidth: "1200px", margin: "0 auto", color:"#f7f7f5", display:"block"}} to={`/aktivnosti/${contact[0]?.acf?.important_news[0]?.ID && contact[0].acf.important_news[0].ID}`}> {contact[0]?.acf?.important_news[0]?.post_title && htmlToReact.parse(contact[0].acf.important_news[0].post_title)}
 </Link>
</div>
  
  useEffect(() => {
    const pageWrapper = mobileMenu.current.parentNode
    if (pageWrapper) {
      if ( showMobileMenu ) pageWrapper.style.overflow = "hidden"
      else pageWrapper.style.overflow = "auto"
    }
  }, [ showMobileMenu ])

  return (
    <React.Fragment>
      {contact[0]?.acf?.important_news[0]?.post_title && annoucment}

      <div
        className={ `${styles.mobileNavMenu} ${showMobileMenu ? styles.menuOpen : ""}` } 
        ref={ mobileMenu }
      >
        <div className={ styles.logoWrapper }>
          
          <Link to="/">
            <Logo />
          </Link>
          <div className={ styles.navMenuIcon } onClick={ handleMenuClick }>
            <span/>
          </div>
        
        </div>
        
        <div className={ `${styles.mobileMenuContent} ${showMobileMenu ? "" : styles.displayNone}` }>
          <div className={styles.divider}/>
          
          <MobileAdditionalNav />
          
          <div>
            <div>
              <MobileMainNav
                location={ props.location }
                handleMenuClick={ handleMenuClick }
                handleContactClick={key => props.handleContactClick(key)}
              />
            </div>
          </div>
        
        </div>
      </div>
      
      <div className={ styles.mobileSearchWrapper }>
        <MobileSearchInput />
      </div>
    
    </React.Fragment>
  )
}

export default MobileHeader

import React, { useState, useCallback, Fragment } from "react"
import styles from "./MostRedBooks.module.css"
import { Carousel, Row, Col, Button, Skeleton } from "antd"
import useFetch from "../../../hooks/useFetch"
import chunk from "lodash.chunk"
import MostRedBook from "./MostRedBook"
import fallbackImage from "../../../hooks/fallbackImage"

export default ({ url }) => {
	let counter = 0

	const [ref, setRef] = useState(null)
	const [currentSelected, setCurrentSelected] = useState(1)

	const { response, isLoading } = useFetch(url)

	const carousel = useCallback( ref => setRef(ref), [])

	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	function setActive(id) { setCurrentSelected(id) }

	function spanRange(id) { 
		return currentSelected === id ? 9 : 3
	}

	return (
		<>
			<Skeleton active={ true } loading={ isLoading } />
			
			<Fragment>
				
				<Carousel ref={ carousel } dots={ false } style={{ height: 450 }} beforeChange={() => setCurrentSelected(0)}>
					
					{response.map( row => {
						return chunk( row.acf.book, 5 ).map(( books, i ) => {
							return (
								<Fragment key={i}>
									<Row gutter={24} style={{ display: "flex", justifyContent: books.length > 2 ? "center" : "flex-start" }}>
										
										{books.map(( b, id ) => {
											counter++
											return (
												<Col key={ id } span={ spanRange(id) }>
													<MostRedBook
														id={ b.ID }
														title={ b.post_title }
														author={ b.acf.author }
														imgSrc={ fallbackImage(b.acf.image) }
														info={ b.acf.about }
														setActive={ setActive }
														bckDrop={ counter }
														i={ id }
														active={ currentSelected === id }
														ganre={ b.acf.genre }
													/>
												</Col>
											)
										})}

									</Row>
								</Fragment>
							)
						})
					})}

				</Carousel>
				

				<div className={ styles.actions }>
					<span>
						<Button className={ styles.actionItem } onClick={ prev } shape="circle" icon="left" />
					</span>
					<span>
						<Button className={ styles.actionItem } onClick={ next } shape="circle" icon="right" />
					</span>
				</div>

			</Fragment>
		</>
	);
};

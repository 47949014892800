import React, { useState, useEffect } from "react"
import { Skeleton, List, Typography } from "antd"
import CustomButton from "../CustomButton/CustomButton"
import BookCard from "../BookCard/BookCard"
import DigitizedBookModal from "../DigitizedBookModal/DigitizedBookModal"
import { useTranslation } from 'react-i18next'
import styles from "./BookList.module.css"

const { Title } = Typography

const BookList = props => {
  const [currentPage, setCurrentPage] = useState(1)
  const [initLoading, setInitLoading] = useState(true)
  const [bookData, setBookData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [responseHeaders, setResponseHeaders] = useState(null)
  const { t } = useTranslation("translation")

  const apiUrl = process.env.REACT_APP_API_URL

  const perPage = window.screen.width <= 576 ? 4 : 10

  useEffect(() => {
    const createUrl = address => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
    ? `${address}&lang=` 
    : `${address}&lang=${localStorage.getItem('lang')}`


    setInitLoading(false)
    setCurrentPage(1)
    setBookData([])

    switch(props.category) {
      case "stampane-do-1867":
        fetch(createUrl(`${apiUrl}/wp-json/gbns/v2/digitized_books_until_1867?per_page=${perPage}&page=${currentPage}`))
          .then(response => {
            setResponseHeaders({
              totalItems:response.headers.get("X-WP-Total"), 
              totalPages:response.headers.get("X-WP-TotalPages")
            })

            return response.json()
          })
          .then(data => {
            if(data.code !== "error") {
              setBookData(data)
            }
            setIsLoading(false)
          })
        break
      case "stampane-od-1868":
        fetch(createUrl(`${apiUrl}/wp-json/gbns/v2/digitized_books_after_1868?per_page=${perPage}&page=${currentPage}`))
          .then(response => {
            setResponseHeaders({
              totalItems:response.headers.get("X-WP-Total"), 
              totalPages:response.headers.get("X-WP-TotalPages")
            })

            return response.json()
          })
          .then(data => {
            if(data.code !== "error") {
              setBookData(data)
            }
            setIsLoading(false)
          })
        break
        default:
          fetch(createUrl(`${apiUrl}/wp-json/gbns/v2/digitized_books_until_1867?per_page=${perPage}&page=${currentPage}`))
            .then(response => {
              setResponseHeaders({
                totalItems:response.headers.get("X-WP-Total"),
                totalPages:response.headers.get("X-WP-TotalPages")
              })

              return response.json()
            })
            .then(data => {
              if(data.code !== "error") {
                setBookData(data)
              }
              setIsLoading(false)
            })

    }
  }, [props.category])

  useEffect(() => {
    const createUrl = address => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') 
    ? `${address}&lang=`
    : `${address}&lang=${localStorage.getItem('lang')}`

    switch(props.category) {
      case "stampane-do-1867":
        fetch(createUrl(`${apiUrl}/wp-json/gbns/v2/digitized_books_until_1867?per_page=${perPage}&page=${currentPage}`))
          .then(response => {
            setResponseHeaders({
              totalItems:response.headers.get("X-WP-Total"),
              totalPages:response.headers.get("X-WP-TotalPages")
            })

            return response.json()
          })
          .then(data => {
            if(data.code !== "error") {
              setBookData(data)
              setBookData(bookData.concat(data))
            }
            setIsLoading(false)
          })
        break
      case "stampane-od-1868":
        fetch(createUrl(`${apiUrl}/wp-json/gbns/v2/digitized_books_after_1868?per_page=${perPage}&page=${currentPage}`))
          .then(response => {
            setResponseHeaders({
              totalItems:response.headers.get("X-WP-Total"),
              totalPages:response.headers.get("X-WP-TotalPages")
            })

            return response.json()
          })
          .then(data => {
            if(data.code !== "error") {
              setBookData(data)
              setBookData(bookData.concat(data))
            }
            setIsLoading(false)
          })
        break
        default:
        fetch(createUrl(`${apiUrl}/wp-json/gbns/v2/digitized_books_until_1867?per_page=${perPage}&page=${currentPage}`))
          .then(response => {
            setResponseHeaders({
              totalItems:response.headers.get("X-WP-Total"),
              totalPages:response.headers.get("X-WP-TotalPages")
            })

            return response.json()
          })
          .then(data => {
            if(data.code !== "error") {
              setBookData(data)
              setBookData(bookData.concat(data))
            }
            setIsLoading(false)
          })
    }
  }, [currentPage])

  useEffect(() => {
    if (
      responseHeaders &&
      responseHeaders.totalPages &&
      currentPage < responseHeaders.totalPages
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [responseHeaders, currentPage])

  function handleLoadMore() {
    if (currentPage < responseHeaders.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  function handleOpenModal(book) {
    setModalData(book)
    setIsModalOpen(true)
  }
  function handleCloseModal() {
    setIsModalOpen(false)
  }

  const ref = React.createRef();

  return (
    <>
      <Title level={ 2 } style={{ fontFamily: "Tinos" }}>{t(`translation:mainNav:${props.category}`)} </Title>
      {
        <List
          className={`magazineList ${styles.books}`}
          grid={{ gutter: window.screen.width <= 576 ? 20: 50, xs: 2, sm: 3, md: 4 }}
          loading={ initLoading }
          loadMore={
            !isLoading && (
              <CustomButton click={ handleLoadMore } isLoading={ isLoading } disabled={ disabled }>
                {t('translation:newBooks:prikazi-jos')}
              </CustomButton>
            )
          }
          dataSource={bookData}
          renderItem={item => (
            <Skeleton avatar title={false} loading={initLoading} active>
              {item.acf && <List.Item>
                <BookCard
                  title={ item && item.post_title }
                  author={ item.acf && item.acf.author }
                  imgSrc={ item.acf.pages && item.acf.pages[0].sizes.medium }
                  click={ () => handleOpenModal(item) }
                />
              </List.Item>}
            </Skeleton>
          )}
        />
      }

      { modalData && (
         <DigitizedBookModal
         forwardedRef={ref}
         isVisible={ isModalOpen }
         closeModal={ handleCloseModal }
         data={ modalData }
       />
      )}
    </>
  )
}

export default BookList

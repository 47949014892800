import React from "react";
import { Menu } from "antd";
import { Link } from "@reach/router";
import "./AllTitlesSidebar.module.css";
import { useTranslation } from 'react-i18next'

const { ItemGroup, Item } = Menu;

export default function AllTitlesSidebar(props) {
	const { t } = useTranslation("translation")
	const { uri } = props

	const renderMenu = () => {
		return <ItemGroup title={t('translation:allTitlesSidebar:za-ljubitelje-knjige')}>
			<Item key="/naslovi/najcitaniji-naslovi">
				<Link to={`/${uri.split("/")[1]}/najcitaniji-naslovi`}>{t('translation:allTitlesSidebar:najcitaniji-naslovi')}</Link>
			</Item>
			<Item key="/naslovi/novi-naslovi">
				<Link to={`/${uri.split("/")[1]}/novi-naslovi`}>{t('translation:allTitlesSidebar:nove-knjige')}</Link>
			</Item>
			<Item key="/naslovi/knjiga-meseca">
				<Link to={`/${uri.split("/")[1]}/knjiga-meseca`}>{t('translation:allTitlesSidebar:knjiga-meseca')}</Link>
			</Item>
			<Item key="/naslovi/prinovljeni-naslovi">
				<Link to={`/${uri.split("/")[1]}/prinovljeni-naslovi`}>{t('translation:allTitlesSidebar:prinovljeni-naslovi')}</Link>
			</Item>
			<Item key="/naslovi/preporuka-bibliotekara">
				<Link to={`/${uri.split("/")[1]}/preporuka-bibliotekara`}>{t('translation:allTitlesSidebar:preporuka-bibliotekara')}</Link>
			</Item>
		</ItemGroup>
	};

	return (
		<Menu
			style={{ width: 256 }}
			defaultSelectedKeys={[`${props.address}`]}
			loading="true"
			className="allTitles"
			mode="inline">
			{renderMenu()}
		</Menu>
	);
}



import React, { Fragment, useState } from "react"
import { Row, Col, Icon, Button, Popover, notification } from "antd"
import axios from "axios"
import ModalSubscribe from "../ContactModal/modalSubscribe"
import apiUrl from "../../apiUrl"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

import styles from "./MobileSingleActivityInfo.module.css"
import MetaDecorator from "../../metaDecorator"

const SingleActivityInfo = props => {
  const { t } = useTranslation("translation")

  const {
    link,
    title,
    date,
    time,
    location,
    coverPhoto,
    bookTitle,
    author,
    others,
    music,
    description,
    about,
    activityId,
    allowParticipants,
    stillActive
  } = props
  const [modalVisible, setModalVisible] = useState( false )
  const [confirmLoading, setConfirmLoading] = useState( false )
  const closeModal = () => setModalVisible( false )

  const subscribeActivityData = ( full_name, email, subject, message, g_recaptcha_response, activity_id ) => {
    return {
      full_name,
      email,
      subject,
      message,
      g_recaptcha_response,
      activity_id
    }
  }

  const content = (
    <div className={ styles.socialMedia }>
      <LinkedinShareButton url={ link }>
        <Icon style={{ color: "#FF595E", margin: "0 4px" }} type="linkedin" />
      </LinkedinShareButton>
      <TwitterShareButton url={ link }>
        <Icon style={{ color: "#FF595E", margin: "0 4px" }} type="twitter" />
      </TwitterShareButton>
      <FacebookShareButton url={ link }>
        <Icon style={{ color: "#FF595E", margin: "0 4px" }} type="facebook" />
      </FacebookShareButton>
    </div>
  )

  const handleClick = ({ full_name, email, subject, message }, recaptchaKeyResponse) => {
    setConfirmLoading( true )
    const data = subscribeActivityData( full_name, email, subject, message, recaptchaKeyResponse, activityId )

    axios.post(`${apiUrl}/wp-json/gbns/v2/activities/register`, JSON.stringify( data )).then( e => {
        setConfirmLoading( false )
        setModalVisible( false )
        notification.success({
          message: e.data.message
        })
      }).catch( e => {
        setConfirmLoading( false )
        if ( e.response && e.response.data && e.response.data.message ) {
          e.response.data.message.forEach( message => {
            notification.warning({ message: message })
          })
        } else {
          notification.warning({ message: t('translation:surveyForm:doslo-je-do-greske') })
        }
      })
  }

  return (
    <Fragment>
      <MetaDecorator 
        description={ description }
        title = { title }
        image = { coverPhoto }
        url = { window.location.href }
      />

      <Row style={{ fontSize: "18px" }} gutter={24}>
      
        <Col span={24}>
          <div className={ styles.coverPhoto }>
            <img src={ coverPhoto } alt={ description } />
          </div>
        </Col>
      
      </Row>
      
      <Row>
        <Col span={24}>
          <h1 className={ styles.title }>{ textTranslation(title) }</h1>
        </Col>
      </Row>
      
      <Row style={{ marginBottom: "50px" }}>
      
        {(date || time) && <Col span={24}>
          <div className={ styles.dateAndTime }>
            <Icon
              size="large"
              className={ styles.clockIcon }
              type="clock-circle"
            />
            <div className={ styles.time }>
              <span>{ date }</span>
              <span>{ time }</span>
            </div>
          </div>
        </Col>}
      
        {location && <Col span={24}>
          <div className={ styles.location }>
            <Icon size="large" className={ styles.pinIcon } type="environment" />
            <div className={ styles.address }>
              <span>{ textTranslation(location) } </span>
            </div>
          </div>
        </Col>}
      
      </Row>
      
      <Row>
      
        <Col span={24}>
          <h2 className={ styles.subTitle }>{t('translation:singleActivityInfo:informacije-o-aktivnosti')}</h2>
          {author && <div className={ styles.block }>
            <h3 className={ styles.bookTitle }>{ textTranslation(bookTitle) }</h3>
            <span className={ styles.infoSectionCaption }>{ textTranslation(author) }</span>
          </div>}
      
          {others && <div className={ styles.block }>
            {/* <p className={ styles.bold }>{t('translation:singleActivityInfo:o-romanu-govore')}</p> */}
            <p className={ styles.infoSectionCaption }>{ textTranslation(others) }</p>
          </div>}
      
          {music && <div className={ styles.block }>
            {/* <p className={ styles.bold }>{t('translation:singleActivityInfo:muzicka-pratnja')}</p> */}
            <p className={ styles.infoSectionCaption }>{ textTranslation(music) }</p>
          </div>}
      
          {about && <div className={ styles.block }>
            <p className={ styles.infoSectionCaption }>{ about }</p>
          </div>}
      
          {description && <>
            <div className={ styles.block }>
              {/* <h3 className={ styles.about }>{t('translation:singleActivityInfo:o-romanu')}</h3> */}
            </div>
        
            <div className={ styles.block }>
              <p className={ styles.infoSectionCaption }>{ textTranslation(description) }</p>
            </div>
          </>}
          
          { allowParticipants &&
            <div className={ styles.block }>
              <h3 className={ styles.subscribe }>{t('translation:singleActivityInfo:prijava')}</h3>
              <p className={ styles.infoSectionCaption }>
                {t('translation:singleActivityInfo:molimo-vas-da-vase-prisustvo-prijavite-klikom-na-dugme-ispod')}
              </p>
              <Button 
              disabled={ stillActive }
              className={ styles.btnSubscribe } 
              type="danger" 
              onClick={() => setModalVisible(!modalVisible)}
              >
                {t('translation:singleActivityInfo:prijavite-se')}
                <Icon type="arrow-right" />
              </Button>
            </div>
            }
            
          <div className={ styles.share }>
            <p>{t('translation:singleActivityInfo:podelite-aktivnost')}</p>
            <Popover placement="left" content={ content } trigger="click">
              <Icon className={ styles.shareIcon } type="share-alt" />
            </Popover>
          </div>
      
        </Col>
      
      </Row>
      
      <ModalSubscribe
        title={t('translation:singleActivityInfo:prijava')}
        visible={ modalVisible }
        onCancel={ closeModal }
        handleClick={ handleClick }
        confirmLoading={ confirmLoading }
      />

    </Fragment>
  )
}

export default SingleActivityInfo
import React, { useState, useEffect } from "react"
import { Row, Col, Table, Spin, Empty, Modal, Button } from "antd"
import queryString from "query-string"
import axios from "axios"
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'
import { Parser } from 'html-to-react'

import styles from "./MobileSearchResults.module.css"

const SearchResults = () => {
	const { t } = useTranslation("translation")
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [numberOfElements, setNumberOfElements] = useState(null)
  const searchedTerm = queryString.parse(window.location.search)
  let searchModel = {}
  let entries = Object.entries(searchedTerm)
  const htmlToReact = new Parser()

  entries.forEach(entry => {
    let objectKey = entry[0]
    let objectValue = entry[1]
    searchModel[objectKey] = objectValue
  })

  const searchQuery = JSON.stringify(searchModel)

  const performSearch = (searchModel, pageNumber = 0) => {
    axios
      .post(
        "https://app.bisis.rs/bisisWS/opac/search",
        {
          searchModel
        },
        {
          headers: {
            "Content-Type": "application/json",
            Library: "gbns"
          },
          params: {
            pageNumber: pageNumber > 0 ? pageNumber - 1 : pageNumber,
            pageSize: 10
          }
        }
      )
      .then(res => {
        let results = res.data.content
        setSearchResults(results)
        setLoading(false)
        setNumberOfElements(res.data.totalElements)
        return res
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        Modal.error({
          title:
          t('translation:gallerySearch:doslo-je-do-greske-molimo-osvezite-stranicu-i-pokusajte-ponovo'),            
          onOk() {}
        })
      })
  }

  useEffect(() => {
    setLoading(true)

    performSearch(searchModel)
  }, [searchQuery])

  const columns = [
    {
      title: t('translation:searchResults:autor'),
      dataIndex: "autor",
      key: "autor",
      width: "25%",
      render: text => <span>{text}</span>
    },
    {
      title: t('translation:searchResults:naslov'),
      dataIndex: "naslov",
      key: "naslov",
      width: "45%"
    },
    {
      title: t('translation:searchResults:godina-izdavanja'),
      dataIndex: "godinaIzdavanja",
      key: "godinaIzdavanja",
      width: "30%"
    }
  ]

  let data = []

  if (searchResults && searchResults.length) {
    searchResults.map((result, index) => {
      let obj = {
        key: index,
        autor: textTranslation(htmlToReact.parse(result.authors)),
        naslov: textTranslation(result.title),
        slika: result.imageUrl,
        izdavac: textTranslation(result.publisher),
        godinaIzdavanja: result.publishYear,
        mestoIzdavanja: textTranslation(result.publishPlace),
        opis: textTranslation(htmlToReact.parse(result.description)),
        udk: textTranslation(result.udk),
        brojStrana: textTranslation(result.pagesCount),
        dimenzije: result.dimensions,
        napomene: textTranslation(result.notes)
      }
      data.push(obj)
      return data
    })
  }

  const locale = {
    emptyText: (
      <Empty
        image={ Empty.PRESENTED_IMAGE_SIMPLE }
        className={ styles.searchEmpty }
        imageStyle={{
          height: 60
        }}
        description={ <span>{ t('translation:searchResults:nema-rezultata-pretrage') }</span> }></Empty>
    )
  }

  const onPaginationChange = (page, pageSize) => {
    performSearch(searchModel, page)
  }

  return (
    <div
      style={{ textAlign: "center" }}
      className={`${styles.container} ${styles.searchResultsWrapper} ${
        loading ? styles.loadingInProgress : ""
      }`}>

      <a href="https://opac.bisis.rs/search/advanced-search" target="_blank" rel="noopener noreferrer">
        <Button 
          type="primary" 
          size="small"
          style={{ border: "none", margin: "4px" }}  
        >
          { t('translation:searchResults:napredna-pretraga-BISIS') }
        </Button>
      </a>

      { loading ? (
        <Spin />
      ) : (
        <Table
          columns={ columns }
          dataSource={ data }
          locale={ locale } 
          image={ Empty.PRESENTED_IMAGE_SIMPLE }
          rowClassName={ styles.singleResultRow }
          expandedRowRender={ record => {
            return (
              <div>
                {record.slika && (
                  <Row style={{ padding: "10px" }}>
                    <Col style={{ textAlign: "left" }}>
                      <div className={ styles.imageWrapper }>
                        <img
                          src={ record.slika }
                          alt={`${record.naslov} - ${record.autor}`}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {record.opis && (
                  <Row style={{ padding: "10px" }}>
                    <Col span={ 6 }>
                      <strong>{ t('translation:searchResults:opis') }</strong>
                    </Col>
                    <Col span={ 18 }>
                      <p>{ record.opis }</p>
                    </Col>
                  </Row>
                )}
                <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:izdavac') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.izdavac }</Col>
                </Row>
                <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:mesto-izdavanja') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.mestoIzdavanja }</Col>
                </Row>
                <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:udk') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.udk }</Col>
                </Row>
                <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:broj-strana') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.brojStrana }</Col>
                </Row>
                <Row style={{ padding: "10px" }}>
                  <Col span={ 6 }>
                    <strong>{ t('translation:searchResults:dimenzije') }</strong>
                  </Col>
                  <Col span={ 18 }>{ record.dimenzije }</Col>
                </Row>
                {record.napomene && (
                  <Row style={{ padding: "10px" }}>
                    <Col span={ 6 }>
                      <strong>{ t('translation:searchResults:napomene') }</strong>
                    </Col>
                    <Col span={ 18 }>{ record.napomene }</Col>
                  </Row>
                )}
              </div>
            )
          }}
          expandRowByClick={ true }
          pagination={{
            defaultPageSize: 10,
            total: numberOfElements,
            onChange: onPaginationChange,
            hideOnSinglePage: true,
            size: "small"
          }}
        />
      )}
    </div>
  )
}

export default SearchResults
import React, { useEffect, useState } from 'react';
import { Row, Typography } from 'antd';
import styles from './UsefulLinks.module.css'
import SingleLink from '../SingleLink/SingleLink';
import { useTranslation } from 'react-i18next'
import textTranslation from '../../textTranslation'

const { Paragraph, Title } = Typography;

const UsefulLinks = (props) => {
	const { t} = useTranslation("translation")
  const [usefulLinks, setUsefulLinks] = useState(null)
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetch(
      `${apiUrl}/wp-json/wp/v2/useful_links?lang=${localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'sr' ? '' : localStorage.getItem('lang')}`
    )
      .then(response => response.json())
      .then(data => {
        if(data.code !== "error") {
          setUsefulLinks(data)
        }
      })
  },[])

  return(
    <Row>
      {props.mobile && <Title className={styles.mainTitle} level={3}>{t('translation:usefulLinks:korisni-linkovi')}</Title>}
      {usefulLinks && usefulLinks.map(el => {
        return (
        <div className={styles.usefulLinkWrapper} key={el.id}>
          <div className={styles.usefulLinkImg}>
            <img src={el.acf.image ? el.acf.image.sizes.medium : ""} alt="" />
          </div>
          <div className={styles.usefulLinkDesc}>
            <Title className={styles.descTitle} level={4}>
              {textTranslation(el.title.rendered)}
            </Title>
            <Paragraph className={styles.descContent}>
              {textTranslation(el.acf.description)}
            </Paragraph>
            <SingleLink url={el.acf.link.url} title={t('translation:usefulLinks:posetite-sajt')} openInNewTab={true}/>
          </div>
        </div>)
      })}
    </Row>
  )
}

export default UsefulLinks
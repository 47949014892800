import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import './ContactModal.css';
import { useTranslation } from 'react-i18next';

import './ContactModal.css'

const { TextArea } = Input

function ContactModal( props ) {
  const { t } = useTranslation("translation")
  const { form } = props
  const { getFieldDecorator, validateFields } = form
  const [recaptchaKeyResponse, setrecaptchaKeyResponse] = useState( null )

  const onChange = responseKey => {
    setrecaptchaKeyResponse( responseKey )
  }

  const handleOk = () => {
    validateFields(( err, values ) => {
      if ( !err ) {
        props.contactModalProp( values, recaptchaKeyResponse )
      }
    })
  }

  return (
    <Modal
      className="contact-modal"
      { ...props }
      onOk={ handleOk }
      loading={ true }
      okButtonProps={{ disabled: recaptchaKeyResponse === null }}
      okText={ t('translation:contactModal:posaljite')} cancelText={t('translation:contactModal:nazad') }
    >
      { props.address && <span className="contact-modal-adresa">{ props.address }</span> }

      <Form layout="vertical">
        <Form.Item label={ t('translation:contactModal:ime-i-prezime') }>
          {getFieldDecorator("sender_full_name", {
            rules: [{ required: true, message: t('translation:contactModal:unesite-vase-ime-i-prezime') }]
          })(
            <Input size="large" placeholder={ t('translation:contactModal:unesite-vase-ime') }/>
          )}
        </Form.Item>

        <Form.Item label="Email">
          {getFieldDecorator("sender_email", {
            rules: [
              { required: true, message: t('translation:contactModal:unesite-vas-email') },
              { type: "email", message: t('translation:contactModal:unesite-vas-email')}
            ]
          })(
            <Input size="large" placeholder={ t('translation:contactModal:unesite-vas-email') }/>
          )}
        </Form.Item>

        <Form.Item label={ t('translation:contactModal:naslov-poruke') }>
          {getFieldDecorator("sender_subject", {
            rules: [{ required: true, message: t('translation:contactModal:unesite-naslov-vase-poruke') }]
          })(
            <Input size="large" placeholder={ t('translation:contactModal:unesite-naslov-vase-poruke') }/>
          )}
        </Form.Item>

        <Form.Item label={ t('translation:contactModal:poruka') }>
          { getFieldDecorator("sender_message", {
            rules: [{ required: true, message: t('translation:contactModal:unesite-vasu-poruku') }]
          })(
            <TextArea size="large" rows="6" placeholder={ t('translation:contactModal:unesite-vasu-poruku') } />
          )}
        </Form.Item>

        <span className="ant-form-item-required">{ t('translation:contactModal:obavezna-polja') }</span>

        <Form.Item>
          <ReCAPTCHA sitekey='6LeyAqAaAAAAAEmIaowm5Zb7Ydk2h0lQvRBuvezY' onChange={ onChange }/>
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default Form.create()(ContactModal);

import React from "react"
import FaceOfNsContent from "./FaceOfNsContent"
import FaceOfNsSider from "./FaceOfNsSider"
import WithSider from "../../layouts/WithSider"
import { useTranslation } from 'react-i18next';

function FaceOfNoviSad({ uri }) {
  const { t } = useTranslation("translation")

  return (
    <WithSider
      siderTitle={t('translation:faceOfNoviSad:lice-novog-sada') }
      sider={<FaceOfNsSider uri={uri} />}
      ignoreBottom={["contact"]}
      content={<FaceOfNsContent uri={uri} />}
    />
  )
}

export default FaceOfNoviSad

import React from 'react'
import useFetch from "../../hooks/useFetch"
import { Parser } from "html-to-react"
import textTranslation from '../../textTranslation'

import styles from "./PrivacyPolicy.module.css"

const PrivacyPolicy = () => {
  const htmlToReact = new Parser()
  const { response: privacy, isLoading: privacyLoading } = useFetch(
    "/wp-json/wp/v2/privacy_policies?slug=privacy&per_page=10&page=1&lang="
  )

  return (
    <div className={styles.privacy}>
      <h1>{ privacy[0]?.acf?.title && htmlToReact.parse(textTranslation(privacy[0].acf.title)) } </h1>    
      <div> { privacy[0]?.acf?.content && htmlToReact.parse(textTranslation(privacy[0].acf.content)) } </div>
    </div>
  )
}

export default PrivacyPolicy
import React, { useState, useCallback } from "react"
import style from './MostRedBooks.module.css'
import { Carousel, Button } from "antd"

import useFetch from "../../../hooks/useFetch"
import MobileMostRedBook from "./MobileMostRedBook"


export default ({ url }) => {
	const [ref, setRef] = useState( null )

	const carousel = useCallback( ref => setRef( ref ), [] )
	function next() { ref.slick.slickNext() }
	function prev() { ref.slick.slickPrev() }

	const { response } = useFetch( url )

	return (
		<>
			<Carousel ref={ carousel } dots={ false } adaptiveHeight={ true } className={ style.carousel } variableWidth={ true }>

				{ response.map( row => {
					return row.acf.book.map(( book, i ) => {
						return (
							<MobileMostRedBook
								id={ book.ID }
								title={ book.post_title }
								author={ book.acf.author }
								imgSrc={ book.acf.image ? book.acf.image.sizes.medium : ""}
								info={ book.acf.about }
								bckDrop={ i + 1 }
								ganre={ book.acf.genre }
							/>
						)})
				})}
			</Carousel>
			
			<div className={ style.actions } style={{ display: window.screen.width >= 600 ? "flex" : "none", justifyContent: "center" }}>
				<span>
					<Button className={ style.actionItem } onClick={ prev } shape="circle" icon="left" />
				</span>
				<span>
					<Button className={ style.actionItem } onClick={ next } shape="circle" icon="right" />
				</span>
			</div>
			
		</>
	)
}

import React from "react"
import { Typography } from "antd"
import textTranslation from '../../textTranslation'

import styles from "./BookCard.module.css"
import MetaDecorator from "../../metaDecorator";

const { Text, Title } = Typography;

function BookCard({ title, author, imgSrc, click}) {

  return (
    <div className={ styles.card }>
      {/* <MetaDecorator /> */}
      <div
        onClick={ (title && author && imgSrc && click) || undefined }
        className={styles.coverImg}
        style={{ backgroundImage: `url(${imgSrc})` }}
      ></div>
      <Title className={ styles.title } ellipsis={{ rows: 2 }} level={ 4 } onClick={ (title && author && imgSrc && click) || undefined }>
        { textTranslation(title) } 
      </Title>
      <Text className={ styles.author } type="secondary">
       { textTranslation(author) }
      </Text>
    </div>
  )
}

export default BookCard
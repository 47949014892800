import React from "react";
import { List, Typography } from "antd";
import WithSider from "../../layouts/WithSider";
import { Parser } from "html-to-react";
import PopularActivities from "../../components/PopularActivities/PopularActivities";
import UserGuidenceSidebar from "../../components/UserGuidenceSidebar";
import useFetch from "../../hooks/useFetch";
import PriceItem from "./PriceItem";
import textTranslation from "../../textTranslation"
import { useTranslation } from "react-i18next";
const { Title } = Typography;

export default function Prices({ uri }) {
	const { response, isLoading } = useFetch("/wp-json/wp/v2/price_categories");
	const htmlToReact = new Parser();
	const { t } = useTranslation("translation")

	return (
		<WithSider
			siderTitle={t('translation:userGuide:vodic-za-korisnike')}
			sider={<UserGuidenceSidebar uri={uri} />}
			content={
				<>
					{response.map(res => {
						return (
							<List
								style={{ marginBottom: "2rem" }}
								loading={isLoading}
								key={res.title.rendered}
								header={
									<Title level={3}>
										{htmlToReact.parse(textTranslation(res.title.rendered))}
									</Title>
								}
								dataSource={res.acf.prices}
								renderItem={item => <PriceItem item={item} />}
							/>
						);
					})}
				</>
			}
			moreContent={<PopularActivities />}
		/>
	);
}

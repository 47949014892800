import React, { useState, useEffect } from 'react'
import { Row, Col, Skeleton, Typography } from 'antd'
import WithSider from '../../layouts/WithSider'
import BookCard from "../../components/BookCard/BookCard"
import { navigate } from "@reach/router"
import UserGuidenceSidebar from "../../components/UserGuidenceSidebar"
import PopularActivities from "../../components/PopularActivities/PopularActivities"
import CustomButton from "../../components/CustomButton/CustomButton"
import SingleLink from "../../components/SingleLink/SingleLink"
import { useTranslation } from 'react-i18next'
import useFetch from '../../hooks/useFetch'
import LocationCard from '../../components/LocationCard/LocationCard'
import ActivityItem from '../../components/ActivityItem/ActivityItem'
import { Parser } from "html-to-react"

import styles from "./UserGuide.module.css"
import MetaDecorator from '../../metaDecorator'
import textTranslation from '../../textTranslation'

const apiUrl = process.env.REACT_APP_API_URL
const { Title } = Typography

function UserGuide({ uri, category }) {
	const [isLoading, setIsLoading] = useState( false )
	const [data, setData] = useState([])
	const [page, setPage] = useState( 1 )
	const [responseHeaders, setResponseHeaders] = useState( null )
	const [locationData, setLocationData] = useState(null)
	const [activities, setActivities] = useState(null)
	const { t } = useTranslation( "translation" )

	const htmlToReact = new Parser()

	const perPage = window.screen.width <= 576 ? 4 : 12

	useEffect(() => {
		setIsLoading( true )
		setPage( 1 )
		getBooksByCategory( category, 1, true )
	}, [category] )

	const getBooksByCategory = async( cat, page, reset = false ) => {
		try {
			const fetchUrl = new URL(`${apiUrl}/wp-json/gbns/v2/target_audience`)
			const params = { per_page: perPage, page: page, audience: cat }
			const langStorage = () => (localStorage.getItem("lang") === '' || localStorage.getItem("lang") === 'sr') ? '' : localStorage.getItem("lang")
			params.lang = langStorage()
			fetchUrl.search = new URLSearchParams(params).toString()
			const res = await fetch( fetchUrl )
			setResponseHeaders({
				totalItems: res.headers.get("X-WP-Total"),
				totalPages: res.headers.get("X-WP-TotalPages")
			})
			const json = await res.json()
			if (reset) {
				setData([...json])
			} else {
				setData([...data, ...json])
			}
			setIsLoading( false )
		} catch ( error ) {
			console.log( error )
			setIsLoading( false )
		}
	};

	const handleLoadMore = () => {
		getBooksByCategory( category, page + 1 )
		setPage( page + 1 )
	}

	const { response } = useFetch(
    "/wp-json/wp/v2/location_groups",
    {
      per_page: 30,
      page: 1
    }
	)
	useEffect(() => {
		const filteredData = filterLocations(response, category)
		setLocationData(filteredData)
	}, [response, category])

	function filterLocations(data, category) {
		if (category === 'odrasli') {
			return data.filter(el => el.acf.name === "Za odrasle")
		}
		else if (category === 'studenti') {
			return data.filter(el => el.acf.name === "Za studente")
		}
		else if (category === 'mladi') {
			return data.filter(el => el.acf.name === 'Za mlade')
		}
		else if (category === 'deca-i-roditelji') {
			return data.filter(el => el.acf.name === 'Za decu')
		}
	}

	const getActivities = target => {
		return fetch(
			`${apiUrl}/wp-json/gbns/v2/activities/filtered?per_page=10&page=1&lang=&target_audience=${target}`
		)
		.then(resp => resp.json())
		.then(response => setActivities(response))
	}
	
	const cyrCategory = function () {
		if(category === "mladi") {
			return "млади"
		}
		if(category === "deca-i-roditelji") {
			return "деца"
		}
		if(category === "studenti") {
			return "студенти"
		}
		if(category === "odrasli") {
			return "одрасли"
		}
	}

	useEffect(() => {
		getActivities(cyrCategory(category))
	}, [category])

	
	return (
		<WithSider
			siderTitle={ t('translation:userGuide:vodic-za-korisnike') }
			sider={
				<UserGuidenceSidebar
					category={ category }
					uri={ uri }
				/>
			}
			content={
				<>
				<MetaDecorator 
					title = { t(`translation:activitySearch:${category}`) }
				/>
					<Row
						type="flex"
						justify="space-between"
						style={{
							paddingBottom: "24px",
							alignItems: "center"
						}}
					>
						<Title level={ 3 } style={{ fontFamily: "Tinos" }}>
							{t(`translation:mobileMainNav:${category}`).split("-").join(" ").charAt(0).toUpperCase() + t(`translation:mobileMainNav:${category}`).split("-").join(" ").slice(1)}
						</Title>
						<SingleLink
							to="/naslovi/najcitaniji-naslovi"
							title={ t('translation:userGuide:svi-naslovi') }
							style={{ marginRight: "0" }}
						/>
					</Row>

					{ isLoading ? (
						<Row>
							<Skeleton />
						</Row>
					) :
						(
							<>
								<Row gutter={24} type="flex" className={ styles.booksrow }>
									{ data.map( ( book, index ) => (
										<Col lg={6} className={ styles.styledCol } key={ index } onClick={ () => navigate(`/knjiga/${book.id}`) }>
											<BookCard
												title={ book.post_title }
												imgSrc={ book.acf.image ? book.acf.image.url : "/" }
												author={ book.acf.author }
											/>
										</Col>
									))}
								</Row>
								<CustomButton
									click={ () => {
										handleLoadMore()
									}}
									isLoading={ isLoading }
									disabled={ responseHeaders !== null && parseInt( responseHeaders.totalPages ) <= page }
								>
									{ t('translation:userGuide:prikazite-jos') }
             					</CustomButton>
									
								{locationData && locationData[0] && locationData[0].acf.locations.length > 0 && <>
									<Title level={ 3 } style={{ fontFamily: "Tinos", margin: "1.5rem 0" }}>
										{ t('translation:userGuide:ogranci') }
									</Title>
									<Row gutter={24} type="flex" className={ styles.booksrow } style={{margin: "1rem 0 0 0"}}>
										{locationData[0].acf.locations.map((item, i) => (
												
												<Col lg={6} key={i}><LocationCard data={item}/></Col>
											))
										}
									</Row>
								</>}

								{activities && activities.length > 0 && <>
								<Title level={ 3 } style={{ fontFamily: "Tinos", margin: "1.5rem 0" }}>
									{ t('translation:userGuide:aktivnosti') }
								</Title>
								{activities.map(activity => (
									<ActivityItem
											key={ activity.id }
											id={ activity.id }
											activityStatus={ activity.acf.status }
											date={ activity.acf.date }
											location={ activity.acf.place }
											category={ activity.acf.type }
											title={ htmlToReact.parse( activity.title.rendered ) }
											description={ activity.acf.about }
										/>
									))
								}
								</>}
							</>
						)
					}
				</>
			}
			moreContent={ <PopularActivities mobile={ document.body.offsetWidth <= 992 }/> }
		/>
	)
}

export default UserGuide